<template>
    <div v-if="popular.error" v-html="popular.error" class="page-error"></div>
    <div v-else class="popular-main">
        <Spinner :listen="spinnerTrigger" />
        <div v-if="!popular.events.length" class="page-error-text">{{ $t('ui.popular.noActiveEvents') }}</div>
        <Game :key="event.ExId" v-for="event in popular.events" :event="event" :showMarketCount="true" />
        <HotDescription v-if="popular.containsHot" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sport } from '@agi.packages/sport';
import { getter as generalGetter } from '@/store/store';

import HotDescription from '@/components/HotDescription';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import Game from '../Game.vue';

export default {
    name: 'PopularView',
    mixins: [PageMixin, SEOMixin],
    data: () => ({
        spinnerTrigger: sport.action.GET_EVENTS_BY_QUERY,
    }),
    components: { Game, HotDescription },
    computed: {
        ...mapState({
            popular: (state) => state.sport.sports,
        }),
        ...mapGetters({
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
        }),
    },
    created: function () {
        this.$store.dispatch(sport.action.RESET_SPORTS_ERROR);
        if (!this.$route.meta?.scrollSelector) {
            this.$store.dispatch(sport.action.GET_EVENTS_BY_QUERY, {
                categoryId: this.currentCategoryId,
                onlyPopular: true,
                take: 100, // temporary query until this page will be enhanced with infinite loading and load more button
            });
        }
    },
};
</script>

<style scoped lang="scss">
.page-error-text {
    padding-top: 24px;
    text-align: center;
}
</style>
