import { findJurisdiction } from './jurisdiction-const';

const { VUE_APP_JURISDICTION } = env;

export const country = {
    KE: false,
    KE2: false,
    ZM: false,
    UG: false,
    TZ: false,
    GH: false,
    NG: false,
    ZW: false,
    CD: false,
    CM: false,
    BJ: false,
    SL: false,
    AO: false,
    MZ: false,
    CG: false,
    CI: false,
    SN: false,
    MWI: false,
    LS: false,
    GA: false,
};

/**
 * @deprecated
 */
export const JURISDICTION = (function () {
    const jurisdiction = findJurisdiction(VUE_APP_JURISDICTION);
    if (jurisdiction) {
        country[jurisdiction.country] = true;
        return jurisdiction;
    } else {
        console.error(`Jurisdiction is not found for host: ${location.hostname}`);
        return null;
    }
})();
