<template>
    <div class="input-field">
        <label :for="`${formName}-${name}`" v-if="hasLabelSlot" class="form" :class="labelClass">
            <slot name="label" />
            <span v-if="isPassword" @click="showPassword = !showPassword" class="optional underline">
                {{ showPassword ? $t('ui.common.hide') : $t('ui.common.show') }}
            </span>
            <span v-else class="optional optional-slot" @click="$emit('optional:click')">
                <slot name="optional" />
            </span>
        </label>
        <div class="input-field-wrapper" :class="`input-icon-${icon.position}`">
            <slot />
            <span v-if="currencySymbol" class="currency-symbol">{{ currencySymbol }}</span>
            <SvgIcon v-if="icon.id" :iconId="icon.id" :class="`icon-size-${icon.size}`" />
            <input
                :class="[v && v.$dirty && (v.$error ? 'error' : 'valid')]"
                :name="name"
                :id="`${formName}-${name}`"
                :value="formattedValue"
                :type="fieldType"
                :disabled="disabled"
                :step="step"
                v-on="handlers"
                v-bind="attrs"
                @blur="blurEventHandler($event)"
                @input="onInputChange($event.target.value)"
            />
        </div>
        <div v-if="helpText" class="help-text">
            {{ helpText }}
        </div>
        <div class="input-field-error" v-if="v && v.$error">
            <renderer :input="getErrorMessage()" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { required: true },
        attrs: {
            default: () => ({}),
        },
        handlers: {
            default: null,
        },
        formName: { required: true },
        name: { required: true },
        type: {
            default: 'text',
        },
        currencySymbol: {
            type: String,
            default: '',
        },
        helpText: {
            type: [String, Boolean],
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: String,
            default: '',
        },
        icon: {
            type: Object,
            default: () => ({}),
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
            default: () => ({}),
        },
        disableOnInputValidation: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            showPassword: false,
        };
    },
    computed: {
        formattedValue() {
            return this.formatInput(this.value);
        },
        hasLabelSlot() {
            return !!this.$slots.label;
        },
        isNumber() {
            return this.type === 'number';
        },
        isPassword() {
            return this.type === 'password';
        },
        isString() {
            return Object.keys(this.$attrs).includes('stringify');
        },
        fieldType() {
            return this.isPassword && this.showPassword ? 'text' : this.type;
        },
    },
    methods: {
        blurEventHandler() {
            this.$attrs.touchOnBlur && this.v && this.v.$touch();
        },
        onInputChange(value) {
            if (this.v && !this.disableOnInputValidation) {
                this.v.$touch();
            }
            if (this.disableOnInputValidation && this.v.$dirty && this.v.$invalid) {
                this.v.$reset();
            }
            const output = this.formatInput(value);
            this.$emit('value', output);
            // TODO: https://aliengain.atlassian.net/browse/BP-30483
            if (output !== null) {
                this.$forceUpdate();
            }
        },
        formatInput(value) {
            const isNumeric = this.isNumber && !this.isString;
            const output = isNumeric ? Number(value) || null : value;
            if (output && `${output}`.length > this.attrs.maxLength) {
                const truncate = `${output}`.slice(0, this.attrs.maxLength);
                return isNumeric ? Number(truncate) : truncate;
            } else {
                return output;
            }
        },
        getErrorMessage() {
            for (const param of Object.keys(this.v.$params)) {
                if (this.v[param] === false) {
                    return this.errorMessages[param] || this.$t('ui.common.form.error.required');
                }
            }
            return '';
        },
    },
};
</script>
<style lang="scss" scoped>
.input-field {
    margin-bottom: 16px;

    label {
        width: 100%;
    }

    .currency-symbol {
        @extend %body-normal-font-400;
        display: inline-block;
        vertical-align: top;
        padding: 8px;
        background: $medium-grey;
        color: $disabled-text;
        text-transform: uppercase;
        @include only_mini_feature_phone {
            display: table-cell;
        }
    }

    &-wrapper {
        display: flex;
        position: relative;

        @include only_mini_feature_phone {
            display: table-row;
        }

        input {
            width: 100%;
            max-width: 100%;

            &.error {
                border-color: $error-red;
                border-width: $input-focus-error-border-width;
            }

            &.valid {
                border-color: $greenish;
                border-width: $input-border-width;
            }
        }

        &.input-icon-right {
            input {
                padding-right: 45px;
            }

            svg {
                position: absolute;
                top: 9px;
                right: 12px;
            }
        }
    }

    &-error {
        @extend %form-validation-error;
    }

    .optional {
        float: right;
        font-size: 14px;
        color: #8e9398;
    }

    .help-text {
        @extend %small-details-font-400;
        color: $disabled-text;
        margin-top: 6px;
    }
}
</style>
