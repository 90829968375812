<template>
    <div>
        <ExpansionPanel
            v-for="(menu, index) in countryMenus"
            :data-test-id="`menu-${menu.name}`"
            :key="`${menu.slug}-${index}`"
            :title="$t(`ui.lMenu.${menu.name}`)"
            :control="!isFeaturePresto"
            :expanded="true"
        >
            <slot :menu="menu">
                <template v-for="(country, index) in menu.data">
                    <MenuItem
                        class="sub-menu"
                        :is-presto="isFeaturePresto"
                        :data-test-id="`country-menu-${country.region.id}`"
                        :underline="isFeaturePresto"
                        :key="`${menu.slug}-country-${country.region.id}-${index}`"
                        :selected="country.isActive"
                        @onIsLeftSideClick="
                            handleMenuSideClick(
                                $event,
                                country.region.id,
                                $event ? `All ${country.region.name}` : country.region.name,
                                $event ? `${menu.slug}_country_nav` : `${menu.slug}_country_nav_expand`,
                                !isFeaturePresto
                            )
                        "
                        @onItemClick="
                            toggleCountry(country.region.id, country.region.name, `${menu.slug}_country_nav_expand`, isFeaturePresto)
                        "
                        :item="{
                            text: country.region.name,
                            control: {
                                text: country.eventCount,
                                icon: isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                            },
                        }"
                    >
                        <template slot="icon">
                            <ImageIcon
                                v-if="country.region.slug"
                                class="icon-status"
                                :src="country.region.slug && `img/flags/${country.region.slug}.png`"
                                :altSrc="country.region.parentSlug && `img/flags/${country.region.parentSlug}.png`"
                            />
                        </template>
                        <template slot="content">
                            <ExpansionPanel v-if="country.competitions" :expanded="isExpandedCountry(country.region.id)">
                                <MenuItem
                                    v-if="!isFeaturePresto"
                                    class="sub-menu-item"
                                    @click.native="trackMainNav(`All ${country.region.name}`, `${menu.slug}_country_nav`)"
                                    :data-test-id="`country-${country.region.id}-all`"
                                    :selected="country.isSelected"
                                    :link="{ name: routeCountryName, params: { id: country.region.id } }"
                                    :item="{
                                        text: $t('menus.all'),
                                        control: { text: country.eventCount, iconDisabled: true },
                                    }"
                                >
                                </MenuItem>
                                <MenuItem
                                    class="sub-menu-item"
                                    v-for="(competition, competitionIndex) in country.competitions"
                                    @click.native="trackMainNav(`${competition.name}`, `${menu.slug}_country_nav`)"
                                    :data-test-id="`country-${country.region.id}-${competition.id}`"
                                    :key="`${menu.slug}-country-${competition.slug}-${competitionIndex}`"
                                    :selected="competition.isSelected"
                                    :link="{ name: routeGroupName, params: { id: competition.id } }"
                                    :item="{
                                        text: competition.name,
                                        control: { text: competition.eventCount, iconDisabled: true },
                                    }"
                                >
                                </MenuItem>
                            </ExpansionPanel>
                        </template>
                    </MenuItem>
                </template>
            </slot>
        </ExpansionPanel>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { deviceType } from '@agi.packages/core';
import { MenuItem, ExpansionPanel } from '@agi.packages/core/components';
import { mutation } from '@/store/store';
import { routeName } from '@/router/const';

const EXCLUSIVE_COUNTRY_ICONS = ['africa', 'asia', 'south-africa', 'south-america', 'europe'];

const CONTINENT_FILTER = ['africa', 'asia', 'america'];

export default {
    name: 'CountryMenus',
    components: { MenuItem, ExpansionPanel },
    data() {
        return {
            routeCountryName: routeName.COUNTRY,
            routeGroupName: routeName.GROUP,
            currentRoute: {
                name: '',
                id: null,
                category: null,
            },
            isFeaturePresto: deviceType.isPresto() && !deviceType.isSmartphonePresto(),
        };
    },
    computed: {
        ...mapState({
            countries: (state) => state.countries,
            expandedCountries: (state) => state.ui.leftMenuExpandedCountries,
        }),
        countriesPopularMenu() {
            const popularCountries = Array.from(this.countries).filter((country) => country.priority > 0);
            return this.updateCountriesList(popularCountries);
        },
        countriesOtherMenu() {
            const otherCountries = Array.from(this.countries).filter((country) => country.priority === 0);
            return this.updateCountriesList(otherCountries);
        },
        countryMenus() {
            const list = [
                {
                    name: 'popularCountries',
                    data: this.countriesPopularMenu,
                    slug: 'popular',
                },
                {
                    name: 'otherCountries',
                    data: this.countriesOtherMenu,
                    slug: 'other',
                },
            ];
            return list.filter((item) => item.data.length);
        },
    },
    methods: {
        updateCountriesList(countries) {
            if (!countries?.length) {
                return [];
            }

            const currentRouteId = this.currentRoute.id;

            return countries
                .map((country) => {
                    const isSelected = currentRouteId === country?.region.id;
                    country = {
                        ...country,
                        isSelected,
                        isActive: isSelected,
                    };
                    const competitions = country.competitions.map((competition) => {
                        const isSelected = currentRouteId === competition.id;
                        if (isSelected) country.isActive = isSelected;
                        return { ...competition, isSelected };
                    });

                    country.competitions = [...competitions];

                    const parentSlug = country.region?.parentSlug;
                    const updatedParentSlug =
                        (parentSlug && !EXCLUSIVE_COUNTRY_ICONS.includes(parentSlug)) ||
                        (parentSlug && CONTINENT_FILTER.find((it) => parentSlug.includes(it))) ||
                        (parentSlug && parentSlug.includes('europe') && 'international') ||
                        parentSlug;

                    country.region = { ...country.region, updatedParentSlug };
                    return country;
                })
                .filter((country) => {
                    return country.eventCount > 0;
                });
        },
        toggleOtherCountries() {
            this.$store.commit(mutation.TOGGLE_LEFT_MENU_OTHER);
        },
        toggleCountry(countryId, name, event, stop) {
            if (!stop) {
                this.trackMainNav(`expand-${name}`, event);
                this.$store.commit(mutation.TOGGLE_LEFT_MENU_COUNTRY, countryId);
            }
        },
        handleMenuSideClick(isLeftSide, countryId, name, event, stop) {
            if (!stop) {
                if (isLeftSide) {
                    this.$router.push({
                        name: routeName.COUNTRY,
                        params: { id: countryId },
                        query: { marketId: undefined, categoryId: undefined },
                    });
                    this.trackMainNav(name, event);
                } else {
                    this.toggleCountry(countryId, name, event);
                }
            }
        },
        isExpandedCountry(countryId) {
            return this.expandedCountries.includes(countryId);
        },
        trackMainNav(element, event = 'main_nav') {
            if (element) {
                this.$gtm.query({
                    event,
                    click_text: element,
                });
            }
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.$data.currentRoute = { name: to.name, id: to.query.id, category: to.query.category };
            },
        },
    },
};
</script>

<style></style>
