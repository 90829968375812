export const primaryMarketType = {
    OTHER: 1,
    _2_WAY_HANDICAP: 2,
    OVER_UNDER: 3,
    _3_WAY_HANDICAP: 4,
    DOUBLE_CHANCE: 5,
    BOTH_TEAMS_TO_SCORE: 6,
    CLEAN_SHEET: 7,
    CORRECT_SCORE: 8,
    SPECIALS: 9,
    DRAW_NO_BET: 10,
    ODD_EVEN: 11,
    TEAM_TO_SCORE: 12,
    TOTAL_GOALS_EXACT: 13,
    GOALSCORER: 14,
    MONEYLINE: 15,
    WINNER: 16,
    TOTAL_GAMES: 17,
    HALF: 18,
    WIN_TO_NIL: 19,
    CORNER: 20,
    BOOKING: 21,
    PLAYER_SENT_OFF: 22,
    SETS: 23,
    GROUP_WINNER: 24,
    TOP_GOALSCORER: 25,
    TOP_ASSIST: 26,
    TO_QUALIFY_FROM_GROUP: 27,
    NOT_TO_QUALIFY_FROM_GROUP: 28,
    TO_REACH_FINAL: 32,
    TO_REACH_SEMI_FINAL: 33,
    TO_REACH_QUARTER_FINAL: 34,
    TEAM_SPECIALS: 35,
    PLAYER_SPECIALS: 36,
    _2_WAY_HANDICAP_GAMES: 37,
    TO_QUALIFY: 38,
};

export const category = {
    ALL: 'all',
    POPULAR: 'popular',
    GOALS: 'goals',
    PLAYERS: 'players',
    HALVES: 'halves',
    COMBOS: 'combos',
    BOOKINGS: 'bookings',
    CORNERS: 'corners',
    SPECIALS: 'specials',
    POINTS: 'points',
    QUARTERS: 'quarters',
    SETS: 'sets',
    GAMES: 'games',
};

export const marketType = {
    _1X2_1H: 3668,
    _1X2_1P: 3677,
    _1X2_1Q: 3681,
    _1X2_2H: 3685,
    _1X2_2P: 3693,
    _1X2_2Q: 3697,
    _1X2_3P: 3707,
    _1X2_3Q: 3711,
    _1X2_4Q: 3719,
    _1X2_FT: 3743,
    ASIAN_HANDICAP_1H: 3747,
    ASIAN_HANDICAP_1P: 3750,
    ASIAN_HANDICAP_1Q: 3753,
    ASIAN_HANDICAP_2H: 3756,
    ASIAN_HANDICAP_2P: 3759,
    ASIAN_HANDICAP_2Q: 3762,
    ASIAN_HANDICAP_3P: 3765,
    ASIAN_HANDICAP_3Q: 3768,
    ASIAN_HANDICAP_4Q: 3771,
    ASIAN_HANDICAP_FT: 3774, // 2-Way Handicap | Full Time
    ASIAN_HANDICAP_FT_INCL_OT: 3777,
    AWAY_NO_BET_1H: 3780,
    AWAY_NO_BET_2H: 3783,
    AWAY_NO_BET_FT: 3786,
    BOTH_TEAMS_TO_SCORE_1H: 3789,
    BOTH_TEAMS_TO_SCORE_2H: 3792,
    BOTH_TEAMS_TO_SCORE_FT: 3795,
    BOTH_TEAMS_TO_SCORE_TOTAL_SCORE_OVER_UNDER_FT: 3798,
    CLEAN_SHEET_AWAY_TEAM_1H: 3801,
    CLEAN_SHEET_AWAY_TEAM_2H: 3804,
    CLEAN_SHEET_AWAY_TEAM_FT: 3807,
    CLEAN_SHEET_HOME_TEAM_1H: 3810,
    CLEAN_SHEET_HOME_TEAM_2H: 3813,
    CLEAN_SHEET_HOME_TEAM_FT: 3816,
    CORRECT_SCORE_1H: 3819,
    CORRECT_SCORE_2H: 4063,
    CORRECT_SCORE_FT: 4429,
    CORRECT_SCORE_FT_INCL_OT: 4551,
    DOUBLE_CHANCE_1H: 4673,
    DOUBLE_CHANCE_1P: 4677,
    DOUBLE_CHANCE_2H: 4681,
    DOUBLE_CHANCE_2P: 4685,
    DOUBLE_CHANCE_3P: 4689,
    DOUBLE_CHANCE_FT: 4693,
    DOUBLE_CHANCE_ET: 5593230,
    DOUBLE_CHANCE_ET_1H: 5593412,
    DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_FT: 28000014,
    DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_1H: 28000021,
    DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_2H: 28000028,
    DRAW_NO_BET_1H: 4697,
    DRAW_NO_BET_2H: 4700,
    DRAW_NO_BET_FT: 4703,
    HT_FT: 4706,
    HANDICAP_1X2_1H: 4716,
    HANDICAP_1X2_2H: 4720,
    HANDICAP_1X2_FT: 4724, // 3-Way Handicap | Full Time
    HALF_MORE_GOALS_TOTAL: 4728,
    HALF_MORE_GOALS_AWAY_TEAM: 4732,
    HALF_MORE_GOALS_HOME_TEAM: 4736,
    HOME_NO_BET_1H: 4740,
    HOME_NO_BET_2H: 4743,
    HOME_NO_BET_FT: 4746,
    MONEYLINE_1H: 4749,
    MONEYLINE_1Q: 4755,
    MONEYLINE_1S: 4758,
    MONEYLINE_2H: 4761,
    MONEYLINE_2Q: 4767,
    MONEYLINE_2S: 4770,
    MONEYLINE_3Q: 4776,
    MONEYLINE_3S: 4779,
    MONEYLINE_4Q: 4782,
    MONEYLINE_FT_INCL_OT: 4791,
    MULTISCORES_FT: 28000035,
    MULTIGOALS_FT: 28000047,
    ODD_EVEN_1H: 4794,
    ODD_EVEN_1H_AWAY_TEAM: 4797,
    ODD_EVEN_1H_HOME_TEAM: 4800,
    ODD_EVEN_1P: 4803,
    ODD_EVEN_1Q: 4806,
    ODD_EVEN_2H: 4809,
    ODD_EVEN_2H_AWAY_TEAM: 4812,
    ODD_EVEN_2H_HOME_TEAM: 4815,
    ODD_EVEN_2P: 4818,
    ODD_EVEN_2Q: 4821,
    ODD_EVEN_3P: 4824,
    ODD_EVEN_3Q: 4827,
    ODD_EVEN_4Q: 4830,
    ODD_EVEN_FT: 4833,
    ODD_EVEN_FT_AWAY_TEAM: 4836,
    ODD_EVEN_FT_INCL_OT: 4839,
    ODD_EVEN_FT_HOME_TEAM: 4842,
    TEAM_TO_SCORE_FIRST_2_WAY: 4860,
    TEAM_TO_SCORE_FIRST_3_WAY: 4863,
    TEAM_TO_SCORE_LAST_2_WAY: 4867,
    TEAM_TO_SCORE_LAST_3_WAY: 4870,
    TO_WIN_SET_3S: 4876,
    TOTAL_GAMES_OVER_UNDER_1S: 4880,
    TOTAL_GAMES_OVER_UNDER_2S: 4883,
    TOTAL_GAMES_OVER_UNDER_3S: 4886,
    TOTAL_GAMES_OVER_UNDER_4S: 4889,
    TOTAL_GAMES_OVER_UNDER_5S: 4892,
    TOTAL_GAMES_OVER_UNDER_FT: 4895,
    TOTAL_GOALS_EXACT_1H: 4898,
    TOTAL_GOALS_EXACT_1H_AWAY_TEAM: 4904,
    TOTAL_GOALS_EXACT_1H_HOME_TEAM: 4908,
    TOTAL_GOALS_EXACT_2H: 4912,
    TOTAL_GOALS_EXACT_2H_AWAY_TEAM: 4918,
    TOTAL_GOALS_EXACT_2H_HOME_TEAM: 4922,
    TOTAL_GOALS_EXACT_FT: 4926,
    TOTAL_GOALS_EXACT_FT_AWAY_TEAM: 4938,
    TOTAL_GOALS_EXACT_FT_HOME_TEAM: 4942,
    TOTAL_GOALS_GROUPED_1H: 4946,
    TOTAL_GOALS_GROUPED_2H: 4950,
    TOTAL_GOALS_GROUPED_FT: 4954,
    TOTAL_SCORE_OVER_UNDER_1H: 4958,
    TOTAL_SCORE_OVER_UNDER_1H_AWAY_TEAM: 4961,
    TOTAL_SCORE_OVER_UNDER_1H_HOME_TEAM: 4964,
    TOTAL_SCORE_OVER_UNDER_1P: 4970,
    TOTAL_SCORE_OVER_UNDER_1Q: 4973,
    TOTAL_SCORE_OVER_UNDER_2H: 4976,
    TOTAL_SCORE_OVER_UNDER_2H_AWAY_TEAM: 4979,
    TOTAL_SCORE_OVER_UNDER_2H_HOME_TEAM: 4982,
    TOTAL_SCORE_OVER_UNDER_2P: 4985,
    TOTAL_SCORE_OVER_UNDER_2Q: 4988,
    TOTAL_SCORE_OVER_UNDER_3P: 4991,
    TOTAL_SCORE_OVER_UNDER_3Q: 4994,
    TOTAL_SCORE_OVER_UNDER_4Q: 4997,
    TOTAL_SCORE_OVER_UNDER_FT: 5000,
    TOTAL_SCORE_OVER_UNDER_FT_AWAY_TEAM: 5003,
    TOTAL_SCORE_OVER_UNDER_FT_HOME_TEAM: 5006,
    TOTAL_SCORE_OVER_UNDER_FT_INCL_OT: 5009,
    WIN_TO_NIL_AWAY_TEAM_1H: 5036,
    WIN_TO_NIL_AWAY_TEAM_2H: 5039,
    WIN_TO_NIL_AWAY_TEAM_FT: 5042,
    WIN_TO_NIL_HOME_TEAM_1H: 5045,
    WIN_TO_NIL_HOME_TEAM_2H: 5048,
    WIN_TO_NIL_HOME_TEAM_FT: 5051,
    TO_WIN_OUTRIGHT: 116921,
    WINNING_TEAM: 120595,
    TOP_FINISH: 121045,
    TEAM_TOP_FINISH: 121046,
    FIRST_GOALSCORER: 129107,
    ANYTIME_GOALSCORER: 129108,
    LAST_GOALSCORER: 129109,
    CORRECT_SCORE_1S: 1027022,
    TOTAL_SETS_EXACT_FT: 1027023,
    ASIAN_HANDICAP_1S: 1027024,
    DOUBLE_RESULT_1S_FT_INCL_OT: 1027025,
    WIN_TO_NIL_ANY_SET_FT_INCL_OT: 1027026,
    TIEBREAK_IN_ANY_SET: 1027027,
    TIEBREAK_IN_FIRST_SET: 1027028,
    ANY_SET_WINNER_HOME: 1027029,
    ANY_SET_WINNER_AWAY: 1027030,
    _1X2_AND_TOTALS_FT: 1096755,
    _1X2_AND_TOTALS_1H: 25451913,
    _1X2_AND_TOTALS_2H: 25451920,
    _1X2_AND_TEAM_TO_SCORE_FIRST_FT: 1096762,
    TOTAL_BOOKINGS_OVER_UNDER_FT: 1096764,
    TOTAL_BOOKINGS_OVER_UNDER_1H: 1096765,
    PLAYER_SENT_OFF_FT: 1096766,
    PLAYER_SENT_OFF_1H: 1096767,
    TOTAL_BOOKINGS_HOME_FT: 1096768,
    TOTAL_BOOKINGS_HOME_1H: 1096769,
    TOTAL_BOOKINGS_AWAY_FT: 1096770,
    TOTAL_BOOKINGS_AWAY_1H: 1096771,
    FIRST_BOOKING_FT: 1096772,
    FIRST_BOOKING_1H: 1096773,
    TEAM_WITH_MOST_BOOKINGS_3_WAY_FT: 1096774,
    TEAM_WITH_MOST_BOOKINGS_3_WAY_1H: 1096775,
    TOTAL_BOOKINGS_FT: 1096776,
    TOTAL_BOOKINGS_1H: 1096777,
    PLAYER_SENT_OFF_HOME_FT: 1096778,
    PLAYER_SENT_OFF_HOME_1H: 1096779,
    PLAYER_SENT_OFF_AWAY_FT: 1096780,
    PLAYER_SENT_OFF_AWAY_1H: 1096781,
    TEAM_TO_SCORE_4_WAY_FT: 1096782,
    TOTAL_CORNERS_OVER_UNDER_FT: 1096783,
    TOTAL_CORNERS_OVER_UNDER_1H: 1096784,
    CORNER_ASIAN_HANDICAP_FT: 1096785,
    CORNER_ASIAN_HANDICAP_1H: 1096786,
    CORNER_COUNT_1X2_FT: 1096787,
    CORNER_COUNT_1X2_1H: 1096788,
    CORNER_COUNT_ODD_EVEN_FT: 1096789,
    CORNER_COUNT_ODD_EVEN_1H: 1096790,
    FIRST_CORNER_FT: 1096791,
    FIRST_CORNER_1H: 1096792,
    TOTAL_CORNERS_HOME_FT: 1096793,
    TOTAL_CORNERS_HOME_1H: 1096794,
    LAST_CORNER_FT: 1096795,
    LAST_CORNER_1H: 1096796,
    TOTAL_CORNERS_AWAY_FT: 1096797,
    TOTAL_CORNERS_AWAY_1H: 1096802,
    TOTAL_CORNERS_FT: 1096803,
    TOTAL_CORNERS_1H: 1096804,
    BOTH_HALVES_WIN_HOME_FT: 1096805,
    EITHER_HALF_WIN_HOME_FT: 1096806,
    SCORE_IN_BOTH_HALVES_HOME_FT: 1096807,
    BOTH_HALVES_WIN_AWAY_FT: 1096808,
    EITHER_HALF_WIN_AWAY_FT: 1096809,
    SCORE_IN_BOTH_HALVES_AWAY_FT: 1096814,
    TEAM_TO_SCORE_FIRST_3_WAY_1H: 1096815,
    TEAM_TO_SCORE_FIRST_3_WAY_2H: 1096816,
    TOTAL_RUNS_GROUPED_FT_HOME_TEAM: 1396211,
    TOTAL_RUNS_GROUPED_FT_AWAY_TEAM: 1396212,
    TOTAL_RUNS_AT_HIGHEST_SCORING_OVER: 1396213,
    TEAM_WITH_HIGHEST_SCORING_PERIOD: 1396222,
    WILL_THE_MATCH_BE_TIED_AFTER_REGULAR_TIME: 1396223,
    _1X2_10_OVERS: 1396224,
    TOTAL_RUNS_OVER_UNDER_10_OVERS_HOME_TEAM: 1396225,
    TOTAL_RUNS_OVER_UNDER_10_OVERS_AWAY_TEAM: 1396226,
    TOTAL_RUNS_GROUPED_10_OVERS_HOME_TEAM: 1396227,
    TOTAL_RUNS_GROUPED_10_OVERS_AWAY_TEAM: 1396228,
    TOTAL_RUNS_GROUPED_6_OVERS_HOME_TEAM: 1396232,
    TOTAL_RUNS_GROUPED_6_OVERS_AWAY_TEAM: 1396233,
    TOTAL_RUNS_OVER_UNDER_FT: 1437288,
    TOTAL_RUNS_OVER_UNDER_FT_AWAY_TEAM: 1437325,
    TOTAL_RUNS_OVER_UNDER_FT_HOME_TEAM: 1437339,
    TOTAL_RUNS_OVER_UNDER_1ST_OVER: 1437881,
    ODD_EVEN_1ST_OVER: 1437887,
    NUMBER_OF_SETS_WITH_EXCEEDED_SCORE_LIMIT: 1475227,
    TEAM_TO_GET_POINTS_FIRST_IN_FIRST_SET: 1475262,
    ODD_EVEN_1_SET: 1475287,
    MONEYLINE_4S: 4785,
    MONEYLINE_5S: 4788,
    WILL_THERE_BE_PLAYED_A_5TH_SET_IN_THE_MATCH: 1475412,
    WILL_THERE_BE_PLAYED_A_4TH_SET_IN_THE_MATCH: 1475427,
    MONEYLINE_FT: 2043818,
    SCORE_IN_SECOND_HALF_HOME: 2043875,
    SCORE_IN_SECOND_HALF_AWAY: 2043878,
    GAMES_OVER_UNDER_FT_INCL_OT: 2067866,
    GAMES_OVER_UNDER_HOME_FT: 2068161,
    GAMES_OVER_UNDER_AWAY_FT: 2068172,
    GAMES_OVER_UNDER_1ST_SET: 2068352,
    GAMES_OVER_UNDER_2ND_SET: 2068376,
    GAMES_OVER_UNDER_3RD_SET: 2068454,
    GAMES_OVER_UNDER_4TH_SET: 2068510,
    GAMES_OVER_UNDER_5TH_SET: 2068530,
    NUMBER_OF_AFRICAN_TEAMS_TO_QUALIFY_FOR_NEXT_ROUND: 3251557,
    GROUP_A_WINNER: 3254267,
    GROUP_B_WINNER: 3254272,
    GROUP_C_WINNER: 3254276,
    GROUP_D_WINNER: 3254280,
    GROUP_E_WINNER: 3254285,
    GROUP_F_WINNER: 3254286,
    GROUP_G_WINNER: 3254289,
    GROUP_H_WINNER: 3254291,
    TO_QUALIFY_FROM_GROUP_A: 3254349,
    TO_QUALIFY_FROM_GROUP_B: 3254352,
    TO_QUALIFY_FROM_GROUP_C: 3254356,
    TO_QUALIFY_FROM_GROUP_D: 3254358,
    TO_QUALIFY_FROM_GROUP_E: 3254360,
    TO_QUALIFY_FROM_GROUP_F: 3254364,
    TO_QUALIFY_FROM_GROUP_G: 3254365,
    TO_QUALIFY_FROM_GROUP_H: 3254367,
    NOT_TO_QUALIFY_FROM_GROUP_A: 3254424,
    NOT_TO_QUALIFY_FROM_GROUP_B: 3254429,
    NOT_TO_QUALIFY_FROM_GROUP_C: 3254431,
    NOT_TO_QUALIFY_FROM_GROUP_D: 3254435,
    NOT_TO_QUALIFY_FROM_GROUP_E: 3254437,
    NOT_TO_QUALIFY_FROM_GROUP_F: 3254438,
    NOT_TO_QUALIFY_FROM_GROUP_G: 3254439,
    NOT_TO_QUALIFY_FROM_GROUP_H: 3254442,
    TO_REACH_QUARTER_FINAL: 3254516,
    TO_REACH_SEMI_FINAL: 3254520,
    TO_REACH_FINAL: 3254529,
    AFRICAN_TEAM_TO_WIN_THE_WORLD_CUP: 3254967,
    AFRICAN_PLAYER_TO_BECOME_TOP_GOALSCORER: 3254972,
    TOP_AFRICAN_TEAM: 3254991,
    TOP_EUROPEAN_TEAM: 3254994,
    NUMBER_OF_PLAYOFF_GAMES_THAT_WILL_GO_TO_EXTRA_TIME: 3255021,
    NUMBER_OF_PLAYOFF_GAMES_THAT_WILL_GO_TO_PENALTIES: 3255026,
    LEWERANDOWSKI_RONALDO_MULLER_KANE_ALL_TO_SCORE: 3255446,
    MESSI_SUAREZ_RODRIGUEZ_NEYMAR_ALL_TO_SCORE: 3255494,
    KELECHI_IHEANACHO_SADIO_MANE__MOHAMED_SALAH_ALL_TO_SCORE: 3255506,
    MESSI_TO_SCORE_MORE_GOALS_THAN_RONALDO: 3255543,
    RONALDO_TO_SCORE_MORE_GOALS_THAN_MESSI: 3255546,
    SALAH_TO_MISS_A_PENALTY: 3255652,
    SALAH_TO_GET_SENT_OFF: 3255658,
    MANE_TO_MISS_A_PENALTY: 3255725,
    MANE_TO_GET_SENT_OFF: 3255729,
    KELECHI_TO_MISS_A_PENALTY: 3255775,
    KELECHI_TO_GET_SENT_OFF: 3255778,
    MESSI_TO_MISS_A_PENALTY: 3255794,
    MESSI_TO_GET_SENT_OFF: 3255799,
    RONALDO_TO_MISS_A_PENALTY: 3255815,
    RONALDO_TO_GET_SENT_OFF: 3255821,
    PUTIN_TO_RIDE_A_BEAR_TO_THE_OPENING_GAME: 3255878,
    LUIS_SUAREZ_TO_BITE_SOMEONE: 3255905,
    HARRY_KANE_TO_CLAIM_A_TEAM_MATES_GOAL: 3270354,
    EDEN_HAZARD_TO_KICK_A_BALL_BOY: 3270359,
    ENGLAND_TO_BE_ELIMINATED_ON_PENALTIES: 3270445,
    RONALDO_TO_GET_BOOKED_FOR_DIVING: 3270456,
    A_PLAYER_TO_FAIL_A_DRUG_TEST: 3270469,
    FRENCH_PLAYERS_TO_GO_ON_STRIKE: 3270483,
    WORLD_CUP_TO_BE_ABANDONED_DUE_TO_HOOLIGAN_RIOTS: 3270493,
    TOP_ASSIST: 3270513,
    TOP_GOALSCORER: 3270520,
    MOST_BOOKED_PLAYER: 3270528,
    TEAM_TO_FIRST_SEND_A_PLAYER_HOME_FOR_BAD_BEHAVIOR: 3270532,
    ODD_EVEN_GAMES_1S: 3532070,
    ODD_EVEN_GAMES_2S: 3532092,
    ODD_EVEN_GAMES_3S: 3532096,
    ODD_EVEN_GAMES_4S: 3532101,
    ODD_EVEN_GAMES_5S: 3532104,
    ODD_EVEN_POINTS_1S: 3532340,
    ODD_EVEN_POINTS_2S: 3532344,
    ODD_EVEN_POINTS_3S: 3532347,
    ODD_EVEN_POINTS_4S: 3532351,
    ODD_EVEN_POINTS_5S: 3532355,
    ODD_EVEN_POINTS_FT: 3532361,
    ODD_EVEN_GAMES_FT: 3532365,
    TIEBREAK_IN_SECOND_SET: 3532446,
    TIEBREAK_IN_THIRD_SET: 3532475,
    TIEBREAK_IN_FOURTH_SET: 3532482,
    TIEBREAK_IN_FIFTH_SET: 3532492,
    TOTAL_POINTS_OVER_UNDER_1S: 3532504,
    TOTAL_POINTS_OVER_UNDER_2S: 3532509,
    TOTAL_POINTS_OVER_UNDER_3S: 3532512,
    TOTAL_POINTS_OVER_UNDER_4S: 3532516,
    TOTAL_POINTS_OVER_UNDER_5S: 3532519,
    TOTAL_POINTS_OVER_UNDER_FT: 3532556,
    MATCH_HANDICAP_GAMES_2_WAY_FT: 3532590,
    MATCH_HANDICAP_GAMES_3_WAY_FT: 3532630,
    _1X2_AND_BOTH_TEAMS_TO_SCORE_FT: 3591790,
    _1X2_AND_BOTH_TEAMS_TO_SCORE_1H: 28000000,
    _1X2_AND_BOTH_TEAMS_TO_SCORE_2H: 28000007,
    PENALTY_AWARDED: 3591798,
    TOTAL_CORNERS_OVER_UNDER_FT_HOME_TEAM: 3591813,
    TOTAL_CORNERS_OVER_UNDER_FT_AWAY_TEAM: 3591817,
    FIRST_CORNER_2H: 3591820,
    PLAYER_RED_CARD_FT: 3594263,
    PLAYER_RED_CARD_HOME_FT: 3594266,
    PLAYER_RED_CARD_AWAY_FT: 3594270,
    PLAYER_RED_CARD_1H: 3594273,
    PLAYER_RED_CARD_2H: 3594276,
    TOUCHDOWN_1Q: 3594954,
    TOUCHDOWN_2Q: 3594958,
    TOUCHDOWN_3Q: 3594962,
    TOUCHDOWN_4Q: 3594965,
    FIELD_GOAL_1Q: 3594968,
    FIELD_GOAL_2Q: 3594971,
    FIELD_GOAL_3Q: 3594975,
    FIELD_GOAL_4Q: 3594978,
    TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_HOME_TEAM: 3595129,
    TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_AWAY_TEAM: 3595133,
    TOSS_WINNER: 3595350,
    TOTAL_SETS_OVER_UNDER_FT: 3597899,
    AWAY_TO_SCORE_2_TRIES_FT: 3598517,
    AWAY_TO_SCORE_3_TRIES_FT: 3598522,
    AWAY_TO_SCORE_TRY_FT: 3598526,
    HOME_TO_SCORE_2_TRIES_FT: 3598530,
    HOME_TO_SCORE_3_TRIES_FT: 3598553,
    HOME_TO_SCORE_TRY_FT: 3598556,
    BOTH_TEAMS_TO_SCORE_TRY_FT: 3598559,
    ODD_EVEN_TRIES_FT: 3598698,
    TOTAL_TRIES_OVER_UNDER_FT: 3598987,
    BRACE_GOALSCORER: 3601769,
    HAT_TRICK_GOALSCORER: 3601772,
    TO_QUALIFY: 4272110,
    MATCH_WINNER_AND_TOTALS_FT_INCL_OT: 4389697,
    TOTAL_SCORE_OVER_UNDER_1Q_HOME_TEAM: 4389935,
    TOTAL_SCORE_OVER_UNDER_2Q_HOME_TEAM: 4389940,
    TOTAL_SCORE_OVER_UNDER_3Q_HOME_TEAM: 4389947,
    TOTAL_SCORE_OVER_UNDER_4Q_HOME_TEAM: 4389953,
    TOTAL_SCORE_OVER_UNDER_1Q_AWAY_TEAM: 4389959,
    TOTAL_SCORE_OVER_UNDER_2Q_AWAY_TEAM: 4389989,
    TOTAL_SCORE_OVER_UNDER_3Q_AWAY_TEAM: 4389997,
    TOTAL_SCORE_OVER_UNDER_4Q_AWAY_TEAM: 4390002,
    PENALTY_SCORED_FT: 4600807,
    PENALTY_SCORED_1H: 4601518,
    PENALTY_SCORED_2H: 4601549,
    PENALTY_SCORED_HOME_TEAM_FT: 4601637,
    PENALTY_SCORED_AWAY_TEAM_FT: 4601648,
    PENALTY_AWARDED_1H: 4601869,
    PENALTY_AWARDED_2H: 4601915,
    GOAL_IN_BOTH_NETS: 4602328,
    BOTH_TEAMS_TO_SCORE_AND_TOTALS_FT: 4613062,
    TEAM_TO_SCORE_1ST_GOAL: 4615748,
    TEAM_TO_SCORE_2ND_GOAL: 4615756,
    TEAM_TO_SCORE_3RD_GOAL: 4615763,
    TEAM_TO_SCORE_4TH_GOAL: 4615769,
    TEAM_TO_SCORE_5TH_GOAL: 4615775,
    TEAM_TO_SCORE_6TH_GOAL: 4615781,
    TEAM_TO_SCORE_7TH_GOAL: 4615786,
    TEAM_TO_SCORE_8TH_GOAL: 4615791,
    TEAM_TO_SCORE_9TH_GOAL: 4615797,
    TEAM_TO_SCORE_10TH_GOAL: 4615809,
    TEAM_TO_SCORE_11TH_GOAL: 4615818,
    TEAM_TO_SCORE_12TH_GOAL: 4615823,
    TEAM_TO_SCORE_13TH_GOAL: 4615830,
    TEAM_TO_SCORE_14TH_GOAL: 4615838,
    TEAM_TO_SCORE_1ST_GOAL_1H: 4652680,
    TEAM_TO_SCORE_2ND_GOAL_1H: 4652684,
    TEAM_TO_SCORE_3RD_GOAL_1H: 4652688,
    TEAM_TO_SCORE_4TH_GOAL_1H: 4652695,
    TEAM_TO_SCORE_5TH_GOAL_1H: 4652701,
    TEAM_TO_SCORE_6TH_GOAL_1H: 4652708,
    TEAM_TO_SCORE_7TH_GOAL_1H: 4652714,
    TEAM_TO_SCORE_8TH_GOAL_1H: 4652719,
    TEAM_TO_SCORE_9TH_GOAL_1H: 4652724,
    TEAM_TO_SCORE_10TH_GOAL_1H: 4652728,
    TEAM_TO_SCORE_1ST_GOAL_2H: 4652954,
    TEAM_TO_SCORE_2ND_GOAL_2H: 4652958,
    TEAM_TO_SCORE_3RD_GOAL_2H: 4652963,
    TEAM_TO_SCORE_4TH_GOAL_2H: 4652971,
    TEAM_TO_SCORE_5TH_GOAL_2H: 4652976,
    TEAM_TO_SCORE_6TH_GOAL_2H: 4652981,
    NEXT_ACTION_1: 4653279,
    NEXT_ACTION_2: 4653287,
    NEXT_ACTION_3: 4653294,
    NEXT_ACTION_4: 4653300,
    NEXT_ACTION_5: 4653307,
    NEXT_ACTION_6: 4653314,
    NEXT_ACTION_7: 4653322,
    NEXT_ACTION_8: 4653328,
    NEXT_ACTION_9: 4653335,
    NEXT_ACTION_10: 4653342,
    NEXT_ACTION_11: 4653539,
    NEXT_ACTION_12: 4653545,
    NEXT_ACTION_13: 4653553,
    NEXT_ACTION_14: 4653560,
    NEXT_ACTION_15: 4653566,
    NEXT_ACTION_16: 4653573,
    NEXT_ACTION_17: 4653582,
    NEXT_ACTION_18: 4653589,
    NEXT_ACTION_19: 4653596,
    NEXT_ACTION_20: 4653603,
    NEXT_ACTION_21: 4653609,
    NEXT_ACTION_22: 4653617,
    NEXT_ACTION_23: 4653623,
    NEXT_ACTION_24: 4653919,
    NEXT_ACTION_25: 4653926,
    NEXT_ACTION_26: 4653933,
    NEXT_ACTION_27: 4653940,
    NEXT_ACTION_28: 4653947,
    NEXT_ACTION_29: 4653953,
    NEXT_ACTION_30: 4653961,
    NEXT_ACTION_31: 4653967,
    NEXT_ACTION_32: 4653974,
    NEXT_ACTION_33: 4653981,
    NEXT_ACTION_34: 4653989,
    NEXT_ACTION_35: 4653997,
    NEXT_ACTION_36: 4654004,
    NEXT_ACTION_37: 4654011,
    NEXT_ACTION_38: 4654020,
    NEXT_ACTION_39: 4654030,
    WINNING_TEAM_BETWEEN_1_AND_15_MINUTES: 4660367,
    WINNING_TEAM_BETWEEN_16_AND_30_MINUTES: 4660379,
    WINNING_TEAM_BETWEEN_31_AND_45_MINUTES: 4660390,
    WINNING_TEAM_BETWEEN_46_AND_60_MINUTES: 4661002,
    WINNING_TEAM_BETWEEN_61_AND_75_MINUTES: 4661024,
    WINNING_TEAM_BETWEEN_76_AND_90_MINUTES: 4661055,
    A_GOAL_TO_BE_SCORED_IN_1_15_MINUTES: 4661714,
    A_GOAL_TO_BE_SCORED_IN_16_30_MINUTES: 4661720,
    A_GOAL_TO_BE_SCORED_IN_31_45_MINUTES: 4661727,
    A_GOAL_TO_BE_SCORED_IN_46_60_MINUTES: 4661734,
    A_GOAL_TO_BE_SCORED_IN_61_75_MINUTES: 4661739,
    A_GOAL_TO_BE_SCORED_IN_76_90_MINUTES: 4661743,
    HOME_TO_SCORE_A_GOAL_IN_1_15_MINUTES: 4662142,
    HOME_TO_SCORE_A_GOAL_IN_16_30_MINUTES: 4662148,
    HOME_TO_SCORE_A_GOAL_IN_31_45_MINUTES: 4662156,
    HOME_TO_SCORE_A_GOAL_IN_46_60_MINUTES: 4662167,
    HOME_TO_SCORE_A_GOAL_IN_61_75_MINUTES: 4662173,
    HOME_TO_SCORE_A_GOAL_IN_76_90_MINUTES: 4662177,
    AWAY_TO_SCORE_A_GOAL_IN_1_15_MINUTES: 4662234,
    AWAY_TO_SCORE_A_GOAL_IN_16_30_MINUTES: 4662237,
    AWAY_TO_SCORE_A_GOAL_IN_31_45_MINUTES: 4662240,
    AWAY_TO_SCORE_A_GOAL_IN_46_60_MINUTES: 4662243,
    AWAY_TO_SCORE_A_GOAL_IN_61_75_MINUTES: 4662248,
    AWAY_TO_SCORE_A_GOAL_IN_76_90_MINUTES: 4662254,
    ODD_EVEN_FT_INCL_OT_HOME_TEAM: 4662841,
    ODD_EVEN_FT_INCL_OT_AWAY_TEAM: 4662871,
    HIGHEST_SCORING_QUARTER: 4666451,
    TEAM_WITH_HIGHEST_SCORING_QUARTER: 4666707,
    SET_1_GAME_HANDICAP: 4666923,
    SET_2_GAME_HANDICAP: 4666927,
    SET_3_GAME_HANDICAP: 4666940,
    SET_4_GAME_HANDICAP: 4666994,
    SET_5_GAME_HANDICAP: 4666997,
    SET_1_GAME_1_TOTAL_POINTS_2_WAY: 4667370,
    SET_1_GAME_2_TOTAL_POINTS_2_WAY: 4667373,
    SET_1_GAME_3_TOTAL_POINTS_2_WAY: 4667377,
    SET_1_GAME_4_TOTAL_POINTS_2_WAY: 4667381,
    SET_1_GAME_5_TOTAL_POINTS_2_WAY: 4667384,
    SET_1_GAME_6_TOTAL_POINTS_2_WAY: 4667388,
    SET_1_GAME_7_TOTAL_POINTS_2_WAY: 4667394,
    SET_1_GAME_8_TOTAL_POINTS_2_WAY: 4667400,
    SET_1_GAME_9_TOTAL_POINTS_2_WAY: 4667403,
    SET_1_GAME_10_TOTAL_POINTS_2_WAY: 4667407,
    SET_1_GAME_11_TOTAL_POINTS_2_WAY: 4667412,
    SET_1_GAME_12_TOTAL_POINTS_2_WAY: 4667416,
    SET_2_GAME_1_TOTAL_POINTS_2_WAY: 4667711,
    SET_2_GAME_2_TOTAL_POINTS_2_WAY: 4667716,
    SET_2_GAME_3_TOTAL_POINTS_2_WAY: 4667720,
    SET_2_GAME_4_TOTAL_POINTS_2_WAY: 4667724,
    SET_2_GAME_5_TOTAL_POINTS_2_WAY: 4667727,
    SET_2_GAME_6_TOTAL_POINTS_2_WAY: 4667731,
    SET_2_GAME_7_TOTAL_POINTS_2_WAY: 4667734,
    SET_2_GAME_8_TOTAL_POINTS_2_WAY: 4667737,
    SET_2_GAME_9_TOTAL_POINTS_2_WAY: 4667741,
    SET_2_GAME_10_TOTAL_POINTS_2_WAY: 4667745,
    SET_2_GAME_11_TOTAL_POINTS_2_WAY: 4667749,
    SET_2_GAME_12_TOTAL_POINTS_2_WAY: 4667754,
    SET_3_GAME_1_TOTAL_POINTS_2_WAY: 4667870,
    SET_3_GAME_2_TOTAL_POINTS_2_WAY: 4667874,
    SET_3_GAME_3_TOTAL_POINTS_2_WAY: 4667877,
    SET_3_GAME_4_TOTAL_POINTS_2_WAY: 4667881,
    SET_3_GAME_5_TOTAL_POINTS_2_WAY: 4667887,
    SET_3_GAME_6_TOTAL_POINTS_2_WAY: 4667891,
    SET_3_GAME_7_TOTAL_POINTS_2_WAY: 4667895,
    SET_3_GAME_8_TOTAL_POINTS_2_WAY: 4667899,
    SET_3_GAME_9_TOTAL_POINTS_2_WAY: 4667903,
    SET_3_GAME_10_TOTAL_POINTS_2_WAY: 4667907,
    SET_3_GAME_11_TOTAL_POINTS_2_WAY: 4667911,
    SET_3_GAME_12_TOTAL_POINTS_2_WAY: 4667917,
    SET_4_GAME_1_TOTAL_POINTS_2_WAY: 4668143,
    SET_4_GAME_2_TOTAL_POINTS_2_WAY: 4668146,
    SET_4_GAME_3_TOTAL_POINTS_2_WAY: 4668150,
    SET_4_GAME_4_TOTAL_POINTS_2_WAY: 4668154,
    SET_4_GAME_5_TOTAL_POINTS_2_WAY: 4668157,
    SET_4_GAME_6_TOTAL_POINTS_2_WAY: 4668160,
    SET_4_GAME_7_TOTAL_POINTS_2_WAY: 4668165,
    SET_4_GAME_8_TOTAL_POINTS_2_WAY: 4668168,
    SET_4_GAME_9_TOTAL_POINTS_2_WAY: 4668176,
    SET_4_GAME_10_TOTAL_POINTS_2_WAY: 4668180,
    SET_4_GAME_11_TOTAL_POINTS_2_WAY: 4668183,
    SET_4_GAME_12_TOTAL_POINTS_2_WAY: 4668186,
    SET_5_GAME_1_TOTAL_POINTS_2_WAY: 4668308,
    SET_5_GAME_2_TOTAL_POINTS_2_WAY: 4668311,
    SET_5_GAME_3_TOTAL_POINTS_2_WAY: 4668314,
    SET_5_GAME_4_TOTAL_POINTS_2_WAY: 4668317,
    SET_5_GAME_5_TOTAL_POINTS_2_WAY: 4668323,
    SET_5_GAME_6_TOTAL_POINTS_2_WAY: 4668331,
    SET_5_GAME_7_TOTAL_POINTS_2_WAY: 4668342,
    SET_5_GAME_8_TOTAL_POINTS_2_WAY: 4668347,
    SET_5_GAME_9_TOTAL_POINTS_2_WAY: 4668356,
    SET_5_GAME_10_TOTAL_POINTS_2_WAY: 4668361,
    SET_5_GAME_11_TOTAL_POINTS_2_WAY: 4668367,
    SET_5_GAME_12_TOTAL_POINTS_2_WAY: 4668371,
    SET_1_GAME_1_TOTAL_POINTS_ODD_EVEN: 4668916,
    SET_1_GAME_2_TOTAL_POINTS_ODD_EVEN: 4668922,
    SET_1_GAME_3_TOTAL_POINTS_ODD_EVEN: 4668927,
    SET_1_GAME_4_TOTAL_POINTS_ODD_EVEN: 4668932,
    SET_1_GAME_5_TOTAL_POINTS_ODD_EVEN: 4668939,
    SET_1_GAME_6_TOTAL_POINTS_ODD_EVEN: 4668944,
    SET_1_GAME_7_TOTAL_POINTS_ODD_EVEN: 4668950,
    SET_1_GAME_8_TOTAL_POINTS_ODD_EVEN: 4668955,
    SET_1_GAME_9_TOTAL_POINTS_ODD_EVEN: 4668960,
    SET_1_GAME_10_TOTAL_POINTS_ODD_EVEN: 4668963,
    SET_1_GAME_11_TOTAL_POINTS_ODD_EVEN: 4668969,
    SET_1_GAME_12_TOTAL_POINTS_ODD_EVEN: 4668975,
    SET_2_GAME_1_TOTAL_POINTS_ODD_EVEN: 4673790,
    SET_2_GAME_2_TOTAL_POINTS_ODD_EVEN: 4673794,
    SET_2_GAME_3_TOTAL_POINTS_ODD_EVEN: 4673798,
    SET_2_GAME_4_TOTAL_POINTS_ODD_EVEN: 4673805,
    SET_2_GAME_5_TOTAL_POINTS_ODD_EVEN: 4673809,
    SET_2_GAME_6_TOTAL_POINTS_ODD_EVEN: 4673814,
    SET_2_GAME_7_TOTAL_POINTS_ODD_EVEN: 4673819,
    SET_2_GAME_8_TOTAL_POINTS_ODD_EVEN: 4673825,
    SET_2_GAME_9_TOTAL_POINTS_ODD_EVEN: 4673828,
    SET_2_GAME_10_TOTAL_POINTS_ODD_EVEN: 4673833,
    SET_2_GAME_11_TOTAL_POINTS_ODD_EVEN: 4673839,
    SET_2_GAME_12_TOTAL_POINTS_ODD_EVEN: 4673843,
    SET_3_GAME_1_TOTAL_POINTS_ODD_EVEN: 4673950,
    SET_3_GAME_2_TOTAL_POINTS_ODD_EVEN: 4673954,
    SET_3_GAME_3_TOTAL_POINTS_ODD_EVEN: 4673959,
    SET_3_GAME_4_TOTAL_POINTS_ODD_EVEN: 4673965,
    SET_3_GAME_5_TOTAL_POINTS_ODD_EVEN: 4673969,
    SET_3_GAME_6_TOTAL_POINTS_ODD_EVEN: 4673973,
    SET_3_GAME_7_TOTAL_POINTS_ODD_EVEN: 4673977,
    SET_3_GAME_8_TOTAL_POINTS_ODD_EVEN: 4673980,
    SET_3_GAME_9_TOTAL_POINTS_ODD_EVEN: 4673985,
    SET_3_GAME_10_TOTAL_POINTS_ODD_EVEN: 4673989,
    SET_3_GAME_11_TOTAL_POINTS_ODD_EVEN: 4673994,
    SET_3_GAME_12_TOTAL_POINTS_ODD_EVEN: 4673997,
    SET_4_GAME_1_TOTAL_POINTS_ODD_EVEN: 4675568,
    SET_4_GAME_2_TOTAL_POINTS_ODD_EVEN: 4675575,
    SET_4_GAME_3_TOTAL_POINTS_ODD_EVEN: 4675578,
    SET_4_GAME_4_TOTAL_POINTS_ODD_EVEN: 4675582,
    SET_4_GAME_5_TOTAL_POINTS_ODD_EVEN: 4675587,
    SET_4_GAME_6_TOTAL_POINTS_ODD_EVEN: 4675591,
    SET_4_GAME_7_TOTAL_POINTS_ODD_EVEN: 4675595,
    SET_4_GAME_8_TOTAL_POINTS_ODD_EVEN: 4675601,
    SET_4_GAME_9_TOTAL_POINTS_ODD_EVEN: 4675606,
    SET_4_GAME_10_TOTAL_POINTS_ODD_EVEN: 4675610,
    SET_4_GAME_11_TOTAL_POINTS_ODD_EVEN: 4675613,
    SET_4_GAME_12_TOTAL_POINTS_ODD_EVEN: 4675617,
    SET_5_GAME_1_TOTAL_POINTS_ODD_EVEN: 4675719,
    SET_5_GAME_2_TOTAL_POINTS_ODD_EVEN: 4675723,
    SET_5_GAME_3_TOTAL_POINTS_ODD_EVEN: 4675726,
    SET_5_GAME_4_TOTAL_POINTS_ODD_EVEN: 4675731,
    SET_5_GAME_5_TOTAL_POINTS_ODD_EVEN: 4675736,
    SET_5_GAME_6_TOTAL_POINTS_ODD_EVEN: 4675739,
    SET_5_GAME_7_TOTAL_POINTS_ODD_EVEN: 4675742,
    SET_5_GAME_8_TOTAL_POINTS_ODD_EVEN: 4675745,
    SET_5_GAME_9_TOTAL_POINTS_ODD_EVEN: 4675751,
    SET_5_GAME_10_TOTAL_POINTS_ODD_EVEN: 4675754,
    SET_5_GAME_11_TOTAL_POINTS_ODD_EVEN: 4675758,
    SET_5_GAME_12_TOTAL_POINTS_ODD_EVEN: 4675763,
    _1X2_ET: 5593136,
    TOTAL_SCORE_OVER_UNDER_ET: 5593172,
    _1X2_ET_1H: 5593427,
    TOTAL_SCORE_OVER_UNDER_ET_1H: 5593775,
    PENALTY_SHOOTOUT_WINNER: 5593883,
    _1X2_1I: 3673,
    _1X2_2I: 3689,
    _1X2_3I: 3701,
    _1X2_4I: 3715,
    _1X2_5I: 3723,
    _1X2_6I: 3727,
    _1X2_7I: 3731,
    _1X2_8I: 3735,
    _1X2_9I: 3739,
    CORRECT_SCORE_1P: 3941,
    CORRECT_SCORE_2P: 4185,
    CORRECT_SCORE_3P: 4307,
    MONEYLINE_1P: 4752,
    MONEYLINE_2P: 4764,
    MONEYLINE_3P: 4773,
    SET_SCORE_1S: 4845,
    TOTAL_SCORE_OVER_UNDER_1I: 4967,
    TOTAL_SCORE_OVER_UNDER_I2: 5012,
    TOTAL_SCORE_OVER_UNDER_I3: 5015,
    TOTAL_SCORE_OVER_UNDER_I4: 5018,
    TOTAL_SCORE_OVER_UNDER_I5: 5021,
    TOTAL_SCORE_OVER_UNDER_I6: 5024,
    TOTAL_SCORE_OVER_UNDER_I7: 5027,
    TOTAL_SCORE_OVER_UNDER_I8: 5030,
    TOTAL_SCORE_OVER_UNDER_I9: 5033,
    NEXT_SCORE_1H: 20209,
    _1X2_HIGH_AVAILABILITY: 13726839,
};

export const footballMarketsOrder = {
    [category.ALL]: [
        marketType._1X2_FT,
        marketType.TO_QUALIFY,
        marketType.DOUBLE_CHANCE_FT,
        marketType.BOTH_TEAMS_TO_SCORE_FT,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_FT,
        marketType.HT_FT,
        marketType.HANDICAP_1X2_FT,
        marketType.HANDICAP_1X2_1H,
        marketType.HANDICAP_1X2_2H,
        marketType._1X2_AND_TOTALS_FT,
        marketType.CORRECT_SCORE_FT,
        marketType.CLEAN_SHEET_HOME_TEAM_FT,
        marketType.CLEAN_SHEET_AWAY_TEAM_FT,
        marketType.TOTAL_GOALS_EXACT_FT,
        marketType.TOTAL_GOALS_EXACT_1H,
        marketType.TOTAL_GOALS_EXACT_2H,
        marketType.ODD_EVEN_FT,
        marketType.TEAM_TO_SCORE_4_WAY_FT,
        marketType.TEAM_TO_SCORE_LAST_3_WAY,
        marketType.ANYTIME_GOALSCORER,
        marketType.FIRST_GOALSCORER,
        marketType.BRACE_GOALSCORER,
        marketType.HAT_TRICK_GOALSCORER,
        marketType._1X2_1H,
        marketType._1X2_2H,
        marketType.TOTAL_SCORE_OVER_UNDER_1H,
        marketType.TOTAL_SCORE_OVER_UNDER_2H,
        marketType.HALF_MORE_GOALS_TOTAL,
        marketType.DOUBLE_CHANCE_1H,
        marketType.DOUBLE_CHANCE_2H,
        marketType.BOTH_TEAMS_TO_SCORE_1H,
        marketType.BOTH_TEAMS_TO_SCORE_2H,
        marketType.EITHER_HALF_WIN_HOME_FT,
        marketType.EITHER_HALF_WIN_AWAY_FT,
        marketType.CORRECT_SCORE_1H,
        marketType.CORRECT_SCORE_2H,
        marketType.SCORE_IN_BOTH_HALVES_HOME_FT,
        marketType.SCORE_IN_BOTH_HALVES_AWAY_FT,
        marketType.BOTH_HALVES_WIN_HOME_FT,
        marketType.BOTH_HALVES_WIN_AWAY_FT,
        marketType.CLEAN_SHEET_HOME_TEAM_1H,
        marketType.CLEAN_SHEET_HOME_TEAM_2H,
        marketType.CLEAN_SHEET_AWAY_TEAM_1H,
        marketType.CLEAN_SHEET_AWAY_TEAM_2H,
        marketType.ODD_EVEN_1H,
        marketType.ODD_EVEN_2H,
        marketType.FIRST_CORNER_1H,
        marketType.FIRST_CORNER_2H,
        marketType.CORNER_COUNT_1X2_1H,
        // marketType.CORNER_COUNT_1X2_2H, //TODO: need id and add to marketType
        marketType._1X2_AND_BOTH_TEAMS_TO_SCORE_FT,
        marketType.TOTAL_BOOKINGS_OVER_UNDER_FT,
        marketType.TOTAL_BOOKINGS_OVER_UNDER_1H,
        marketType.FIRST_BOOKING_FT,
        marketType.TOTAL_CORNERS_OVER_UNDER_FT,
        marketType.TOTAL_CORNERS_OVER_UNDER_1H,
        // marketType.TOTAL_CORNERS_OVER_UNDER_2H,  //TODO: need id and add to marketType
        marketType.CORNER_COUNT_1X2_FT,
        marketType.TOTAL_CORNERS_OVER_UNDER_FT_HOME_TEAM,
        marketType.TOTAL_CORNERS_OVER_UNDER_FT_AWAY_TEAM,
        marketType.FIRST_CORNER_FT,
        marketType.CORNER_COUNT_ODD_EVEN_FT,
        marketType.CORNER_COUNT_ODD_EVEN_1H,
        marketType.DRAW_NO_BET_FT,
        marketType.DRAW_NO_BET_1H,
        marketType.DRAW_NO_BET_2H,
        marketType.ASIAN_HANDICAP_FT,
        marketType.WIN_TO_NIL_HOME_TEAM_FT,
        marketType.WIN_TO_NIL_AWAY_TEAM_FT,
        marketType.TOTAL_GOALS_EXACT_FT_HOME_TEAM,
        marketType.TOTAL_GOALS_EXACT_FT_AWAY_TEAM,
        marketType.CORNER_ASIAN_HANDICAP_FT,
        marketType.CORNER_ASIAN_HANDICAP_1H,
        marketType.LAST_CORNER_FT,
        marketType.LAST_CORNER_1H,
        marketType.TOTAL_CORNERS_FT,
        marketType.TOTAL_CORNERS_1H,
        marketType.TOTAL_BOOKINGS_1H,
        marketType.HOME_NO_BET_FT,
        marketType.AWAY_NO_BET_FT,
        marketType._1X2_AND_TOTALS_1H,
        marketType._1X2_AND_TOTALS_2H,
        marketType._1X2_AND_BOTH_TEAMS_TO_SCORE_1H,
        marketType._1X2_AND_BOTH_TEAMS_TO_SCORE_2H,
        marketType.DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_FT,
        marketType.DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_1H,
        marketType.DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_2H,
        marketType.MULTISCORES_FT,
        marketType.MULTIGOALS_FT,
    ],
    [category.POPULAR]: [
        marketType._1X2_FT,
        marketType.DOUBLE_CHANCE_FT,
        marketType.BOTH_TEAMS_TO_SCORE_FT,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_FT,
        marketType.TOTAL_SCORE_OVER_UNDER_1H,
        marketType._1X2_1H,
        marketType.HT_FT,
        marketType.HANDICAP_1X2_FT,
        marketType._1X2_AND_TOTALS_FT,
    ],
    [category.GOALS]: [
        marketType.TOTAL_SCORE_OVER_UNDER_FT,
        marketType.TOTAL_SCORE_OVER_UNDER_1H,
        marketType.TOTAL_SCORE_OVER_UNDER_2H,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_AWAY_TEAM,
        marketType.BOTH_TEAMS_TO_SCORE_FT,
        marketType.BOTH_TEAMS_TO_SCORE_1H,
        marketType.BOTH_TEAMS_TO_SCORE_2H,
        marketType.HALF_MORE_GOALS_TOTAL,
        marketType.CORRECT_SCORE_FT,
        marketType.CORRECT_SCORE_1H,
        marketType.CORRECT_SCORE_2H,
        marketType.CLEAN_SHEET_HOME_TEAM_FT,
        marketType.CLEAN_SHEET_AWAY_TEAM_FT,
        marketType.CLEAN_SHEET_HOME_TEAM_1H,
        marketType.CLEAN_SHEET_AWAY_TEAM_1H,
        marketType.CLEAN_SHEET_HOME_TEAM_2H,
        marketType.CLEAN_SHEET_AWAY_TEAM_2H,
        marketType.TOTAL_GOALS_EXACT_FT_HOME_TEAM,
        marketType.TOTAL_GOALS_EXACT_FT_AWAY_TEAM,
        marketType.TOTAL_GOALS_EXACT_1H,
        marketType.TOTAL_GOALS_EXACT_2H,
        marketType.SCORE_IN_BOTH_HALVES_HOME_FT,
        marketType.SCORE_IN_BOTH_HALVES_AWAY_FT,
        marketType.TOTAL_GOALS_EXACT_FT,
        marketType.ODD_EVEN_FT,
        marketType.ODD_EVEN_1H,
        marketType.ODD_EVEN_2H,
        marketType.TEAM_TO_SCORE_4_WAY_FT,
        marketType.ANYTIME_GOALSCORER,
        marketType.FIRST_GOALSCORER,
        marketType.BRACE_GOALSCORER,
        marketType.HAT_TRICK_GOALSCORER,
        marketType.TEAM_TO_SCORE_LAST_3_WAY,
        marketType.MULTISCORES_FT,
        marketType.MULTIGOALS_FT,
    ],
    [category.HALVES]: [
        marketType._1X2_1H,
        marketType._1X2_2H,
        marketType.TOTAL_SCORE_OVER_UNDER_1H,
        marketType.TOTAL_SCORE_OVER_UNDER_2H,
        marketType.HALF_MORE_GOALS_TOTAL,
        marketType.DOUBLE_CHANCE_1H,
        marketType.DOUBLE_CHANCE_2H,
        marketType.BOTH_TEAMS_TO_SCORE_1H,
        marketType.BOTH_TEAMS_TO_SCORE_2H,
        marketType.EITHER_HALF_WIN_HOME_FT,
        marketType.EITHER_HALF_WIN_AWAY_FT,
        marketType.CORRECT_SCORE_1H,
        marketType.CORRECT_SCORE_2H,
        marketType.SCORE_IN_BOTH_HALVES_HOME_FT,
        marketType.SCORE_IN_BOTH_HALVES_AWAY_FT,
        marketType.BOTH_HALVES_WIN_HOME_FT,
        marketType.BOTH_HALVES_WIN_AWAY_FT,
        marketType.CLEAN_SHEET_HOME_TEAM_1H,
        marketType.CLEAN_SHEET_HOME_TEAM_2H,
        marketType.CLEAN_SHEET_AWAY_TEAM_1H,
        marketType.CLEAN_SHEET_AWAY_TEAM_2H,
        marketType.ODD_EVEN_1H,
        marketType.ODD_EVEN_2H,
        marketType.FIRST_CORNER_1H,
        marketType.FIRST_CORNER_2H,
        marketType.CORNER_COUNT_1X2_1H,
        marketType.CORNER_ASIAN_HANDICAP_1H,
        marketType.CORNER_COUNT_ODD_EVEN_1H,
        marketType.LAST_CORNER_1H,
        marketType.TOTAL_CORNERS_1H,
        marketType.TOTAL_BOOKINGS_1H,
        marketType.TOTAL_BOOKINGS_OVER_UNDER_1H,
        marketType.HANDICAP_1X2_1H,
        marketType.HANDICAP_1X2_2H,
        marketType.TOTAL_GOALS_EXACT_1H,
        marketType.TOTAL_GOALS_EXACT_2H,
        marketType.DRAW_NO_BET_1H,
        marketType.DRAW_NO_BET_2H,
        // marketType.CORNER_COUNT_1X2_2H, //TODO: need id and add to marketType
    ],
    [category.COMBOS]: [
        marketType._1X2_AND_TOTALS_FT,
        marketType._1X2_AND_TOTALS_1H,
        marketType._1X2_AND_TOTALS_2H,
        marketType._1X2_AND_BOTH_TEAMS_TO_SCORE_FT,
        marketType._1X2_AND_BOTH_TEAMS_TO_SCORE_1H,
        marketType._1X2_AND_BOTH_TEAMS_TO_SCORE_2H,
        marketType.DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_FT,
        marketType.DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_1H,
        marketType.DOUBLE_CHANCE_AND_BOTH_TEAMS_TO_SCORE_2H,
    ],
    [category.SPECIALS]: [
        marketType.TO_QUALIFY,
        marketType.HT_FT,
        marketType.DRAW_NO_BET_FT,
        marketType.HANDICAP_1X2_FT,
        marketType.ASIAN_HANDICAP_FT,
        marketType.HOME_NO_BET_FT,
        marketType.AWAY_NO_BET_FT,
        marketType.DRAW_NO_BET_1H,
        marketType.DRAW_NO_BET_2H,
        marketType.EITHER_HALF_WIN_HOME_FT,
        marketType.EITHER_HALF_WIN_AWAY_FT,
        marketType.BOTH_HALVES_WIN_HOME_FT,
        marketType.BOTH_HALVES_WIN_AWAY_FT,
        marketType.WIN_TO_NIL_HOME_TEAM_FT,
        marketType.WIN_TO_NIL_AWAY_TEAM_FT,
        marketType.TEAM_TO_SCORE_4_WAY_FT,
    ],
    [category.CORNERS]: [
        marketType.TOTAL_CORNERS_OVER_UNDER_FT,
        marketType.TOTAL_CORNERS_OVER_UNDER_1H,
        // marketType.TOTAL_CORNERS_OVER_UNDER_2H, //TODO: need id and add to marketType
        marketType.CORNER_COUNT_1X2_FT,
        marketType.CORNER_COUNT_1X2_1H,
        // marketType.CORNER_COUNT_1X2_2H, //TODO: need id and add to marketType
        marketType.TOTAL_CORNERS_OVER_UNDER_FT_HOME_TEAM,
        marketType.TOTAL_CORNERS_OVER_UNDER_FT_AWAY_TEAM,
        marketType.FIRST_CORNER_FT,
        marketType.FIRST_CORNER_1H,
        marketType.FIRST_CORNER_2H,
        marketType.LAST_CORNER_FT,
        marketType.LAST_CORNER_1H,
        marketType.TOTAL_CORNERS_FT,
        marketType.TOTAL_CORNERS_1H,
        marketType.CORNER_COUNT_ODD_EVEN_FT,
        marketType.CORNER_COUNT_ODD_EVEN_1H,
        marketType.CORNER_ASIAN_HANDICAP_FT,
        marketType.CORNER_ASIAN_HANDICAP_1H,
    ],
    [category.BOOKINGS]: [marketType.FIRST_BOOKING_FT, marketType.TOTAL_BOOKINGS_OVER_UNDER_FT, marketType.TOTAL_BOOKINGS_1H],
    [category.PLAYERS]: [
        marketType.ANYTIME_GOALSCORER,
        marketType.FIRST_GOALSCORER,
        marketType.BRACE_GOALSCORER,
        marketType.HAT_TRICK_GOALSCORER,
    ],
};

export const basketballMarketsOrder = {
    [category.ALL]: [
        marketType.MONEYLINE_FT_INCL_OT,
        marketType.MATCH_WINNER_AND_TOTALS_FT_INCL_OT,
        marketType.ODD_EVEN_FT_INCL_OT,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_HOME_TEAM,
        marketType.ASIAN_HANDICAP_FT_INCL_OT,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_1H,
        marketType.TOTAL_SCORE_OVER_UNDER_3Q,
        marketType.MONEYLINE_3Q,
        marketType.MONEYLINE_4Q,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q,
        marketType.TOTAL_SCORE_OVER_UNDER_2Q,
        marketType.MONEYLINE_1H,
        marketType.TOTAL_SCORE_OVER_UNDER_2H,
        marketType.TOTAL_SCORE_OVER_UNDER_4Q,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q_HOME_TEAM,
    ],
    [category.POPULAR]: [
        marketType.MONEYLINE_FT_INCL_OT,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT, // ? O/U Full Time Inc. Over Time
        marketType.MATCH_WINNER_AND_TOTALS_FT_INCL_OT,
        marketType.ODD_EVEN_FT_INCL_OT,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_HOME_TEAM,
    ],
    [category.POINTS]: [
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT, // ? O/U Full Time Inc. Over Time
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q_AWAY_TEAM,
        marketType.ODD_EVEN_FT_INCL_OT,
        marketType.ODD_EVEN_1H,
        marketType.ODD_EVEN_1Q,
    ],
    [category.HALVES]: [marketType.MONEYLINE_1H, marketType._1X2_1H, marketType.ODD_EVEN_1H],
    [category.QUARTERS]: [
        marketType.HIGHEST_SCORING_QUARTER,
        marketType.TEAM_WITH_HIGHEST_SCORING_QUARTER,
        marketType.MONEYLINE_1Q,
        marketType.MONEYLINE_2Q,
        marketType.MONEYLINE_3Q,
        marketType.MONEYLINE_4Q,
        marketType._1X2_1Q,
        marketType._1X2_2Q,
        marketType._1X2_3Q,
        marketType._1X2_4Q,
        marketType.ASIAN_HANDICAP_1Q,
        marketType.ASIAN_HANDICAP_2Q,
        marketType.ASIAN_HANDICAP_3Q,
        marketType.ASIAN_HANDICAP_4Q,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_2Q_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_2Q_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_3Q_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_3Q_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_4Q_HOME_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_4Q_AWAY_TEAM,
        marketType.TOTAL_SCORE_OVER_UNDER_1Q,
        marketType.TOTAL_SCORE_OVER_UNDER_2Q,
        marketType.TOTAL_SCORE_OVER_UNDER_3Q,
        marketType.TOTAL_SCORE_OVER_UNDER_4Q,
        marketType.ODD_EVEN_1Q,
        marketType.ODD_EVEN_2Q,
        marketType.ODD_EVEN_3Q,
        marketType.ODD_EVEN_4Q,
    ],
    [category.COMBOS]: [marketType.MATCH_WINNER_AND_TOTALS_FT_INCL_OT],
    [category.SPECIALS]: [marketType.MONEYLINE_FT_INCL_OT, marketType.MONEYLINE_1H, marketType.MONEYLINE_1Q],
};

export const tennisMarketsOrder = {
    [category.ALL]: [
        marketType.MONEYLINE_FT, // Winner
        marketType.MATCH_HANDICAP_GAMES_2_WAY_FT, // Game Handicap
        marketType.TOTAL_GAMES_OVER_UNDER_FT, // Total games
        marketType.TOTAL_SETS_OVER_UNDER_FT, // Total sets
        marketType.MONEYLINE_1S, // First set - winner
        marketType.MONEYLINE_2S, // Second set - winner
        marketType.MONEYLINE_3S, // Third set - winner
        marketType.MONEYLINE_4S, // Fourth set - winner
        marketType.MONEYLINE_5S, // Fifth set - winner
        marketType.SET_1_GAME_HANDICAP, // Set 1 game handicap
        marketType.SET_2_GAME_HANDICAP, // Set 2 game handicap
        marketType.SET_3_GAME_HANDICAP, // Set 3 game handicap
        marketType.SET_4_GAME_HANDICAP, // Set 4 game handicap
        marketType.SET_5_GAME_HANDICAP, // Set 5 game handicap
        marketType.TOTAL_GAMES_OVER_UNDER_1S, // Total Games - Over/Under First set
        marketType.TOTAL_GAMES_OVER_UNDER_2S, // Total Games - Over/Under Second set
        marketType.TOTAL_GAMES_OVER_UNDER_3S, // Total Games - Over/Under Third set
        marketType.TOTAL_GAMES_OVER_UNDER_4S, // Total Games - Over/Under 4th set
        marketType.TOTAL_GAMES_OVER_UNDER_5S, // Total Games - Over/Under 5th set
        marketType.ODD_EVEN_GAMES_1S, // Odd / Even Games First set
        marketType.ODD_EVEN_GAMES_2S, // Odd / Even Games Second set
        marketType.ODD_EVEN_GAMES_3S, // Odd / Even Games Third set
        marketType.ODD_EVEN_GAMES_4S, // Odd / Even Games 4th set
        marketType.ODD_EVEN_GAMES_5S, // Odd / Even Games 5th set
        marketType.TIEBREAK_IN_FIRST_SET, // Tiebreak in First Set
        marketType.TIEBREAK_IN_SECOND_SET, // Tiebreak in Second Set
        marketType.TIEBREAK_IN_THIRD_SET, // Tiebreak in Third Set
        marketType.TIEBREAK_IN_FOURTH_SET, // Tiebreak in Fourth Set
        marketType.TIEBREAK_IN_FIFTH_SET, // Tiebreak in Fifth Set
        marketType.ANY_SET_WINNER_HOME, // Competitor1 to win a set
        marketType.ANY_SET_WINNER_AWAY, // Competitor2 to win a set
        marketType.GAMES_OVER_UNDER_HOME_FT, // Competitor1 total games
        marketType.GAMES_OVER_UNDER_AWAY_FT, // Competitor2 total games
        marketType.WIN_TO_NIL_ANY_SET_FT_INCL_OT, // Any set to nil
        marketType.TIEBREAK_IN_ANY_SET, // Will there be a tiebreak
        marketType.ODD_EVEN_GAMES_FT, // Odd/even games
        marketType.CORRECT_SCORE_FT, // Correct score ( 2 set win)
    ],
    [category.POPULAR]: [
        marketType.MONEYLINE_FT, // Winner
        marketType.TOTAL_GAMES_OVER_UNDER_FT, // Total games
    ],
    [category.SETS]: [
        marketType.MONEYLINE_1S, // First set - winner
        marketType.MONEYLINE_2S, // Second set - winner
        marketType.MONEYLINE_3S, // Third set - winner
        marketType.MONEYLINE_4S, // Fourth set - winner
        marketType.MONEYLINE_5S, // Fifth set - winner
        marketType.SET_1_GAME_HANDICAP, // Set 1 game handicap
        marketType.SET_2_GAME_HANDICAP, // Set 2 game handicap
        marketType.SET_3_GAME_HANDICAP, // Set 3 game handicap
        marketType.SET_4_GAME_HANDICAP, // Set 4 game handicap
        marketType.SET_5_GAME_HANDICAP, // Set 5 game handicap
        marketType.TOTAL_GAMES_OVER_UNDER_1S, // Total Games - Over/Under First set
        marketType.TOTAL_GAMES_OVER_UNDER_2S, // Total Games - Over/Under Second set
        marketType.TOTAL_GAMES_OVER_UNDER_3S, // Total Games - Over/Under Third set
        marketType.TOTAL_GAMES_OVER_UNDER_4S, // Total Games - Over/Under 4th set
        marketType.TOTAL_GAMES_OVER_UNDER_5S, // Total Games - Over/Under 5th set
        marketType.TOTAL_SETS_OVER_UNDER_FT, // Total sets
        marketType.CORRECT_SCORE_FT, // Correct score ( 2 set win)
        marketType.WIN_TO_NIL_ANY_SET_FT_INCL_OT, // Any set to nil
        marketType.TIEBREAK_IN_ANY_SET, // Will there be a tiebreak
        marketType.ODD_EVEN_GAMES_1S, // Odd / Even Games First set
        marketType.ODD_EVEN_GAMES_2S, // Odd / Even Games Second set
        marketType.ODD_EVEN_GAMES_3S, // Odd / Even Games Third set
        marketType.ODD_EVEN_GAMES_4S, // Odd / Even Games 4th set
        marketType.ODD_EVEN_GAMES_5S, // Odd / Even Games 5th set
        marketType.TIEBREAK_IN_FIRST_SET, // Tiebreak in First Set
        marketType.TIEBREAK_IN_SECOND_SET, // Tiebreak in Second Set
        marketType.TIEBREAK_IN_THIRD_SET, // Tiebreak in Third Set
        marketType.TIEBREAK_IN_FOURTH_SET, // Tiebreak in Fourth Set
        marketType.TIEBREAK_IN_FIFTH_SET, // Tiebreak in Fifth Set
    ],
    [category.GAMES]: [
        marketType.MATCH_HANDICAP_GAMES_2_WAY_FT, // Game Handicap
        marketType.TOTAL_GAMES_OVER_UNDER_FT, // Total games
        marketType.ODD_EVEN_GAMES_FT, // Odd/even games
    ],
    [category.PLAYERS]: [
        marketType.ANY_SET_WINNER_HOME, // Competitor1 to win a set
        marketType.ANY_SET_WINNER_AWAY, // Competitor2 to win a set
        marketType.GAMES_OVER_UNDER_HOME_FT, // Competitor1 total games
        marketType.GAMES_OVER_UNDER_AWAY_FT, // Competitor2 total games
    ],
};

export const sportCategory = {
    FOOTBALL: {
        key: 2,
        name: 'football',
    },
    BASKETBALL: {
        key: 3,
        name: 'basketball',
    },
    TENNIS: {
        key: 452,
        name: 'tennis',
    },
    RUGBY: {
        key: 453,
        name: 'rugby',
    },
    BOXING: {
        key: 454,
        name: 'boxing',
    },
    MOTORSPORT: {
        key: 455,
        name: 'motorsport',
    },
    CHESS: {
        key: 456,
        name: 'chess',
    },
    RUGBY_LEAGUE: {
        key: 458,
        name: 'rugby-league',
    },
    AMERICAN_FOOTBALL: {
        key: 459,
        name: 'american-football',
    },
    VOLLEYBALL: {
        key: 461,
        name: 'volleyball',
    },
    CRICKET: {
        key: 462,
        name: 'cricket',
    },
    TABLE_TENNIS: {
        key: 463,
        name: 'table-tennis',
    },
    ICE_HOCKEY: {
        key: 464,
        name: 'ice-hockey',
    },
};

export const sportFilters = {
    [sportCategory.FOOTBALL.name]: footballMarketsOrder,
    [sportCategory.BASKETBALL.name]: basketballMarketsOrder,
    [sportCategory.TENNIS.name]: tennisMarketsOrder,
};

export const EXCLUDED_MARKET_NAMES = ['Specials', 'Others'];

export const NO_SORT_ON_ALL_TAB_SPORTS = ['basketball'];

export const MARKET_TYPE_GROUPING = {
    [sportCategory.FOOTBALL.key]: [
        { K: marketType._1X2_FT, T: '_1X2' },
        { K: marketType.TOTAL_SCORE_OVER_UNDER_FT, T: 'OU' },
        { K: marketType.BOTH_TEAMS_TO_SCORE_FT, T: 'BTTS' },
        { K: marketType.DOUBLE_CHANCE_FT, T: 'DC' },
        { K: marketType.HT_FT, T: 'HT_FT' },
    ],
    [sportCategory.BASKETBALL.key]: [
        { K: marketType.MONEYLINE_FT_INCL_OT, T: 'ML' },
        { K: marketType.TOTAL_SCORE_OVER_UNDER_FT_INCL_OT, T: 'OU' },
        { K: marketType.ODD_EVEN_FT_INCL_OT, T: 'OE' },
        { K: marketType.ASIAN_HANDICAP_FT_INCL_OT, T: 'HCAP' }, // 'TWO_WAY_HCAP_OT' == ASIAN_HANDICAP_FT_INCL_OT ?
    ],
    [sportCategory.TENNIS.key]: [
        { K: marketType.MONEYLINE_FT, T: 'ML' },
        { K: marketType.TOTAL_GAMES_OVER_UNDER_FT, T: 'OU' },
        { K: marketType.MATCH_HANDICAP_GAMES_2_WAY_FT, T: 'HCAP' },
    ],
    [sportCategory.RUGBY.key]: [
        { K: '_1X2', T: '_1X2' },
        { K: 'OU_FT', T: 'OU' },
        { K: 'ODD_EVEN', T: 'OE' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    [sportCategory.BOXING.key]: [{ K: 'MONEYLINE_FT_OT', T: 'ML' }],
    [sportCategory.RUGBY_LEAGUE.key]: [
        { K: '_1X2', T: '_1X2' },
        { K: 'OU_FT', T: 'OU' },
        { K: 'ODD_EVEN', T: 'OE' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    [sportCategory.AMERICAN_FOOTBALL.key]: [
        { K: 'MONEYLINE_FT_OT', T: 'ML' },
        { K: 'OU_FT_OT', T: 'OU' },
        { K: 'TWO_WAY_HCAP_OT', T: 'HCAP' },
    ],
    460: [
        // what type of sport?
        { K: 'OU_FT', T: 'OU' },
    ],
    [sportCategory.VOLLEYBALL.key]: [
        { K: 'MONEYLINE_FT', T: 'ML' },
        { K: 'POINTS_OU', T: 'OU' },
        { K: 'ODD_EVEN_POINTS', T: 'OE' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    [sportCategory.CRICKET.key]: [{ K: '_1X2', T: '_1X2' }],
    [sportCategory.TABLE_TENNIS.key]: [{ K: 'MONEYLINE_FT', T: 'ML' }],
    [sportCategory.ICE_HOCKEY.key]: [
        { K: 'MONEYLINE_FT_OT', T: 'ML' },
        { K: 'OU_FT_OT', T: 'OU' },
        { K: 'ODD_EVEN_FT_OT', T: 'OE' },
        { K: 'TWO_WAY_HCAP_OT', T: 'HCAP' },
    ],
};

export const marketPreferenceType = {
    MAIN: 'MAIN',
    ALL: 'ALL',
    DEFINED: 'DEFINED',
};

export const DEFAULT_EVENT_QUERY_AMOUNT = 20;
export const MAX_LIVE_EVENT_QUERY_AMOUNT = 100;
export const LIVE_EVENT_TYPE = 'LIVE';
