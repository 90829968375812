<template>
    <div class="search">
        <form v-on:submit.prevent class="search-bar table">
            <InputField
                class="search-input row-cell"
                type="text"
                name="search-mobile"
                :formName="formNameId"
                :attrs="{ placeholder: $t('ui.search.mobileSearchPlaceholder') }"
                :value="searchQuery"
                @value="searchQuery = $event"
            />
            <div class="button-search row-cell">
                <input type="submit" class="button button-primary" @click="processSearch()" :value="$t('ui.search.search')" />
            </div>
        </form>
    </div>
</template>

<script>
import { InputField } from '@agi.packages/core/components';
import { routeName } from '@/router/const';

export default {
    name: 'SearchBar',
    components: { InputField },
    data() {
        return {
            searchQuery: '',
            searchTimeout: undefined,
            formNameId: 'search-form',
        };
    },
    computed: {
        preventSearch() {
            return this.searchQuery.length < 3 || this.$route.query.searchQuery === this.searchQuery;
        },
    },
    methods: {
        processSearch(event) {
            if (!event) {
                this.$emit('toggle-mobile-search');
            }

            if (!this.searchQuery) {
                if (this.$router.currentRoute.name === routeName.SEARCH) {
                    this.$router.back();
                }
                return;
            } else if ((event && event.keyCode === 13) || this.preventSearch) {
                return;
            }
            this.search();
        },
        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(
                function () {
                    this.$router.currentRoute.name === routeName.SEARCH
                        ? this.$router.replace({ name: routeName.SEARCH, query: { searchQuery: this.searchQuery } })
                        : this.$router.push({ name: routeName.SEARCH, query: { searchQuery: this.searchQuery } });
                }.bind(this),
                800
            );
            this.$gtm.query({
                event: 'search_navigation',
                searchQuery: this.searchQuery,
            });
        },
    },
    created() {
        this.$router.onReady(() => {
            this.searchQuery = this.$route.query.searchQuery || this.searchQuery;
        });
    },
};
</script>

<style lang="scss" scoped>
.search {
    width: 100%;
    border-top: $header-mobile-border;
    background: $betpawa-black;
    padding: 8px;

    &-bar {
        .search-input {
            width: 100%;
            margin: 0;
            padding-right: 8px;
        }
    }

    .button-search {
        border: 1px solid $search-button-border-color;

        .button {
            color: $search-button-color;
            background-color: $search-button-background;
            padding: 0 8px;
        }
    }
}
</style>
