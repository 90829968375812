export default {
    createDeposit: 'api/payment/v1/deposit/request/create',
    getDeposit: 'api/payment/v1/deposit/request',
    getDepositOptions: '/api/payment/v1/deposit/options',
    getDepositTypeDetails: 'api/payment/v1/deposit/type',
    getDepositTelcoFee: 'api/payment/v1/deposit/fee',
    withdraw: 'api/payment/v1/payout/request/process',
    withdrawDisabled: 'api/payment/v1/payout/request/disabled/process',
    cancelPayout: 'api/payment/v2/withdrawal/cancel',
    getPendingPayouts: 'api/payment/v1/payout/request/pending',
    getPayoutTypeList: 'api/payment/v2/payout/type/active',
    getPayoutTypeListDisabled: 'api/payment/v1/payout/type/disabled',
    getPayoutTaxAmount: 'api/payment/v1/payout/request/tax',
};
