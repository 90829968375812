var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lmenu-main"},[_c('LeftMenuCategories',[_c('span',{staticClass:"close",on:{"click":_vm.hideLeftMenu}},[_c('SvgIcon',{attrs:{"icon-id":"icon-close"}})],1)]),_vm._l((_vm.leftMenu),function(menuItem,index){return _c('MenuItem',{key:menuItem.pageId + '-left-menu-' + index,attrs:{"selected":_vm.hasStrapiMenu ? _vm.isCurrentMenuItem(menuItem) && !menuItem.disableHighlight : _vm.currentRoute.name === menuItem.pageId,"link":{
            path: menuItem.url,
            query: menuItem.query,
        },"item":{
            badge: _vm.hasStrapiMenu ? menuItem.badge : _vm.$t(menuItem.badge),
            badgeType: _vm.hasStrapiMenu ? menuItem.badgeType : 'new-messages',
            badgeColor: _vm.hasStrapiMenu ? menuItem.badgeColor : '',
            icon: _vm.hasStrapiMenu ? menuItem.icon : _vm.menuIcons[menuItem.pageId.toLowerCase()],
            text: _vm.hasStrapiMenu ? menuItem.name : _vm.$t(menuItem.key, { casinoName: _vm.casinoName }),
            control: {
                text: menuItem.pageId === 'Live' && _vm.liveEventsCount,
            },
        }},nativeOn:{"click":function($event){_vm.currentRoute.name === menuItem.pageId ? _vm.hideLeftMenu() : _vm.trackMainNav(menuItem.pageId)}}})}),(!!_vm.tournaments && _vm.tournaments.length)?_c('ExpansionPanel',{attrs:{"data-test-id":"menu-tournaments","title":_vm.$t('ui.lMenu.tournaments'),"control":!_vm.isPresto,"expanded":true}},_vm._l((_vm.tournaments),function(tournament,index){return _c('MenuItem',{key:("tournament-" + (tournament.id) + "-" + index),staticClass:"sub-menu",attrs:{"selected":_vm.currentRoute.id === tournament.id,"link":{ name: _vm.routeGroupName, params: { id: tournament.id } },"item":{
                text: tournament.name,
                control: {
                    text: tournament.eventCount,
                    iconDisabled: true,
                },
            },"data-test-id":("tournament-" + (tournament.id))},nativeOn:{"click":function($event){return _vm.trackMainNav(tournament.name, 'tournaments_nav')}}},[_c('template',{slot:"icon"},[(tournament.region)?_c('ImageIcon',{staticClass:"icon-status",attrs:{"src":tournament.url || (tournament.region && ("img/flags/" + (tournament.regionSlug.toLowerCase()) + ".png")),"altSrc":tournament.regionSlug && ("img/flags/" + (tournament.parentRegionSlug) + ".png")}}):_vm._e()],1)],2)}),1):_vm._e(),_c('CountryMenus')],2)}
var staticRenderFns = []

export { render, staticRenderFns }