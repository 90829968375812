import { helper } from '@agi.packages/core';

export default {
    inserted: (el, binding, vnode) => {
        const target = vnode.context.$scroll.getScroll();
        const { handler, distance, disabled, debounceTimeout } = binding.value;
        const eventHandler = (event) => {
            if (el.scrollHeight - event.target.scrollTop - event.target.offsetHeight < (distance || 250)) {
                if (typeof handler === 'function') {
                    handler();
                }
            }
        };

        if (disabled || !target) {
            return;
        }

        const debounceHandler = helper.debounce(eventHandler, debounceTimeout);
        const scrollHandler = debounceTimeout ? debounceHandler : eventHandler;

        target.addEventListener('scroll', scrollHandler);
        el.$destroy = () => {
            target.removeEventListener('scroll', scrollHandler);
        };
    },
    unbind: (el, { value }) => {
        !value.disabled && el.$destroy();
    },
};
