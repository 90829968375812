<template>
    <div class="app-error-page">
        <SvgIcon iconId="logo-black" iconFallback="logo-black" class="logo-black" />
        <div class="container">
            <ErrorPage
                :imgSrc="appErrorImage"
                :title="$t('ui.common.error.appSomethingWentWrong')"
                :sectionList="[errorText]"
                :button="{ text: $t('ui.common.refreshPage'), emit: true }"
                @button:click="errorButtonClick()"
            />
        </div>
    </div>
</template>

<script>
import ErrorPage from '@/components/Pages/ErrorPage.vue';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { deviceType } from '@agi.packages/core';

export default {
    name: 'AppErrorPage',
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    components: { ErrorPage },
    computed: {
        errorText() {
            return !this.isPresto ? this.$t('ui.common.error.appError') : this.$t('ui.common.error.appRefreshError');
        },
        appErrorImage() {
            return !this.isPresto ? ERROR_IMAGES.errorApp : '';
        },
    },
    methods: {
        errorButtonClick() {
            window.location.reload();
        },
    },
};
</script>

<style lang="scss">
.app-error-page {
    background: $body-background-color;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;

    .logo-black {
        width: 108px;
        margin: 12px;
    }

    .container {
        display: flex;
        justify-content: center;
        .error {
            margin-top: 52px;
            max-width: 320px;
            &-title {
                margin-bottom: 12px;
            }
            &-button {
                margin-top: 24px;
            }
            img {
                max-width: 237px;
                width: 100%;
                margin-bottom: 24px;
            }
            @include oldschool {
                margin-top: 8px;
                &-title {
                    line-height: 27px;
                }
                .button-wrapper {
                    width: 100%;
                }
                &-button {
                    width: 100%;
                    margin: 16px 0;
                }
                &-description {
                    color: $dark-green;
                    line-height: 27px;
                }
            }
        }
    }
}
</style>
