<template>
    <div :id="widgetId" class="cloudflare-widget" v-show="visible || isVisible" ref="container" />
</template>
<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

import { deviceType } from '@agi.packages/core';
import { auth } from '@agi.packages/platform';

import { widgetSize } from '@/js/plugins/cloudflare/const';

export default {
    name: 'TurnstileWidget',
    data: () => ({
        isPresto: deviceType.isPresto(),
    }),
    props: {
        visible: {
            type: Boolean,
            default: true,
        },
        widgetId: {
            type: String,
            required: false,
            default: 'turnstile-widget',
        },
        name: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState({
            turnstile(state) {
                return state.ui.turnstile.includes(this.name);
            },
        }),
        ...mapGetters({
            needUpdate: auth.getter.NEED_UPDATE_SECURED_TOKEN,
            token: auth.getter.SECURED_TOKEN,
        }),
        accessDeniedError() {
            return this.$t('errors.ACCESS_DENIED');
        },
        isVisible() {
            return this.$route.query.turnstile;
        },
        byPass() {
            const { protection } = this.$route.query;
            return this.isPresto || protection;
        },
        hasToken() {
            return this.byPass || !this.turnstile || this.token;
        },
        isEnabled() {
            return !this.byPass && this.turnstile;
        },
    },
    methods: {
        ...mapActions({
            enableAccess: auth.action.ENABLE_ACCESS,
        }),
        ...mapMutations({
            setTurnstileError: auth.mutation.SET_TURNSTILE_ERROR,
            resetTurnstileError: auth.mutation.RESET_TURNSTILE_ERROR,
        }),
        callback(token) {
            this.resetTurnstileError();
            this.enableAccess(token).then(() => {
                this.$emit('token', token);
            });
        },
        expired(expired) {
            this.enableAccess().then(() => {
                this.$cloudflare.turnstile.reset();
                this.handleError(this.accessDeniedError, { expired });
            });
        },
        error(error) {
            this.enableAccess().then(() => {
                this.$cloudflare.turnstile.reset();
                this.handleError(this.accessDeniedError, { error });
            });
        },
        timeout(timeout) {
            this.enableAccess().then(() => {
                this.$cloudflare.turnstile.reset();
                this.handleError(this.accessDeniedError, { timeout }, false);
            });
        },
        handleError(message, data, emitEvent = true) {
            if (emitEvent) {
                this.setTurnstileError(message || '');
                this.$emit('error', message || '');
            }
            message && console.error(message, data);
        },
        callCloudflare() {
            this.isEnabled &&
                this.$cloudflare
                    .bootstrap({
                        callback: this.callback,
                        expired: this.expired,
                        error: this.error,
                        timeout: this.timeout,
                        widgetOptions: {
                            size: this.$mq.xsUp ? widgetSize.NORMAL : widgetSize.COMPACT,
                        },
                    })
                    .catch((error) => {
                        this.handleError(this.accessDeniedError, { error, reason: 'Challenge not loaded!' });
                    });
        },
    },
    watch: {
        needUpdate(value) {
            if (value) {
                this.$store.dispatch(auth.action.NEED_UPDATE_SECURED_TOKEN, { needUpdate: false });
                this.callCloudflare();
            }
        },
    },
    mounted() {
        this.enableAccess(this.hasToken);
        this.callCloudflare();
    },
    beforeDestroy() {
        this.enableAccess();
        this.resetTurnstileError();
        this.isEnabled && this.$cloudflare.turnstile.remove();
    },
};
</script>

<style scoped lang="scss">
.cloudflare-widget {
    &:has(div) {
        text-align: center;
        padding-top: 16px;
    }
}
</style>
