import { action as translationAction } from '@/store/modules/translations';
import localLocales from '@localization/translations.json';

class Manager {
    constructor(locales, defaultLang) {
        // TODO: add translation debugger
        // console.log('Manager constructor', ...arguments)
        this.locales = locales;
        this.reservedProps = ['indefinite'];
        this.current = defaultLang || this.locales[0];
    }
    /**
     * Switch language
     * @param {Array} locales Available locales
     * @param {String} language current language
     */
    set(language, locales) {
        this.current = language;
        this.locales = locales || this.locales;
    }
    /**
     * Get translation text by key
     * @param {String} key
     * @param {Function|null} interpolate
     * @param {Object|null} options
     */
    get(key, interpolate, options) {
        const props = options || {};
        const { indefinite } = props;
        const str = this.locales[this.current].get(key);
        if (!indefinite && typeof str !== 'string') {
            // TODO: add translation debugger
            !indefinite && console.error(`[v-translations] Not found key '${key}'!`);
            return indefinite ? '' : `${key}`;
        }
        const hasProps = !!Object.keys(props).filter((it) => !this.reservedProps.includes(it)).length;
        return (str && hasProps && interpolate(str, props)) || str;
    }
}

class Lang {
    constructor(lang) {
        this.serialize = {};
        this.keyGen(lang);
    }
    /**
     * Get translation text by key
     * @param {String} key
     */
    get(key) {
        if (key in this.serialize) {
            // TODO: add translation debugger
            // console.log(`[v-translations] found key '${key}' - ${this.serialize[key]}`)
            return this.serialize[key];
        }
        return '';
    }

    /**
     * Generate flatten key & value list
     * @param {Object} object
     * @param {Boolean} inside recursive
     * @param {String} path key of parent object for next child
     */
    keyGen(object, inside = false, path = false) {
        // Clear meta data
        if (!inside) {
            this.keys = [];
            this.depth = 0;
            this.serialize = {};
        }
        for (let key in object) {
            // Check key is root
            if (this.keys[this.depth] === void 0) {
                this.keys[this.depth] = path || '';
            }
            if (typeof object[key] === 'object') {
                this.keys[this.depth] += `${key}.`;
                this.keyGen(object[key], true, this.keys[this.depth]);
            } else {
                // If value is not object
                this.keys[this.depth] += key;
                this.serialize[this.keys[this.depth]] = object[key];
                this.depth++;
            }
        }
    }
}

export default {
    install(Vue, { store, languageGetter, setLocalesMutation, interpolate = () => {}, flatten = null }) {
        store.subscribe(({ type, payload }) => {
            if (type === setLocalesMutation) {
                store.dispatch(translationAction.SET_DETECTED_LANGUAGE);
                const { language, locales } = store.getters[languageGetter];
                const languages = {};
                const languageKeys = Object.keys(locales);
                languageKeys.forEach((key) => {
                    const lang = flatten ? flatten(locales[key]) : locales[key];
                    languages[key] = new Lang(lang);
                });
                const defaultLang = languageKeys.find((it) => it === language);
                const manager = new Manager(languages, defaultLang || languageKeys[0]);
                Vue.prototype.$t = Vue.$t = (value, props = null) => manager.get(value, interpolate, props);
            }
        });
        if (Object.keys(localLocales).length) {
            store.commit(setLocalesMutation, { locales: localLocales }, { root: true });
        }
    },
};
