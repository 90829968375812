<template>
    <div class="deposit-list">
        <h2 v-if="data.content.isTitleEnabled" class="deposit-list-title">{{ data.content.title }}</h2>
        <p v-if="data.content.isTextEnabled" class="deposit-list-text">{{ data.content.text }}</p>
        <div v-if="providersList" class="deposit-list-body">
            <div class="deposit-list-items">
                <p class="deposit-list-items-text">{{ $t('ui.payment.deposit.selectDepositMethod') }}</p>
                <router-link
                    class="deposit-list-item"
                    v-for="(provider, index) of providersList"
                    :key="`${index}-deposit-method-${provider.id}`"
                    :to="{ path: provider.pageUrl }"
                >
                    <div
                        class="deposit-list-item-wrapper"
                        :class="{ 'deposit-list-item-highlighted': provider.isDepositOptionHighlighted }"
                    >
                        <div class="deposit-list-item-title">
                            <div class="deposit-list-item-preview">
                                <img :src="provider.mediaUrl" alt="media" />
                            </div>
                            <div class="deposit-list-item-text">
                                <h5>{{ provider.text }}</h5>
                                <p class="help-text">
                                    {{ provider.helpText }}
                                </p>
                            </div>
                        </div>
                        <div class="deposit-list-item-action">
                            <div v-if="provider.feeData.label" class="fee-amount" :class="provider.feeData.bgColor">
                                <span>{{ provider.feeData.label }}</span>
                            </div>
                            <div class="icon">
                                <SvgIcon iconId="arrow_right" :verticalAlign="'baseline'" />
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { action, getter } from '@agi.packages/payment';
import { helper } from '@/modules/core';

const BG_COLORS = {
    GREY: 'grey',
    GREEN: 'green',
};
const DEFAULT_BG_COLOR = BG_COLORS.GREY;

export default {
    name: 'Depositoptions',
    props: ['data'],
    mounted() {
        this.$store.dispatch(action.FETCH_PROVIDERS_OPTIONS);
    },
    computed: {
        ...mapGetters({
            providers: getter.GET_PROVIDERS_LIST,
        }),
        providersList() {
            return this.providers.map(
                ({ id, pageUrl, mediaUrl, text, maxDepositAmount, additionalData, showFeeAsNumber, isDepositOptionHighlighted }) => {
                    const { depositFee, badgeForDepositList } = additionalData || {};
                    const { bgColor, label } = badgeForDepositList || {};

                    const feeData = { label: label || '', bgColor: `bg-${bgColor || DEFAULT_BG_COLOR}` };
                    if (depositFee) {
                        const fee = `${depositFee}${showFeeAsNumber ? '' : '%'}`;
                        feeData.label = this.$t('ui.payment.deposit.fee', { fee });
                        feeData.bgColor = `bg-${BG_COLORS.GREY}`;
                    }

                    return {
                        id,
                        pageUrl,
                        mediaUrl,
                        isDepositOptionHighlighted: !!isDepositOptionHighlighted,
                        text,
                        helpText: this.$t('ui.payment.deposit.maxAmount', {
                            amount: this.$numberFormat(maxDepositAmount, null),
                        }),
                        feeData,
                    };
                }
            );
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
    },
};
</script>
<style scoped lang="scss">
.deposit-list {
    &-title {
        font-size: 23px;
        margin-bottom: 8px;
    }

    &-body {
        padding-bottom: 20px;
    }

    &-text {
        font-size: 14px;
        margin-bottom: 16px;
    }

    &-items {
        &-text {
            font-size: 14px;
            margin-bottom: 8px;
        }
    }

    &-item {
        display: inline-block;
        width: 100%;
        vertical-align: middle;

        &-preview {
            width: 40px;
            min-width: 40px;
            height: 40px;
            margin-right: 12px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h5 {
            font-size: 14px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &-wrapper {
            cursor: pointer;
            padding: 12px;
            border: 1px solid $medium-grey;
            border-radius: $deposit-list-thumbnail-border-radius;
            background: $deposit-list-thumbnail-image-background;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include oldschool {
                position: relative;
                padding: 28px 12px;
            }
        }

        @include hoverable {
            &:hover {
                color: inherit;
            }
        }

        &-title {
            display: flex;
            .help-text {
                font-size: 12px;
                color: $disabled-text;
            }
        }

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-action {
            display: flex;
            align-items: center;
            .fee-amount {
                font-size: 12px;
                margin-right: 12px;
                padding: 2px 6px;
                border-radius: 2px;

                @include oldschool {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    margin: 0;
                }

                &.bg-grey {
                    background: $light-grey;
                }
                &.bg-green {
                    background: $deposit-list-free-background;
                }
            }
            .icon {
                width: 14px;
                height: 16px;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $deposit-list-icon-color;
                }
            }
        }

        &-highlighted {
            border-color: $deposit-list-item-highlighted-color;
            background-color: $deposit-list-item-highlighted-bg;

            div .icon svg {
                fill: $deposit-list-item-highlighted-color;
            }

            .fee-amount {
                background: $deposit-list-item-highlighted-color !important;
                @extend %body-normal-font-500;
            }
        }
    }
}
</style>
