<template>
    <div class="tabs" v-if="tabsList.length">
        <Tabs :tabs="tabsList" :active="currentTab" scrollable @select="setCurrentTab" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getter, auth } from '@agi.packages/platform';
import { Tabs } from '@agi.packages/core/components';
import { helper } from '@agi.packages/core';
import { routeName as routeNameMap } from '@/router/const';

export default {
    name: 'ProductNav',
    components: { Tabs },
    computed: {
        ...mapState({
            mobileSearchOpen: (state) => state.ui.mobileSearchOpen,
            headerMenuLinks(state) {
                const { isHeaderLinksEnabled, links } = state.platform.config.headerMenu || {};
                if (!isHeaderLinksEnabled) return [];
                return helper.processStrapiMenuLinks({
                    links,
                    isAuthenticated: this.isAuthenticated,
                    userStatus: this.currentUserStatus,
                    transformer: ({ pageId, icon, name, fullPath, path }) => ({
                        key: pageId,
                        iconId: icon,
                        textVisible: true,
                        iconClass: 'icon-size-small',
                        text: name,
                        fullPath,
                        path,
                    }),
                    router: this.$router,
                });
            },
        }),
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            currentUserStatus: getter.GET_CURRENT_USER_STATUS,
        }),
        isEventPageHeaderTabsEnabled() {
            return !!helper.getObjectField(this.brandPreference, 'isEventPageHeaderTabsEnabled', false);
        },
        tabsList() {
            const matchesList = [
                {
                    key: 'Upcoming',
                    iconId: 'icon-clock',
                    textVisible: true,
                    iconClass: 'icon-size-small',
                    text: this.$t('menus.upcoming'),
                    fullPath: this.$router.resolve({ name: routeNameMap.UPCOMING }).route.fullPath,
                },
                {
                    key: 'Popular',
                    iconId: 'icon-popular',
                    textVisible: true,
                    iconClass: 'icon-size-small',
                    text: this.$t('ui.popular.popularEvents'),
                    fullPath: this.$router.resolve({ name: routeNameMap.POPULAR }).route.fullPath,
                },
                {
                    key: 'Live',
                    iconId: 'icon-live',
                    textVisible: true,
                    iconClass: 'icon-size-small',
                    text: this.$t('menus.live'),
                    fullPath: this.$router.resolve({ name: routeNameMap.LIVE }).route.fullPath,
                },
                {
                    key: routeNameMap.BOOSTED_EVENTS,
                    iconId: 'icon-boosted',
                    textVisible: true,
                    iconClass: 'icon-size-small',
                    text: this.$t('ui.common.bestOdds'),
                    fullPath: this.$router.resolve({ name: routeNameMap.BOOSTED_EVENTS }).route.fullPath,
                },
            ];

            const { name } = this.$route;
            const matchesRouteNames = [routeNameMap.COUNTRY, routeNameMap.GROUP];
            const isMatchesList = matchesList.some(({ key }) => name === key) || matchesRouteNames.some((routeName) => routeName === name);
            const isEventPage = name === routeNameMap.EVENT;

            if (isMatchesList && this.isEventPageHeaderTabsEnabled) {
                return matchesList;
            } else if (isEventPage) {
                return [];
            } else {
                return this.headerMenuLinks;
            }
        },

        currentTab() {
            if (this.$route.name === routeNameMap.GENERIC_PAGE) {
                const { key } = this.tabsList.find((link) => link.path === this.$route.path) || {};
                return { key: key };
            }
            return { key: this.$route.name };
        },
    },
    methods: {
        trackTopNav(key) {
            this.$gtm.query({
                event: this.$gtm.makeValidEventName(`product_nav_${key}_click`),
                click_element: 'button',
            });
        },
        setCurrentTab(e) {
            this.$router.push({ path: e.fullPath });
            this.trackTopNav(e.key);
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    background: map-get($bg-neutral, 'bg-lighten-1');

    ::v-deep {
        .tabs-menu {
            background: transparent;
            padding: 0;
            border: 0;
            display: flex;

            .tabs-selector {
                background: transparent;
                color: map-get($bg-neutral, 'bg-lighten-2');
                border: none;
                flex-grow: 1;

                .tab-item .tab-text {
                    @extend %body-small-font-700;
                    text-transform: uppercase;
                }

                &.active {
                    color: $light-green;
                    border-bottom: 2px solid $light-green;
                    background: none;

                    .tab-item .svg-icon {
                        fill: $light-green;
                    }
                }

                .tab-item {
                    .svg-icon {
                        fill: map-get($bg-neutral, 'bg-lighten-2');
                    }
                }
            }
        }
    }
}
</style>
