<template>
    <div class="verify-button">
        <div v-if="showMessage" class="notify" :class="messageClass">
            <renderer :input="message" />
        </div>
        <input
            v-if="isButtonVisible"
            class="verify-button-cta button button-primary button-full"
            type="submit"
            :value="buttonText"
            :disabled="isButtonDisabled"
            @click="verify"
        />
        <div v-if="isInReview" class="text text-status"><renderer :input="$t('ui.verify.currentStatus')" /></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { auth, action, getter as platformGetter, hasUserVerificationStatus, userVerificationStatuses } from '@agi.packages/platform';
import { getter as coreGetter } from '@agi.packages/core';
import { action as generalAction } from '@/store/store';
import { routeName } from '@/router/const';

const notification = {
    type: 'verify',
    data: {
        id: 'verifySuccess',
        trigger: true,
    },
};

export default {
    name: 'VerifyButton',
    props: ['data'],
    data() {
        return {
            isRequestFailed: false,
            isRequesting: false,
        };
    },
    computed: {
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            areLoading: coreGetter.ARE_LOADING,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            preference: platformGetter.GET_PREFERENCE,
        }),
        isProcessing() {
            return this.isRequesting || this.areLoading([action.PAWAPASS_VERIFICATION, action.LOAD_AGI_SETTINGS]);
        },
        buttonText() {
            if (!this.isAuthenticated) {
                return this.$t('ui.verify.login');
            }
            if (this.isProcessing) {
                return this.$t('ui.verify.loading');
            }
            if (this.isStarted) {
                return this.$t('ui.verify.complete');
            }
            if (this.isCompleted) {
                return this.$t('ui.verify.processing');
            }
            if (this.isInReview) {
                return this.$t('ui.verify.checkStatus');
            }
            const { buttonText } = this.data.content;
            return buttonText || this.$t('ui.verify.cta');
        },
        isButtonVisible() {
            return !(this.isCompleted && !this.isRedirectNeeded);
        },
        isButtonDisabled() {
            return this.isProcessing || this.isCompleted;
        },
        isStarted() {
            return hasUserVerificationStatus(this.preference, [userVerificationStatuses.VERIFICATION_STATUS_STARTED]);
        },
        isCompleted() {
            return hasUserVerificationStatus(this.preference, [userVerificationStatuses.VERIFICATION_STATUS_COMPLETED]);
        },
        isCanceled() {
            return hasUserVerificationStatus(this.preference, [
                userVerificationStatuses.VERIFICATION_STATUS_DECLINED,
                userVerificationStatuses.VERIFICATION_STATUS_EXPIRED,
            ]);
        },
        isInReview() {
            return hasUserVerificationStatus(this.preference, [userVerificationStatuses.VERIFICATION_STATUS_INREVIEW]);
        },
        isRedirectNeeded() {
            const { returnPath } = this.data.content;
            return returnPath && returnPath !== this.$route.path;
        },
        message() {
            if (this.isCompleted) {
                return this.$t('ui.verify.notification.success');
            }
            if (this.isRequestFailed) {
                return this.$t('ui.verify.notification.failed');
            }
            if (this.isCanceled) {
                return this.$t('ui.verify.notification.cancelled');
            }
            if (this.isInReview) {
                return this.$t('ui.verify.notification.review');
            }
            return '';
        },
        messageClass() {
            if (this.isInReview) return 'warning';
            return this.isRequestFailed || this.isCanceled ? 'error' : 'success';
        },
        showMessage() {
            return (this.isCompleted && !this.isButtonVisible) || this.isRequestFailed || this.isInReview || this.isCanceled;
        },
    },
    methods: {
        verify() {
            if (!this.isAuthenticated) {
                this.$router.push({ name: routeName.LOGIN, query: { returnPath: this.$route.fullPath } });
                return;
            }
            if (this.isInReview && !this.isLoading(action.LOAD_AGI_SETTINGS)) {
                this.$store.dispatch(action.LOAD_AGI_SETTINGS, { noUserTracking: true });
                return;
            }
            if (this.isLoading(action.PAWAPASS_VERIFICATION)) return;
            const { requirements, reason } = this.data?.content || {};
            this.isRequesting = true;
            this.$store
                .dispatch(action.PAWAPASS_VERIFICATION, { requirements, reason })
                .then((url) => {
                    if (url) {
                        location.href = url;
                        return;
                    }
                    this.isRequestFailed = true;
                })
                .catch(() => {
                    this.isRequestFailed = true;
                    this.isRequesting = false;
                });
        },
    },
    mounted() {
        const { returnPath } = this.data.content;
        if (this.isCompleted && this.isRedirectNeeded) {
            this.$router.push({ path: returnPath }).then(() => {
                this.$store.dispatch(generalAction.NOTIFY, {
                    ...notification,
                    data: { ...notification.data, message: this.$t('ui.verify.notification.success') },
                });
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.verify-button {
    margin: 20px 0;

    .notify {
        margin: 0 0 16px 0;
    }

    .text-status {
        margin-top: 16px;
        text-align: center;
        width: 100%;
    }
}
</style>
