export const toolbarIconType = {
    LIST: 'list',
    TILE: 'tile',
};

export const casinoGameMode = {
    demo: 'DEMO',
    real: 'REAL',
};

export const casinoProviderType = {
    WAZDAN: 'wazdan',
    NATIVE: 'betpawa',
    SMARTSOFT: 'smartsoft',
    SPRIBE: 'spribe',
};

export const sortType = {
    POP: 'popular',
    FAV: 'favourites',
    ASC: 'ascending',
    DESC: 'descending',
};

const defaultGameCategory = {
    ALL: 'all',
    FAVOURITES: 'favourites',
};

export const gameCategory = {
    ...defaultGameCategory,
};

export const NATIVE_POPULAR_GAMES = ['wol', 'fm', 'lr', 'job', 'ta'];

export const wazdanTag = {
    POPULAR: 'popular',
};

export const CASINO_GAMES_PRIORITY = [casinoProviderType.NATIVE, casinoProviderType.WAZDAN];
