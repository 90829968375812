import Vue from 'vue';
import { cmsActions } from '@/js/utils/cms/cms-const';
import { modalNames, showModal } from '@/js/utils/cms/cms-modals';
import router from '@/router';
import { deviceType } from '@agi.packages/core';
import { routeName } from '@/router/const';

const handler = (actionName) => {
    switch ((actionName || '').toLowerCase()) {
        case cmsActions.SELF_EXCLUSION:
            Vue.$gtm.query({ event: 'want_to_be_self_excluded' });
            if (deviceType.isPresto()) {
                router.push({ name: routeName.PRESTO_SELF_EXCLUSION });
            } else {
                showModal(modalNames.SELF_EXCLUSION);
            }
            break;
        case cmsActions.VERIFICATION_CLICK_TO_DIAL_CODE:
            Vue.$gtm.query({ event: 'verification_click_to_dial' });
            Vue.$modal.hide(modalNames.VERIFICATION_MODAL);
            break;
        default:
            break;
    }
};

export default handler;
