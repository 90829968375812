<template>
    <div>
        <SimpleButton
            :color="leftOption.color"
            @click.native="$emit('leftResolvePrompt', leftOption.returnValue)"
            :disabled="disabled || leftOption.disabled"
            :class="leftOption.buttonCssClass"
            :fullSize="leftOption.fullSize"
        >
            <template v-if="leftOption.text" v-slot:center>
                <span :class="leftOption.cssClass">{{ leftOption.text }}</span>
            </template>
            <template v-if="leftOption.icon" v-slot:right>
                <SvgIcon :class="leftOption.cssClass" :iconId="leftOption.icon" />
            </template>
        </SimpleButton>
        <SimpleButton
            :color="rightOption.color"
            class="refuseClass"
            @click.native="$emit('rightResolvePrompt', rightOption.returnValue)"
            :disabled="disabled || rightOption.disabled"
            :class="rightOption.buttonCssClass"
            :fullSize="rightOption.fullSize"
            :loading="rightOption.isLoading"
        >
            <template v-if="rightOption.isLoading" v-slot:loader>
                <div class="loader"></div>
            </template>
            <template v-if="rightOption.text" v-slot:center>
                <span :class="rightOption.cssClass">{{ rightOption.text }}</span>
            </template>
            <template v-if="rightOption.icon" v-slot:right>
                <SvgIcon :iconId="rightOption.icon" :class="rightOption.iconCssClass" />
            </template>
        </SimpleButton>
    </div>
</template>

<script>
import SimpleButton from './SimpleButton.vue';

const defaultProps = {
    text: '',
    icon: null,
    cssClass: null,
    iconCssClass: null,
    buttonCssClass: null,
    fullSize: null,
    color: 'accent',
};
export default {
    name: 'PromptAction',
    components: { SimpleButton },
    props: {
        left: {
            required: true,
            type: Object,
        },
        right: {
            required: true,
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        leftOption() {
            const left = this.left;
            return { returnValue: true, ...defaultProps, ...left };
        },
        rightOption() {
            const right = this.right;
            return { returnValue: false, ...defaultProps, ...right };
        },
    },
};
</script>

<style scoped>
.refuseClass {
    margin-left: 8px;
}

.loader {
    background: transparent url('./../../assets/default-spinner.gif') no-repeat center center;
    background-size: contain;
    width: 100%;
    height: 100%;
    display: inline-block;
}
</style>
