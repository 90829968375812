import jackpotSmall from '@/assets/images/backgrounds/jp_bg_small.jpg';
import jackpotMedium from '@/assets/images/backgrounds/jp_bg_medium.jpg';
import jackpotLarge from '@/assets/images/backgrounds/jp_bg_large.jpg';

import noFavouriteGame from '@/assets/images/icons/nofavouritegame.png';
import { NetworkError, UnexpectedError } from '@agi.packages/core/components';

const singleJackpotSmall = require(`@/assets/images/backgrounds/single_jp_bg_small.jpg`);
const singleJackpotMedium = require(`@/assets/images/backgrounds/single_jp_bg_medium.jpg`);
const singleJackpotLarge = require(`@/assets/images/backgrounds/single_jp_bg_large.jpg`);

const multiJackpotSmall = require(`@/assets/images/backgrounds/multi_jp_bg_small.jpg`);
const multiJackpotMedium = require(`@/assets/images/backgrounds/multi_jp_bg_medium.jpg`);
const multiJackpotLarge = require(`@/assets/images/backgrounds/multi_jp_bg_large.jpg`);

const logoDark = require('@assets/images/logo/dark.png');
const logoWhiteGreen = require('@assets/images/logo/white-green.png');
const logoBlackGreen = require('@assets/images/logo/black-green.png');

export const LOGO_IMAGES = {
    logoDark,
    logoWhiteGreen,
    logoBlackGreen,
};

const errorNoActiveMarkets = require('@/assets/svg/no-active-markets.svg');
const errorNoMatchesMarket = require('@/assets/svg/no-matches-market.svg');
const error404 = require('@/assets/svg/error-404.svg');
const errorNoActivity = require('@/assets/svg/no-activity.svg');
const errorApp = require('@/assets/svg/app-error.svg');
const errorBadRequest = require('@/assets/svg/bad-request.svg');

export const ERROR_IMAGES = {
    errorNetwork: {
        component: NetworkError,
        className: 'error-image',
    },
    errorUnexpected: {
        component: UnexpectedError,
        className: 'error-image',
    },
    errorNoActiveMarkets,
    errorNoMatchesMarket,
    error404,
    errorApp,
    errorNoActivity,
    errorBadRequest,
};

const casino = require('@/assets/images/seo/casino.jpg');
const games = require('@/assets/images/seo/games.jpg');
const seoSocial = require('@/assets/images/seo/seo_social.jpg');
const virtual = require('@/assets/images/seo/virtual.jpg');
const jackpot = require('@/assets/images/seo/jackpot.jpg');
const jackpotDetails = jackpot;
const withdraw = require('@/assets/images/seo/withdraw.jpg');

export const SEO_IMAGES = {
    casino,
    games,
    jackpot,
    jackpotDetails,
    seoSocial,
    virtual,
    withdraw,
};

export const linkTarget = {
    EXTERNAL: '_blank',
    INTERNAL: '_self',
};

export const JACKPOT_BACKGROUNDS = [
    { url: jackpotSmall, width: 480 },
    { url: jackpotMedium, width: 767 },
    { url: jackpotLarge, width: 990 },
];

export const SINGLE_JACKPOT_BG = [
    { url: singleJackpotSmall, width: 480 },
    { url: singleJackpotMedium, width: 767 },
    { url: singleJackpotLarge, width: 990 },
];

export const MULTI_JACKPOT_BG = [
    { url: multiJackpotSmall, width: 480 },
    { url: multiJackpotMedium, width: 767 },
    { url: multiJackpotLarge, width: 990 },
];

export const GAME_BACKGROUNDS = [{ url: noFavouriteGame, width: 120 }];
