<template>
    <div class="lmenu-main">
        <LeftMenuCategories>
            <span class="close" @click="hideLeftMenu">
                <SvgIcon icon-id="icon-close" />
            </span>
        </LeftMenuCategories>

        <MenuItem
            v-for="(menuItem, index) in leftMenu"
            :key="menuItem.pageId + '-left-menu-' + index"
            :selected="hasStrapiMenu ? isCurrentMenuItem(menuItem) && !menuItem.disableHighlight : currentRoute.name === menuItem.pageId"
            :link="{
                path: menuItem.url,
                query: menuItem.query,
            }"
            :item="{
                badge: hasStrapiMenu ? menuItem.badge : $t(menuItem.badge),
                badgeType: hasStrapiMenu ? menuItem.badgeType : 'new-messages',
                badgeColor: hasStrapiMenu ? menuItem.badgeColor : '',
                icon: hasStrapiMenu ? menuItem.icon : menuIcons[menuItem.pageId.toLowerCase()],
                text: hasStrapiMenu ? menuItem.name : $t(menuItem.key, { casinoName }),
                control: {
                    text: menuItem.pageId === 'Live' && liveEventsCount,
                },
            }"
            @click.native="currentRoute.name === menuItem.pageId ? hideLeftMenu() : trackMainNav(menuItem.pageId)"
        />

        <ExpansionPanel
            v-if="!!tournaments && tournaments.length"
            data-test-id="menu-tournaments"
            :title="$t('ui.lMenu.tournaments')"
            :control="!isPresto"
            :expanded="true"
        >
            <MenuItem
                class="sub-menu"
                v-for="(tournament, index) in tournaments"
                :key="`tournament-${tournament.id}-${index}`"
                :selected="currentRoute.id === tournament.id"
                :link="{ name: routeGroupName, params: { id: tournament.id } }"
                :item="{
                    text: tournament.name,
                    control: {
                        text: tournament.eventCount,
                        iconDisabled: true,
                    },
                }"
                :data-test-id="`tournament-${tournament.id}`"
                @click.native="trackMainNav(tournament.name, 'tournaments_nav')"
            >
                <template slot="icon">
                    <ImageIcon
                        v-if="tournament.region"
                        class="icon-status"
                        :src="tournament.url || (tournament.region && `img/flags/${tournament.regionSlug.toLowerCase()}.png`)"
                        :altSrc="tournament.regionSlug && `img/flags/${tournament.parentRegionSlug}.png`"
                    />
                </template>
            </MenuItem>
        </ExpansionPanel>

        <CountryMenus />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { helper, deviceType } from '@agi.packages/core';
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { MenuItem, ExpansionPanel } from '@agi.packages/core/components';
import { getter as translationsGetter } from '@/store/modules/translations';

import { action, getter as storeGetter } from '@/store/store';
import { CountryMenus } from '@agi.packages/sport/components';
import { routeName } from '@/router/const';

const LeftMenuCategories = () => import('./LeftMenuCategories');

const MENU_ICONS = {
    homepage: 'icon-home',
    live: 'icon-live',
    casino: 'icon-casino',
    popular: 'icon-popular',
    jackpotpage: 'icon-jackpot',
    upcoming: 'icon-clock',
    'virtual sports': 'icon-virtual-sport',
    pawa6: 'icon-pawa6',
};

const CASINO_PAGE_ID = 'Casino';

export default {
    name: 'LeftMenu',
    components: { LeftMenuCategories, MenuItem, ExpansionPanel, CountryMenus },
    data() {
        return {
            routeGroupName: routeName.GROUP,
            menuIcons: MENU_ICONS,
            currentRoute: {
                name: '',
                id: null,
                category: null,
            },
            isPresto: deviceType.isPresto(),
            isFeaturePresto: deviceType.isPresto() && !deviceType.isSmartphonePresto(),
        };
    },
    computed: {
        ...mapState({
            strapiMenuLinks: (state) => helper.getObjectField(state.platform.config.leftMenu, 'links', []),
            tournaments: 'currentTournaments',
            liveEventsCount: (state) => state.sport.sports.liveEventsCount,
            setting: (state) => state.platform.settings,
            showOther: (state) => state.ui.showLeftMenuOther,
        }),
        ...mapGetters({
            casinoName: translationsGetter.CASINO_NAME,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            quickMainMenu: storeGetter.GET_QUICK_MAIN_MENU,
            currentUserStatus: platformGetter.GET_CURRENT_USER_STATUS,
        }),
        hasStrapiMenu() {
            return !!this.strapiMenuLinks.length;
        },
        leftMenu() {
            if (this.hasStrapiMenu) {
                return helper.processStrapiMenuLinks({
                    links: this.strapiMenuLinks,
                    userStatus: this.currentUserStatus,
                    isAuthenticated: this.isAuthenticated,
                    transformer: (link) => ({ ...link, url: link.path }),
                    router: this.$router,
                });
            }
            return this.quickMainMenu.filter((menuItem) => menuItem.isLeftMenuLink && this.shouldDisplay(menuItem));
        },
    },
    methods: {
        $getObjectField: helper.getObjectField,
        getRegionCode: function (address) {
            return parseInt(helper.getRegionCode(address));
        },
        shouldDisplay: function (menuItem) {
            const isEnabled = !menuItem.enabler || helper.pathChecker(menuItem.enabler, this.setting);
            return isEnabled && this.isCasinoItemShown(menuItem);
        },
        isCasinoItem(menuItem) {
            return menuItem.pageId === CASINO_PAGE_ID;
        },
        isCasinoItemShown(menuItem) {
            if (!this.isCasinoItem(menuItem)) {
                return true;
            }
            return !this.isPresto;
        },
        hideLeftMenu() {
            this.$store.dispatch(action.SET_SIDEBAR_STATE, false);
        },
        trackMainNav(element, event = 'main_nav') {
            if (element) {
                this.$gtm.query({
                    event,
                    click_text: element,
                });
            }
        },
        isCurrentMenuItem(menuItem) {
            const isHomePage = this.$route.path === '/';
            if (isHomePage) {
                return menuItem.url === this.$route.path;
            }
            const page = this.$route.path
                .split('/')
                .filter((segment) => !!segment)
                .pop();
            return menuItem.links.includes(page);
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.$data.currentRoute = { name: to.name, id: to.query.id, category: to.query.category };
            },
        },
    },
};
</script>

<style scoped lang="scss">
.lmenu-main {
    position: relative;
    overflow: auto;
}

.menu-link {
    display: block;
    padding: 12px;
    margin: 0;
}

::v-deep .sub-menu {
    & > .link,
    &.selected > .link {
        background: #f5f5f0;
    }
}

.close {
    cursor: pointer;
    padding-left: 10px;

    svg {
        padding: 5px;
        width: 22px;
        height: 22px;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.4s, opacity 0.4s;
    max-height: 500px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0;
}
</style>
