export const durationUnitsType = {
    WEEK: 'week',
    DAY: 'day',
    HOUR: 'hour',
    MINUTE: 'minute',
    SECOND: 'second',
};

export const durationUnits = Object.values(durationUnitsType);

export const durationUnitsMapper = {
    [durationUnitsType.DAY]: {
        [durationUnitsType.WEEK]: 7,
    },
    [durationUnitsType.HOUR]: {
        [durationUnitsType.WEEK]: 24 * 7,
        [durationUnitsType.DAY]: 24,
    },
    [durationUnitsType.MINUTE]: {
        [durationUnitsType.WEEK]: 60 * (24 * 7),
        [durationUnitsType.DAY]: 60 * 24,
        [durationUnitsType.HOUR]: 60,
    },
    [durationUnitsType.SECOND]: {
        [durationUnitsType.WEEK]: 3600 * (24 * 7),
        [durationUnitsType.DAY]: 3600 * 24,
        [durationUnitsType.HOUR]: 3600,
        [durationUnitsType.MINUTE]: 60,
    },
};

const getUnsupportedUnitError = (unit) => new Error(`The unit of duration "${unit}" is not supported in the durationUnitsMapper`);

/**
 *  Units can be cut off at the lowest or biggest value
 *  @param {object} options
 *  @param {string} [options.biggestUnit]
 *  @param {string} [options.lowestUnit]
 */
export const getDurationUnitsCutoffBy = ({ biggestUnit, lowestUnit }) => {
    if (!durationUnits.includes(biggestUnit)) {
        biggestUnit = durationUnits[0];
    }
    if (!durationUnits.includes(lowestUnit)) {
        lowestUnit = durationUnits[durationUnits.length - 1];
    }
    return durationUnits.slice(durationUnits.indexOf(biggestUnit), durationUnits.indexOf(lowestUnit) + 1);
};
/**
 *  Converting one unit of duration to another unit of duration
 *  , ex: 1 day -> 24 hours
 *  @param {number} value
 *  @param {string} from - type of unit, ex: 'week'
 *  @param {string} to - type of unit, ex: 'day'
 *  @returns number
 */
export const convert = (value, from, to) => {
    const lowerUnit = durationUnitsMapper[to] && durationUnitsMapper[to][from];
    if (from === to) {
        return value;
    }
    if (!durationUnitsMapper[from] || (!durationUnitsMapper[from][to] && !lowerUnit)) {
        throw getUnsupportedUnitError(!durationUnitsMapper[from] ? from : to);
    }
    if (lowerUnit) {
        return value * lowerUnit;
    }
    return value / durationUnitsMapper[from][to];
};

/**
 *  Convert to duration units
 *  @param {number} value - time
 *  @param {string} from - type of unit, ex: 'week'
 *  @param {array} units - list of units, ex: ['week', 'day', 'hour', 'minute']
 *  @param {function} round - round method. By default: Math.floor.
 *  @returns {{ value: number, unit: string }[]}
 */
export const convertToDurationUnits = (value, from, units, round = Math.floor) => {
    const results = [];
    if (!durationUnitsMapper[from]) {
        throw getUnsupportedUnitError(from);
    }
    let previousUnitsCutoffValue = value;
    for (const unit of units) {
        const result = previousUnitsCutoffValue;
        previousUnitsCutoffValue %= durationUnitsMapper[from][unit];
        results.push({ value: round(convert(result, from, unit)), unit });
    }
    return results;
};
