import Vue from 'vue';
import { getter as platformGetter } from '@agi.packages/platform';
import { getLocalTypes } from '@/store/utils';

export const getter = {
    GET_SELECTED_LANGUAGE: 'translations/getSelectedLanguage',
    LANGUAGE: 'translations/getLanguage',
    LANGUAGE_LIST: 'translations/getLanguageList',
    PIN_OR_PASSWORD: 'translations/getPinOrPasswordTranslations',
    CASINO_NAME: 'translations/getCasinoName',
    TRANSLATIONS: 'translations/getTranslations',
};
export const action = {
    SWITCH_LANGUAGE: 'translations/switchLanguage',
    SET_DETECTED_LANGUAGE: 'translations/setDetectedLanguage',
};
export const mutation = {
    SET_LOCALES: 'translations/setLocales',
    SET_LANGUAGE: 'translations/setLanguage',
};

const state = {
    language: null,
    persist: null,
    locales: {},
    languageList: [],
};

const _getter = getLocalTypes(getter);

const getters = {
    [_getter.PIN_OR_PASSWORD]: (state, getters, rootState, rootGetters) => {
        const { pin } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        return pin ? 'pin' : 'password';
    },
    [_getter.CASINO_NAME]: (state, getters, rootState, rootGetters) => {
        const { casinoType } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
        return Vue.$t(`ui.casinoLobby.${casinoType.path}`);
    },
    [_getter.LANGUAGE]: ({ language, locales, persist, languageList }) => {
        return { language, locales, persist, languageList };
    },
    [_getter.GET_SELECTED_LANGUAGE]: ({ language }) => {
        return language;
    },
    [_getter.LANGUAGE_LIST]: ({ locales, language, languageList }) => {
        const { otherLanguages } = locales[language] || {};
        if (!otherLanguages) return [];
        return languageList.map((key) => {
            const { languageSwitcher } = otherLanguages[key];
            return {
                key,
                text: languageSwitcher || key,
                isSelected: key === language,
            };
        });
    },
};

const actions = {
    switchLanguage({ commit }, targetLanguage) {
        commit('setLanguage', targetLanguage);
        Vue.nextTick(() => {
            window.location.reload();
        });
    },
    setDetectedLanguage({ commit, getters }) {
        const { locales, persist, language } = getters[_getter.LANGUAGE];
        const deviceLang = navigator.language.split('-')[0];
        const queryParams = new URLSearchParams(window.location.search.substring(1));
        const targetLanguage = queryParams.get('language') || deviceLang;
        const [defaultLanguage] = Object.entries(locales)[0];
        const detectedLanguage = Object.keys(locales).includes(targetLanguage) ? targetLanguage : defaultLanguage;
        const hasInvalidLanguage = persist && !Object.keys(locales).includes(language);
        if (hasInvalidLanguage || !persist || !language) {
            commit('setLanguage', detectedLanguage);
        }
    },
};

const mutations = {
    setLanguage: (state, value) => {
        state.language = value;
        state.persist = true;
    },
    setLocales: (state, { locales, languageList }) => {
        state.locales = locales;
        state.languageList = languageList || Object.keys(locales);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
