<template>
    <div class="betline-list-button" :class="{ 'betline-list-button-hidden': isHidden }">
        <router-link :to="{ name: 'Event', params: { id: event.id }, hash: isPresto ? '#presto' : '' }" class="betline-count button">
            +{{ isUnavailable && !event.totalMarketCount ? '0' : event.totalMarketCount }}
        </router-link>
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';

export default {
    name: 'BetlineCountButton',
    props: {
        event: {
            type: Object,
            required: true,
        },
        isHidden: {
            type: Boolean,
            default: false,
        },
        isUnavailable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
};
</script>

<style lang="scss" scoped>
.betline-list-button {
    padding-left: 8px;
    margin-bottom: 5px;
    display: flex;

    @include no_flex_box_supported {
        display: table-cell;
        vertical-align: top;
        padding-left: 4px;
    }

    @include oldschool {
        padding-left: 4px;
    }

    &-hidden {
        visibility: hidden;
        pointer-events: none;
        height: 0px;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-top: 0;
        overflow: hidden;
    }
}

.betline-count {
    font-weight: bold;
    background: $betline-button-background;
    border-radius: $border-radius;
    border: $betline-button-border;
    color: $betline-button-color;
    white-space: nowrap;
    padding: 11px 6px;
    min-width: 46px;
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: $betline-button-background-hover;
        color: $white-bg;
        border: 1px solid $betline-button-background-hover;
    }

    @include maxbasic {
        padding: 18px 6px;
    }

    @include oldoldschool {
        padding: 18px 1px;
        min-width: 34px;
    }

    @include only_mini {
        padding: 11px 6px;

        @include maxbasic {
            padding: 17px 6px;
        }
    }
}
</style>
