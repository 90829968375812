<template>
    <div class="loading-page" v-if="showSpinner">
        <div>
            <SvgIcon iconId="logo-black" iconFallback="logo-black" class="logo-black" />
            <div class="loading-page-text" v-if="isPresto">Loading...</div>
            <div v-else class="loading-page-progress">
                <div class="loading-page-progress-bar"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { deviceType } from '@agi.packages/core';

export default {
    name: 'LoadingPage',
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    props: {
        listen: {
            type: [Array, String],
            default: '',
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('core', ['areLoading', 'isLoading']),
        showSpinner() {
            let isListen = false;
            if (this.listen) {
                isListen = Array.isArray(this.listen) ? this.areLoading(this.listen) : this.isLoading(this.listen);
            }
            return this.visible || isListen;
        },
    },
};
</script>

<style lang="scss">
@keyframes progress_bar {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 5%;
    }
}

.loading-page {
    display: flex;
    align-items: center;
    background: $body-background-color;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 99999;

    &-text {
        font-size: 18px;
        margin-top: 24px;
        text-align: center;
    }

    .logo-black {
        width: 208px;

        @include oldschool {
            width: 154px;
        }
    }

    &-progress {
        height: 4px;
        overflow: hidden;
        background-color: $light-grey;
        margin-top: 40px;

        &-bar {
            position: relative;
            width: 100%;
            height: 100%;
            &:after {
                content: '';
                position: absolute;
                height: 100%;
                background-color: $green;
                animation: progress_bar 1s infinite cubic-bezier(0.71, 0, 0.28, 1);
            }
        }
    }
}
</style>
