<template>
    <div v-if="error" v-html="error" class="page-error"></div>
    <div v-else-if="ticket">
        <div class="header">
            <div @click="onBack" class="back">
                <SvgIcon class="icon" icon-id="arrow_left" />
            </div>
            <div class="jackpot-title">
                <p class="id" data-test-id="jackpotID">{{ $t('ui.myBets.id') }}: #{{ ticket.id }}</p>
                <p class="subtitle" data-test-id="jackpotName">{{ ticket.pool.name }}</p>
            </div>
        </div>

        <div class="summary">
            <div class="summary-line" v-if="ticket.freeTicketsUsed > 0">
                <span class="label">{{ $t('ui.jackpot.freeTickets') }}:</span>
                <span class="value" data-test-id="freeTickets">{{ ticket.freeTicketsUsed }}</span>
            </div>
            <div class="summary-line">
                <span class="label">{{ $t('ui.jackpot.totalTickets') }}:</span>
                <span class="value" data-test-id="totalTickets">{{ ticket.totalTickets }}</span>
            </div>
            <div class="summary-line">
                <span class="label">{{ $t('ui.jackpot.totalPrice') }}:</span>
                <Currency class="value" :amount="totalPrice" :format="currency" data-test-id="totalPrice" />
            </div>
            <div class="summary-line">
                <span class="label">{{ $t('ui.jackpot.closingTime') }}:</span>
                <span class="datetime">{{ formatDateTime(ticket.pool.closeTime, { ...dateOptions, isUtc: true }) }}</span>
            </div>
            <div v-if="!ticket.isWinner" class="summary-line bold">
                <span class="label"> {{ $t('ui.myBets.payout') }}:</span>
                <Currency class="value" :amount="ticket.pool.maxPrize" :format="currency" data-test-id="maxWin" />
                {{ taxEnabled ? '*' : '' }}
            </div>
            <template v-else-if="!ticket.pool.tiebreakers">
                <template v-if="ticket.hits">
                    <div v-for="(hit, index) in ticket.hits" :key="index" class="summary-line">
                        <span class="label">
                            {{ hit.place === 1 ? $t('ui.myBets.jackpot') : $t('ui.myBets.hit') + ' ' + hit.row }}
                            ({{ hit.hits }}
                            <renderer :input="$t('ui.jackpotTicket.ticketPlural', { pluralSuffix: hit.hits > 1 ? 's' : '' })" />)
                        </span>
                        <Currency
                            class="value"
                            :amount="hit.wonPerTicket * hit.hits"
                            :format="currency"
                            data-test-class="wonPerTicketHits"
                        />{{ taxEnabled ? '*' : '' }}
                    </div>
                </template>
                <div class="summary-line bold">
                    <span class="label">{{ $t('ui.myBets.won') }}:</span>
                    <Currency
                        :contrast="false"
                        class="value winning-result"
                        :amount="ticket.payoutDetails.prize"
                        :format="currency"
                        data-test-id="totalPrize"
                    />
                </div>
                <div class="summary-line" v-if="ticket.payoutDetails.tax > 0">
                    <span class="label">{{ $t('ui.jackpotTicket.wht') }}:</span>
                    <Currency class="value" :amount="-ticket.payoutDetails.tax" :format="currency" data-test-id="totalTax" />
                </div>
                <div class="summary-line bold" v-if="ticket.payoutDetails.payout > 0">
                    <span class="label">{{ $t('ui.myBets.return') }}:</span>
                    <Currency
                        :contrast="false"
                        class="value winning-result"
                        :amount="ticket.payoutDetails.payout"
                        :format="currency"
                        data-test-id="totalPayout"
                    >
                        {{ $t('ui.myBets.won').toUpperCase() }}
                    </Currency>
                </div>
            </template>
            <template v-else>
                <div class="summary-line bold">
                    <span class="label" v-bind:class="{ 'winning-result': tieWinner && isSoloWinner }">
                        {{ $t('ui.myBets.payout') }}:
                    </span>
                    <Currency
                        :contrast="false"
                        v-bind:class="{ 'winning-result': tieWinner && isSoloWinner }"
                        class="value"
                        :amount="totalTiebreakerPayout"
                        :format="currency"
                    />
                    {{ taxEnabled ? '*' : '' }}
                </div>
                <div v-if="tieWinner && !isSoloWinner" class="summary-line bold">
                    <span class="label winning-result">{{ $t('ui.jackpotTicket.tiebreakerPayout') }}:</span>
                    <Currency class="value winning-result" :amount="ticket.pool.tiebreakers.statistics.winners.payout" :format="currency" />
                </div>
                <div v-if="tieWinner === false" class="summary-line bold">
                    <span class="label winning-result">{{ $t('ui.jackpotTicket.runnerupPayout') }}:</span>
                    <Currency class="value winning-result" :amount="ticket.pool.tiebreakers.statistics.losers.payout" :format="currency" />
                </div>
                <div v-if="ticket.payoutDetails.tax > 0">
                    <div class="summary-line">
                        <span class="label">{{ $t('ui.jackpotTicket.wht') }}:</span>
                        <Currency class="value" :amount="-ticket.payoutDetails.tax" :format="currency" />
                    </div>
                    <div class="summary-line bold" v-if="ticket.payoutDetails.payout > 0">
                        <span class="label winning-result">{{ $t('ui.myBets.return') }}:</span>
                        <Currency
                            :contrast="false"
                            class="value winning-result"
                            :amount="ticket.payoutDetails.payout"
                            :format="currency"
                            data-test-id="totalPayout"
                        />
                    </div>
                </div>
            </template>
            <div v-if="taxEnabled" class="summary-line">
                <renderer class="message" :input="$t('ui.jackpot.taxEnabledMessage', { taxAmount })" />
            </div>
        </div>

        <template v-if="ticket.eventRows.length">
            <div class="event" v-for="row in ticket.eventRows" :key="row.event.id">
                <div class="event-line event-header">
                    <Modal
                        v-if="isCancelledResult(row)"
                        class="cancellation-info"
                        :name="getCancellationInfoModalName(row.event.id)"
                        width="80%"
                        :avoidCollisionWithFooter="true"
                        @close="$modal.hide(getCancellationInfoModalName(row.event.id))"
                    >
                        <renderer :input="$t('ui.jackpotTicket.cancellationReason')" />
                    </Modal>

                    <div class="label">
                        <div class="label-content">
                            <div class="label-content-text" :class="{ strikethrough: isCancelledResult(row) }">
                                {{ formatTime(row.event.startDate, { isUtc: true }) }}
                                <span class="date">{{ formatDate(row.event.startDate, { isUtc: true }) }}</span>
                            </div>
                            <div v-if="isCancelledResult(row)" class="cancellation-badge">
                                <div class="cancellation-badge-label bold text">{{ $t('ui.jackpot.cancelled') }}</div>
                                <div class="cancellation-badge-info">
                                    <SvgIcon
                                        @click.native="$modal.show(getCancellationInfoModalName(row.event.id))"
                                        class="icon-info icon-size-medium"
                                        iconId="icon-info"
                                        verticalAlign="text-top"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pick" :class="{ strikethrough: isCancelledResult(row) }">
                        {{ $t('ui.jackpotTicket.pick') }}: {{ row.pick }}
                        <badge v-if="isCancelledResult(row)" type="cancelled" mode="circle" iconName="icon-close" />
                        <badge v-else-if="isMissingResult(row)" type="pending-dark" mode="circle" />
                        <badge v-else :type="row.hasWon ? 'win' : 'lose'" mode="circle" />
                    </div>
                </div>
                <div class="event-line">
                    <div class="label">
                        {{ row.name }}
                        <div class="league">{{ row.event.competition }}</div>
                    </div>
                    <span v-if="isGameScoreVisible(row)" class="value result">
                        {{ row.result.scores.scoreHome }} - {{ row.result.scores.scoreAway }}
                    </span>
                </div>
            </div>
        </template>

        <template v-else-if="ticket.jackpotRows.length">
            <div v-for="(row, rowIndex) in ticket.jackpotRows" :key="rowIndex" class="event">
                <div class="event-line event-header">
                    <div class="jackpot-name">{{ row.name }}</div>
                    <div class="value">
                        <badge v-if="isCancelledResult(row)" type="cancelled" mode="circle" iconName="icon-close" />
                        <badge v-else-if="isMissingResult(row)" type="pending-dark" mode="circle" />
                        <badge v-else :type="row.hasWon ? 'win' : 'lose'" mode="circle" />
                    </div>
                </div>
                <div class="event-line">
                    <div class="pick">{{ $t('ui.jackpotTicket.pick') }}: {{ row.pick }}</div>
                    <span class="value bold" v-if="row.result && row.result.cancelled">
                        {{ $t('ui.myBets.cancelled').toUpperCase() }}
                    </span>
                </div>
            </div>
        </template>

        <template v-if="ticket.pool.tiebreakers">
            <div class="summary">
                <span class="">{{ $t('ui.jackpot.tiebreakers.title') }}</span>
            </div>
            <div
                class="event"
                v-for="(tiebreaker, index) in ticket.pool.tiebreakers.tiebreakers"
                :key="`{${index}-tiebreaker-${tiebreaker.id}}`"
            >
                <div class="event-line event-header">
                    <div class="label question">
                        {{ tiebreaker.question }}
                    </div>
                    <div class="pick column">
                        <div>
                            {{ $t('ui.jackpotTicket.pick') }}: {{ tiebreaker.answer }}
                            <badge v-if="!tiebreaker.correctAnswer" type="pending-dark" mode="circle" />
                            <badge v-else :type="tiebreaker.isAnswerCorrect ? 'win' : 'lose'" mode="circle" />
                        </div>
                        {{ tiebreaker.correctAnswer }}
                    </div>
                </div>
            </div>
        </template>

        <div class="legend">
            <div class="lightgray">
                <div class="legend-line" v-if="ticket.purchaseDate">
                    {{ $t('ui.jackpotTicket.placedOn') }}
                    <span class="nowrap">
                        {{ formatDate(ticket.purchaseDate) + $t('ui.myBets.at') + formatTime(ticket.purchaseDate) }}
                    </span>
                </div>
                <div class="legend-line">
                    <badge type="win" mode="circle" />
                    <span>{{ $t('ui.myBets.hit') }}</span>
                    <badge type="lose" mode="circle" />
                    <span>{{ $t('ui.myBets.miss') }}</span>
                    <badge type="cancelled" mode="circle" iconName="icon-close" />
                    <span>{{ $t('ui.myBets.void') }}</span>
                </div>
            </div>
        </div>

        <MyBetsRegulatoryMessage betType="jackpotTickets" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { helper } from '@agi.packages/core';
import { Currency } from '@agi.packages/core/components';
import { MyBetsRegulatoryMessage } from '@agi.packages/sport/components';
import { sport, betType } from '@agi.packages/sport';
import { getter } from '@agi.packages/platform';
import { getter as generalGetter } from '@/store/store';

import PageMixin from '@/components/Pages/Page.mixin';
import BackButtonMixin from '@/components/Pages/BackButton.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Badge from '@/components/Badge';
import { routeName } from '@/router/const';

export default {
    name: 'JackpotTicket',
    components: { MyBetsRegulatoryMessage, Badge, Currency },
    mixins: [PageMixin, BackButtonMixin, SEOMixin],
    computed: {
        totalPrice() {
            const { freeTicketsUsed } = this.ticket;
            const { fee } = this.ticket.pool;
            const itemCount = this.ticket.totalTickets - freeTicketsUsed;
            return fee * itemCount;
        },
        totalTiebreakerPayout() {
            const { losers, winners } = this.ticket.pool.tiebreakers?.statistics || {};
            return Math.round(losers.count * losers.payout + winners.count * winners.payout);
        },
        tieWinner() {
            return this.ticket.tiebreaker?.result?.tiebreakerWinner;
        },
        isSoloWinner() {
            const { losers, winners } = this.ticket.pool.tiebreakers?.statistics || {};
            return losers.count === 0 && winners.count === 1;
        },
        taxAmount() {
            return this.taxPoolAmount * 100;
        },
        ...mapState({
            ticket: (state) => state.sport.myBets.betslip,
            error: (state) => state.sport.myBets.error,
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            currency: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            dateOptions: getter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            taxEnabled: getter.IS_POOL_TAX_ENABLED,
            taxPoolAmount: getter.GET_POOL_TAX_AMOUNT,
            getMenuItemByBetslip: generalGetter.GET_MENU_ITEM_BY_BETSLIP,
        }),
    },
    methods: {
        formatDateTime: helper.formatDateTime,
        getCancellationInfoModalName(id) {
            return `cancellation-info-${id}`;
        },
        formatDate(dateTime, options) {
            return this.formatDateTime(dateTime, {
                ...this.dateOptions,
                ...options,
                needDate: true,
                needTime: false,
            });
        },
        formatTime(dateTime, options) {
            return this.formatDateTime(dateTime, {
                ...this.dateOptions,
                ...options,
                isUtc: true,
                needDate: false,
                needTime: true,
            });
        },
        isGameScoreVisible(row) {
            return !this.isMissingResult(row) && !this.isCancelledResult(row);
        },
        isCancelledResult(row) {
            return row.result && row.result?.cancelled;
        },
        isMissingResult(row) {
            return !row.result || !row.result?.text;
        },
        onBack() {
            const section = this.getMenuItemByBetslip({ betslipType: betType.JACKPOT, status: this.ticket?.status })?.name;
            this.$router.push({ name: routeName.MY_BETS, params: { section } });
        },
    },
    created() {
        this.$store.dispatch(sport.action.GET_JACKPOT_TICKET, this.$route.params.ticketId);
    },
};
</script>

<style lang="scss" scoped>
.header {
    align-items: center;
    background-color: $bet-header-row-header-background;
    border-bottom: 1px solid $jackpot-ticket-header-border-bottom-color;
    display: flex;
    padding: 12px;
    text-align: center;

    .back {
        color: $main-text;
        cursor: pointer;

        .icon {
            height: 16px;
            width: 16px;
        }
    }

    .jackpot-title {
        display: inline-block;
        text-align: center;
        width: 100%;

        .id {
            font-size: 16px;
            font-weight: bolder;
        }

        .subtitle {
            color: $disabled-text;
            font-size: 10px;
            font-weight: normal;
            margin-left: auto;
            margin-right: auto;
            text-transform: uppercase;
            width: 180px;
            word-break: break-all;
        }
    }
}

.summary {
    background: $single-betslip-summary-background-color;
    border-bottom: $single-betslip-border;
    font-size: 14px;
    line-height: 16.8px;
    padding: 8px;

    .summary-line {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;

        .label {
            color: $main-text;
            display: inline-block;
            text-align: left;

            &.bold {
                font-weight: bold;
            }
        }

        .value {
            display: inline-block;
            font-weight: bold;
            margin-left: auto;
            text-align: right;
        }

        .winning-result {
            color: $green-success;
        }

        .message {
            font-size: 10px;
        }

        .currency {
            font-size: 10px;

            &::v-deep .amount {
                font-size: 14px;
            }
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.event {
    border-bottom: $single-betslip-border;
    color: $main-text;
    font-size: 12px;
    line-height: 16px;
    padding: 8px;

    .event-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }

        &.event-header {
            align-items: flex-start;
            font-size: 14px;
            line-height: 20px;

            .jackpot-name {
                font-size: 12px;
                line-height: 16px;
            }

            .date {
                font-weight: bold;
            }

            .badge {
                margin-right: 0;
                margin-top: 2px;
            }
        }

        .label {
            &-content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                &-text {
                    margin-right: 14px;
                }
            }

            .cancellation-badge {
                display: flex;
                align-items: center;

                &-label {
                    background-color: $medium-grey;
                    color: $main-text;
                    padding: 2px 4px;
                    max-width: 110px;
                }
                .icon-info {
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
            .league {
                color: $disabled-text;
            }
            &.question {
                flex: 1;
            }
        }

        .value {
            display: inline;
        }

        .pick {
            align-items: center;
            display: flex;
            font-weight: bold;
            &.column {
                flex-direction: column;
            }
        }

        .result {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            margin-left: 10px;
            white-space: nowrap;
        }
    }
}
.tiebreaker-headline {
    background: $single-betslip-summary-background-color;
}

.legend {
    font-size: 12px;
    margin: 12px 0;
    text-align: center;

    .legend-line {
        margin-top: 6px;

        &:first-child {
            margin-top: 0;
        }
    }

    .nowrap {
        white-space: nowrap;
    }
}
</style>
