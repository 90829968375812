<template>
    <div
        v-sniffer="{ handler: onScroll }"
        :class="[`sticky-progressive-jp sticky-progressive-jp-${progressiveJackpot.type}`, { 'sticky-progressive-jp-expanded': expanded }]"
        :style="{ height }"
        @click="toggle"
    >
        <div class="sticky-progressive-jp-head" ref="head">
            <SvgIcon :iconId="progressiveJackpot.icon" class="icon-size-very-small icon-sport" />
            <span>{{ progressiveJackpot.name }}</span>
            <span class="bullet" />
            <span class="sticky-progressive-jp-prize">
                <Currency :amount="progressiveJackpot.prize" :format="currencyFormat" :decimal="0" />
            </span>
        </div>
        <SvgIcon iconId="arrow_down" class="icon-size-very-small icon-close" verticalAlign="top" />
        <div v-show="!isHidden" class="sticky-progressive-jp-body" ref="content">
            <ProgressiveJpContent v-bind="progressiveJackpot" disabled />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Currency } from '@/modules/core/components';
import scrollSniffer from '@/js/directives/ScrollSniffer';
import ProgressiveJpContent from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpContent.vue';

export default {
    name: 'ProgressiveJpPreviewBarCollapsible',
    components: { ProgressiveJpContent, Currency },
    directives: {
        sniffer: scrollSniffer,
    },
    props: {
        progressiveJackpot: {
            type: Object,
        },
    },
    data() {
        return {
            expanded: false,
            height: null,
            defaultHeight: null,
            isHidden: true,
            timeout: null,
        };
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
    },
    methods: {
        toggle() {
            this.expanded = !this.expanded;
            this.toggleContent();
            this.$nextTick(() => {
                if (this.expanded) {
                    this.height = `${this.$refs.content.offsetHeight}px`;
                } else {
                    this.height = this.defaultHeight;
                }
            });
        },
        onScroll() {
            if (this.expanded) {
                this.toggle();
            }
        },
        toggleContent() {
            if (!this.expanded) {
                this.timeout = setTimeout(() => {
                    this.isHidden = true;
                }, 250);
            } else {
                this.timeout && clearTimeout(this.timeout);
            }
            this.isHidden = false;
        },
    },
    mounted() {
        this.defaultHeight = `${this.$refs.head.offsetHeight}px`;
        this.height = this.defaultHeight;
    },
    beforeDestroy() {
        this.timeout && clearTimeout(this.timeout);
    },
};
</script>

<style lang="scss" scoped>
.sticky-progressive-jp {
    @extend %small-details-font-400;
    position: relative;
    transition: height 250ms ease-in-out;
    overflow: hidden;

    &-head {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        visibility: visible;
        transition: all 100ms linear;
        opacity: 1;
        width: 100%;
        padding: 4px 25px 4px 12px;
        display: flex;
        align-items: center;
        background: map-get($pjp-color-schema, 'sport', 'background');
        color: map-get($pjp-color-schema, 'sport', 'text');
        position: absolute;
        z-index: 1;

        .sticky-progressive-jp-casino & {
            background: map-get($pjp-color-schema, 'casino', 'background');
            color: map-get($pjp-color-schema, 'casino', 'text');

            & .icon-sport {
                fill: map-get($pjp-color-schema, 'casino', 'icon');
            }
            & .bullet {
                background: map-get($pjp-color-schema, 'casino', 'icon');
            }
        }
    }

    .bullet {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: map-get($pjp-color-schema, 'sport', 'icon');
        margin: 0 4px;
    }

    &-prize {
        @extend %small-details-font-700;
        text-transform: uppercase;
    }

    &-expanded &-head {
        opacity: 0;
        visibility: hidden;
    }

    ::v-deep .progressive-jp-top {
        padding: 0 15px;
    }
}
.icon-close {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 7px;

    .sticky-progressive-jp-expanded & {
        transform: rotate(180deg);
    }

    .sticky-progressive-jp-casino & {
        fill: $white-bg;
    }
}
.icon-sport {
    margin-right: 4px;
}
</style>
