export const unique = (array) => {
    return array.filter((el, index, arr) => index === arr.indexOf(el));
};

export const getLocalTypes = (actions, regex) => {
    // rename to `getLocalNames` BP-16141
    return Object.keys(actions).reduce((types, actionName) => {
        return { ...types, [actionName]: actions[actionName].split(regex || '/').pop() };
    }, {});
};

export const MAKE_BETTING_FRIENDLY = 'r00dkc4b_s1_s1h7';

export default {
    MAKE_BETTING_FRIENDLY,
    unique,
    getLocalTypes,
};
