import Vue from 'vue';
import Router from 'vue-router';
import { country, DEVICE_TYPE, DEVICE_TYPE_NAMES, deviceType, helper, JURISDICTION } from '@agi.packages/core';
import { mutation as paymentMutation } from '@agi.packages/payment';
import { WithdrawPage } from '@agi.packages/payment/components';
import { betslip, MARKET_TYPE_GROUPING, marketPreferenceType, sport } from '@agi.packages/sport';
import {
    EventView,
    JackpotPage,
    JackpotTicket,
    MyBetsView,
    PopularView,
    SearchView,
    SingleBetslipView,
    SportsView,
    VirtualMatchdayView,
    VirtualSportsView,
    BoostedEventsView,
} from '@agi.packages/sport/components';

import { action as platformAction, auth, getter as platformGetter, messaging } from '@agi.packages/platform';
import {
    AccountVerification,
    ChangePassword,
    JoinNowPage,
    LoginPageV2,
    Messages,
    MyAccount,
    Registration,
    ResetPassword,
    SetPassword,
    StatementView,
} from '@agi.packages/platform/components';

import store, { action, mutation } from '@/store/store';
import { getter as transGetter } from '@/store/modules/translations';

import redirections from './redirections';
import { routeName } from '@/router/const';

const LandingPage = () => import('@/components/Pages/LandingPage');
const EventNotFound = () => import('@/components/Pages/EventNotFound');
const GenericPage = () => import('@/components/Pages/GenericPage');
const RulesPage = () => import('@/components/Pages/RulesPage');
const PageNotFound = () => import('@/components/Pages/PageNotFound');
const SelfExclusionPage = () => import('@/components/Pages/SelfExclusionPage');

const pinOrPassword = helper.capitalize(store.getters[transGetter.PIN_OR_PASSWORD]);
const CASINO_PAGE_ROUTE = {
    component: GenericPage,
    meta: {
        moduleSwitch: ['brandGamingIntegration.enabled'],
        seo: {
            template: JURISDICTION.casinoType.seoTemplate,
        },
        forceBalanceCheck: true,
    },
};
// TODO import casinoType.path from core -> jurisdiction-const
const casinoRedirect = ['casino', 'games'].find((path) => path !== JURISDICTION.casinoType.path);

const setRetainScrollMeta = ({ to, from, retainFromRoute, elementSelector }) => {
    if (!deviceType.isPresto() && to.meta?.isClickedBack && from.name === retainFromRoute) {
        to.meta.scrollSelector = elementSelector;
    }
};

Vue.use(Router);

const router = new Router({
    mode: 'history',
    scrollBehavior: handleScrollBehaviour,
    routes: [
        ...redirections,
        {
            path: '/',
            name: routeName.HOMEPAGE,
            component: GenericPage,
            beforeEnter: (to, from, next) => {
                const { bookingCode } = to.query;
                if (bookingCode) {
                    store.dispatch(betslip.action.LOAD_BOOKING_CODE_BY_QUERY, bookingCode);
                    next({ path: to.path });
                } else {
                    next();
                }
            },
            props: {
                slotId: 'HOME_PAGE_COMPONENT',
            },
            meta: {
                seo: {
                    template: 'main',
                },
            },
        },
        {
            path: '/deposit',
            name: routeName.DEPOSIT,
            component: GenericPage,
            beforeEnter: (to, from, next) => {
                const isPrestoPageAvailable = deviceType.isPresto() && country.NG;
                if (isPrestoPageAvailable) {
                    next('/deposit-presto');
                } else {
                    next();
                }
            },
            meta: {
                seo: {
                    template: 'other',
                    page: 'deposit',
                },
                requireRedirect: !country.NG,
                redirect: ({ from }) => {
                    const newRoute = {};
                    const newHref = store.getters[platformGetter.GET_DEPOSIT_PAGE_LINK];
                    const { skipDepositRedirect } = from.meta || {};

                    if (!skipDepositRedirect && newHref && newHref !== '/deposit') {
                        newRoute.path = newHref;
                    }
                    return newRoute;
                },
            },
        },
        {
            path: '/deposit-presto',
            name: routeName.DEPOSIT, // note: better use different name, because no ability to redirect deposit-pesto by route name (see DEPOSIT route above)
            alias: '/deposit',
            component: GenericPage,
            meta: {
                seo: {
                    template: 'other',
                    page: 'deposit',
                },
            },
        },
        {
            path: '/change-country',
            name: routeName.LANDING,
            component: LandingPage,
            meta: {
                seo: {
                    template: 'main',
                },
            },
        },
        {
            path: '/event/:id',
            name: routeName.EVENT,
            component: EventView,
            meta: {
                seo: {
                    skipOnCreated: true,
                },
            },
        },
        {
            path: '/preview/:id',
            name: routeName.PREVIEW,
            component: GenericPage,
            props: {
                isPreviewRoute: true,
            },
        },
        {
            path: '/event404',
            name: routeName.EVENT_NOT_FOUND,
            component: EventNotFound,
            meta: {
                seo: {
                    skipOnCreated: true,
                },
            },
        },
        {
            path: '/login',
            name: routeName.LOGIN,
            component: LoginPageV2,
            meta: {
                requireAuth: false,
                redirect: { path: '/' },
                seo: {
                    template: 'login',
                    page: 'login',
                },
            },
        },
        {
            path: '/join-now',
            name: routeName.JOIN_NOW,
            component: JoinNowPage,
            meta: {
                requireAuth: false,
                redirect: { path: '/' },
                seo: {
                    template: 'joinNow',
                    page: 'join-now',
                },
            },
        },
        {
            path: '/logout',
            name: routeName.LOGOUT,
            meta: {
                skipBalanceCheck: true,
            },
            beforeEnter(to, from, next) {
                store.dispatch(auth.action.LOGOUT).finally(() => next({ name: to.query?.nextPath || 'Homepage' }));
            },
        },
        {
            path: '/account',
            name: routeName.MY_ACCOUNT,
            component: MyAccount,
            meta: {
                requireAuth: true,
                redirect: {
                    path: '/login',
                    query: { returnPath: '/account' },
                },
                seo: {
                    skipOnCreated: true,
                },
            },
        },
        {
            path: '/change-password',
            name: routeName.CHANGE_PASSWORD,
            component: ChangePassword,
            meta: {
                requireAuth: true,
                redirect: {
                    path: '/login',
                    query: { returnPath: '/change-password' },
                },
                seo: {
                    template: 'other',
                    page: 'change' + pinOrPassword,
                },
            },
        },
        {
            path: '/statements',
            name: routeName.STATEMENT,
            component: StatementView,
            meta: {
                requireAuth: true,
                redirect: {
                    path: '/login',
                    query: { returnPath: '/statements' },
                },
                seo: {
                    template: 'statement',
                    page: 'statement',
                },
                forceBalanceCheck: true,
            },
        },
        {
            path: '/messages',
            name: routeName.MESSAGES,
            component: Messages,
            meta: {
                requireAuth: true,
                redirect: {
                    path: '/login',
                    query: { returnPath: '/messages' },
                },
                seo: {
                    template: 'messages',
                    page: 'messages',
                },
            },
        },
        {
            path: '/country/:id?', // note: optional to avoid empty countryPath in SEO.mixin ld+json breadcrumbList, previous was "/country?id=" - wrong, but always is, seems like issue
            name: routeName.COUNTRY,
            component: SportsView,
            props: {
                action: sport.action.GET_EVENTS_BY_QUERY,
                payloadHandler: ({ marketId, regionId, categoryId }) => ({
                    regionId,
                    categoryId,
                    marketFilters: {
                        marketPreference: marketPreferenceType.DEFINED,
                        marketTypeIds: [`${marketId}`],
                    },
                }),
                seoHandler: ({ country, id, sport }) => ({
                    country,
                    countryId: id,
                    sport,
                }),
                skipSetSEOTags: false,
            },
            meta: {
                seo: {
                    skipOnCreated: true,
                },
                showEventFilters: true,
            },
            beforeEnter(to, from, next) {
                setRetainScrollMeta({
                    to,
                    from,
                    retainFromRoute: 'Event',
                    elementSelector: `[data-event-id="${from.params.id}"]`,
                });
                next();
            },
        },
        {
            path: '/group/:id',
            name: routeName.GROUP,
            component: SportsView,
            props: {
                action: sport.action.GET_EVENTS_BY_QUERY,
                payloadHandler: ({ marketId, competitions, categoryId }) => ({
                    competitions,
                    categoryId,
                    marketFilters: {
                        marketPreference: marketPreferenceType.DEFINED,
                        marketTypeIds: [`${marketId}`],
                    },
                }),
                seoHandler: ({ id, regionId, league, country, sport }) => ({
                    league,
                    leagueId: id,
                    sport,
                    country,
                    countryId: regionId,
                }),
                skipSetSEOTags: false,
            },
            meta: {
                seo: {
                    skipOnCreated: true,
                },
                showEventFilters: true,
            },
            beforeEnter(to, from, next) {
                setRetainScrollMeta({
                    to,
                    from,
                    retainFromRoute: 'Event',
                    elementSelector: `[data-event-id="${from.params.id}"]`,
                });
                next();
            },
        },
        {
            path: '/upcoming',
            name: routeName.UPCOMING,
            component: SportsView,
            props: {
                action: sport.action.GET_EVENTS_BY_QUERY,
                payloadHandler: ({ marketId, categoryId, competitions }) => ({
                    competitions,
                    categoryId,
                    marketFilters: {
                        marketPreference: marketPreferenceType.DEFINED,
                        marketTypeIds: [`${marketId}`],
                    },
                }),
                skipSetSEOTags: true,
                tabsAligned: true,
            },
            meta: {
                seo: {
                    template: 'upcoming',
                    page: 'upcoming',
                },
                showEventFilters: true,
            },
            beforeEnter(to, from, next) {
                setRetainScrollMeta({
                    to,
                    from,
                    retainFromRoute: 'Event',
                    elementSelector: `[data-event-id="${from.params.id}"]`,
                });
                next();
            },
        },
        {
            path: '/popular',
            name: routeName.POPULAR,
            component: PopularView,
            meta: {
                seo: {
                    template: 'popular',
                    page: 'popular',
                },
            },
            beforeEnter(to, from, next) {
                setRetainScrollMeta({
                    to,
                    from,
                    retainFromRoute: 'Event',
                    elementSelector: `[data-event-id="${from.params.id}"]`,
                });
                next();
            },
        },
        {
            path: '/live',
            name: routeName.LIVE,
            component: GenericPage,
            props: {
                params: {
                    sort: (event) => event.additionalInfo?.live,
                },
            },
            meta: {
                useCustomStyle: true,
                seo: {
                    template: 'live',
                },
            },
        },
        {
            path: '/live-(ice-hockey|[A-Za-z]+)',
            name: routeName.LIVE_CATEGORY,
            component: GenericPage,
            meta: {
                useCustomStyle: true,
                seo: {
                    template: 'liveCategory',
                },
            },
            beforeEnter(to, from, next) {
                to.params.pathMatch = to.path;
                to.params.category = to.path.replace('/live-', '');
                next();
            },
        },
        {
            path: '/boosted',
            name: routeName.BOOSTED_EVENTS,
            component: BoostedEventsView,
            beforeEnter(to, from, next) {
                setRetainScrollMeta({
                    to,
                    from,
                    retainFromRoute: 'Event',
                    elementSelector: `[data-event-id="${from.params.id}"]`,
                });
                next();
            },
        },
        {
            path: '/virtual-sports',
            name: routeName.VIRTUAL_SPORTS,
            component: VirtualSportsView,
            meta: {
                isVirtual: true,
                moduleSwitch: ['virtuals.enabled'],
                seo: {
                    template: 'virtual',
                    page: 'virtual',
                },
            },
        },
        {
            path: '/virtual-sports/matchday/:seasonId/:roundId',
            name: routeName.MATCH_DAY,
            component: VirtualMatchdayView,
            meta: {
                isVirtual: true,
                seo: {},
            },
        },
        {
            path: '/jackpot',
            name: routeName.JACKPOT_PAGE,
            component: JackpotPage,
            props: {
                slotId: ['ACTIVE_JACKPOT_PAGE', 'RESULTS_JACKPOT_PAGE'],
            },
            meta: {
                seo: {
                    template: 'jackpot',
                    page: 'jackpot',
                },
            },
        },
        {
            path: '/search',
            name: routeName.SEARCH,
            component: SearchView,
            meta: {
                seo: {
                    template: 'other',
                    page: 'search',
                },
            },
        },
        {
            path: '/bets/:section?',
            name: routeName.MY_BETS,
            component: MyBetsView,
            meta: {
                requireAuth: true,
                redirect: (route) => ({
                    path: '/login',
                    query: { returnPath: route.to.path },
                }),
                seo: {
                    template: 'myBets',
                    page: 'myBets',
                },
            },
        },
        {
            path: '/jackpot-ticket/:ticketId',
            name: routeName.JACKPOT_TICKET,
            component: JackpotTicket,
            meta: {
                requireAuth: true,
                redirect: (route) => ({
                    path: '/login',
                    query: { returnPath: route.to.path },
                }),
                backButtonPathName: 'My Bets',
                seo: {
                    template: 'other',
                    page: 'placedJackpotTicket',
                },
            },
        },
        {
            path: '/betslip/:betslipType?/:id/',
            name: routeName.BETSLIP,
            component: SingleBetslipView,
            meta: {
                requireAuth: true,
                redirect: (route) => ({
                    path: '/login',
                    query: { returnPath: route.to.path },
                }),
                backButtonPathName: 'My Bets',
                seo: {
                    template: 'other',
                    page: 'placedBetslip',
                },
            },
        },
        {
            path: '/snapshot/betslip/:id',
            name: routeName.SNAPSHOT_BETSLIP,
            component: SingleBetslipView,
            meta: {
                backButtonPathName: 'My Bets',
                seo: {
                    template: 'other',
                    page: 'placedBetslip',
                },
            },
        },
        {
            path: '/signup',
            name: routeName.SIGNUP,
            component: Registration,
            meta: {
                requireAuth: false,
                redirect: { path: '/' },
                seo: {
                    template: 'other',
                    page: 'registration',
                },
                savedPosition: {
                    top: 0,
                    left: 0,
                },
            },
        },
        {
            path: '/verification/:phoneNumber?',
            name: routeName.ACCOUNT_VERIFICATION,
            component: AccountVerification,
            props: {
                isPasswordRequired: false,
                changePhoneRoute: routeName.JOIN_NOW,
            },
            meta: {
                requireAuth: false,
                redirect: { path: '/' },
                seo: {
                    template: 'other',
                    page: 'accountVerification',
                },
            },
        },
        {
            path: '/reset/:phoneNumber?',
            name: routeName.RESET,
            component: AccountVerification,
            props: (route) => ({
                isAccountVerification: false,
                verificationCodeSent: route.params.verificationCodeSent,
                isVerificationModalOpened: route.params.isVerificationModalOpened,
                changePhoneRoute: routeName.RESET_PASSWORD,
            }),
            meta: {
                requireAuth: false,
                redirect: { path: '/' },
                seo: {
                    template: 'other',
                    page: 'reset' + pinOrPassword,
                },
            },
        },
        {
            path: '/vf/:hash',
            name: routeName.SMS_VERIFICATION,
            component: AccountVerification,
            props: (route) => ({ smsVerification: route.params.hash, isPasswordRequired: false }),
        },
        {
            path: '/forgot/:phoneNumber?',
            name: routeName.RESET_PASSWORD,
            component: ResetPassword,
            meta: {
                requireAuth: false,
                redirect: { path: '/' },
                seo: {
                    template: 'other',
                    page: 'reset' + pinOrPassword,
                },
            },
        },
        {
            path: '/set-password',
            name: routeName.SET_PASSWORD,
            component: SetPassword,
            meta: {
                requireAuth: true,
                redirect: {
                    path: '/login',
                    query: { returnPath: '/set-password' },
                },
                seo: {
                    template: 'other',
                    page: 'set' + pinOrPassword,
                },
            },
        },
        {
            path: '/verify',
            name: routeName.VERIFY_PAGE,
            component: GenericPage,
            meta: {
                seo: {
                    template: 'other',
                },
            },
        },
        {
            path: `/${casinoRedirect}`,
            redirect: JURISDICTION.casinoType.path,
        },
        {
            path: `/${JURISDICTION.casinoType.path}`,
            name: routeName.CASINO,
            ...CASINO_PAGE_ROUTE,
        },
        {
            path: '/games/:game',
            name: routeName,
            beforeEnter(to) {
                window.location.pathname = to.path;
            },
            meta: {
                allowedDeviceType: [DEVICE_TYPE_NAMES.WEB, DEVICE_TYPE_NAMES.APP, DEVICE_TYPE_NAMES.SMART_PHONE],
                redirect: {
                    path: '/games',
                },
                skipBalanceCheck: true,
            },
        },
        {
            path: '/pawa6',
            name: routeName.PAWA6,
            meta: {
                query: {
                    language: store.getters[transGetter.GET_SELECTED_LANGUAGE],
                },
                isExternal: true,
            },
            beforeEnter(to, from, next) {
                const { isPawaSixEnabled } = store.getters[platformGetter.GET_BRAND_PREFERENCE];
                if (isPawaSixEnabled) {
                    next();
                } else {
                    next('/');
                }
            },
        },
        {
            path: '/rules',
            name: routeName.RULES,
            component: GenericPage,
            beforeEnter(to, from, next) {
                if (deviceType.isPresto()) {
                    next({ name: 'Rules presto' });
                } else {
                    next();
                }
            },
            meta: {
                seo: {
                    template: 'rules',
                    page: 'rules',
                },
            },
        },
        {
            path: '/presto/rules',
            name: routeName.RULES_PRESTO,
            component: RulesPage,
            meta: {
                seo: {
                    template: 'rules',
                    page: 'rules',
                },
            },
        },

        // ------------ START OF UNKNOWN -----------------------
        {
            path: '/mtn-airtime-banner', // Page or content ?
            name: routeName.MTN_AIRTIME_BANNER,
            component: GenericPage,
            props: {
                slotId: 'MTN_AIRTIME_BANNER', // EMPTY
            },
        },
        // ------------------- END OF UNKNOWN -----------------------------
        {
            path: '/withdraw',
            name: routeName.WITHDRAW,
            component: WithdrawPage,
            meta: {
                requireAuth: true,
                redirect: {
                    path: '/login',
                    query: { returnPath: '/withdraw' },
                },
                seo: {
                    template: 'withdraw',
                    page: 'withdraw',
                },
            },
            beforeEnter(to, from, next) {
                store.commit(paymentMutation.SET_EXPERIMENTAL_PAYOUTS, false);
                next();
            },
        },
        {
            path: '/withdraw-disabled',
            name: routeName.WITHDRAW_DISABLED,
            component: WithdrawPage,
            beforeEnter(to, from, next) {
                store.commit(paymentMutation.SET_EXPERIMENTAL_PAYOUTS, true);
                next();
            },
        },
        {
            path: '/404',
            name: routeName.NOT_FOUND,
            component: PageNotFound,
            props: {
                slotId: 'ERROR_PAGE_404',
            },
            meta: {
                seo: {
                    template: 'other',
                    page: '404',
                },
            },
        },
        {
            path: '/cashout-locked',
            name: routeName.CASHOUT_LOCKED,
            component: GenericPage,
            meta: {
                seo: {
                    template: 'cashoutLocked',
                },
            },
        },
        {
            path: '/presto-self-exclusion',
            name: routeName.PRESTO_SELF_EXCLUSION,
            component: SelfExclusionPage,
            seo: {
                template: 'other',
            },
        },
        {
            path: '/*',
            name: routeName.GENERIC_PAGE,
            component: GenericPage,
        },
    ],
});

// TODO: upcoming vue router should have a built in back detection, update router and refactor the guard
// https://github.com/vuejs/vue-router/issues/2571
let currentTimestamp = 0;
let reopenBetslip = false;

// Global route guard
router.beforeEach((to, from, next) => {
    if (to.meta?.isExternal) {
        const url = new URL(window.location);
        const requiredParams = Object.entries(to.meta?.query || {});
        const missParams = requiredParams.filter(([k, v]) => !url.searchParams.has(k));

        if (missParams.length) {
            url.pathname = to.path;
            url.searchParams.delete('r');
            url.searchParams.delete('returnPath');
            missParams.forEach(([key, value]) => {
                url.searchParams.set(key, value);
            });

            window.location = url.toString();
            return false;
        }
    }

    store.commit(sport.mutation.SET_SPORT_TYPE, to.meta?.isVirtual);
    store.commit(mutation.CLOSE_TOP_MENU);

    const { categoryId } = to.params;
    // TODO: map sport types https://aliengain.atlassian.net/browse/BP-19617
    if (categoryId && Object.keys(MARKET_TYPE_GROUPING).includes(categoryId)) {
        store.commit(mutation.SET_CURRENT_CATEGORY, categoryId);
    }

    // back button detection
    if (!history.state) {
        history.replaceState({ key: Date.now() }, to.name, location.href);
    }
    const isClickedBack = currentTimestamp > Number(history.state.key);

    if (to.meta) {
        to.meta.isClickedBack = isClickedBack;
        to.meta.scrollSelector = undefined;
    }

    if (isClickedBack) {
        if (store.state.ui.sidebarOpen && Vue.$mq.isXMedium) {
            store.dispatch(action.TOGGLE_SIDEBAR);
            return next(false);
        }
        if (reopenBetslip) {
            store.dispatch(action.SET_BETSLIP_STATE, true);
        }
    } else {
        if (!reopenBetslip) {
            reopenBetslip = store.state.ui.betslipOpen;
        } else {
            reopenBetslip = false;
        }
        // #top is the Presto way to scroll up to the betslip
        if (to.hash !== '#top') {
            store.dispatch(action.SET_BETSLIP_STATE, false);
        }
    }
    const { brand } = store.getters[platformGetter.GET_SETTINGS];

    if (!brand || !Object.keys(brand).length) {
        // a hack to set deviceType header for devices where android app is not able to set window flags on time
        if (DEVICE_TYPE !== DEVICE_TYPE_NAMES.APP && deviceType.isApp()) {
            Vue.$http.defaults.headers.deviceType = DEVICE_TYPE_NAMES.APP;
        }

        store.dispatch(platformAction.GET_CONFIG).then(() => {
            store.dispatch(platformAction.LOAD_AGI_SETTINGS).then(({ user }) => {
                handleRouteMetaData(user && user.userUuid, store.state.platform.settings, { to, from, next });
            });
        });
    } else {
        handleRouteMetaData(store.getters[auth.getter.IS_AUTHENTICATED], store.state.platform.settings, { to, from, next });
    }
});

router.afterEach((to) => {
    if (!to.meta?.selfRedirect) {
        Vue.nextTick((vm) => {
            currentTimestamp = Number(history.state.key);
            router.app.$root.$emit('routeChanged');
        });
    }
});

function handleRouteMetaData(isAuthenticated, settings, { to, from, next }) {
    const {
        allowedJurisdictions,
        allowedDeviceType,
        moduleSwitch,
        redirect,
        skipBalanceCheck,
        forceBalanceCheck,
        requireAuth,
        requireRedirect,
    } = to.meta || {};
    const UTMQuery = getUTMQueryObject({ to, from });
    const CasinoQuery = getCasinoQueryObject({ to, from });
    const redirectRoute = typeof redirect === 'function' ? redirect({ to, from, next }) : redirect;

    if (allowedJurisdictions) {
        if (!allowedJurisdictions.includes(JURISDICTION.id)) {
            next({ path: '/', UTMQuery });
            return;
        }
    }
    if (allowedDeviceType && !allowedDeviceType.includes(DEVICE_TYPE)) {
        if (redirect) {
            next({ ...redirectRoute, UTMQuery });
        } else {
            next({ path: '/', UTMQuery });
        }
        return;
    }
    if (moduleSwitch && !helper.pathChecker(moduleSwitch, settings)) {
        next({ path: '/', UTMQuery });
        return;
    }

    const isLogout = to.name === routeName.LOGOUT;
    if (isAuthenticated && !isLogout) {
        const isSkipBalance = skipBalanceCheck || from.name === to.name;
        if (!isSkipBalance) {
            store.dispatch(auth.action.GET_BALANCE, { force: !!forceBalanceCheck });
        }
        store.dispatch(messaging.action.GET_ONSITE_MESSAGES_AND_COUNT);
    }
    const redirectRequired = isAuthenticated
        ? (requireAuth !== undefined && !requireAuth) || requireRedirect
        : requireAuth || requireRedirect;

    applyQueryAndRedirect({ to, from, next }, UTMQuery, CasinoQuery, redirectRequired, redirectRoute);
}

function getUTMQueryObject({ to, from }) {
    const fromUTM = Object.keys(from.query).filter((queryParam) => queryParam.startsWith('utm'));
    const toUTM = Object.keys(to.query).filter((queryParam) => queryParam.startsWith('utm'));
    if (toUTM.length || !fromUTM.length) {
        return {};
    }
    return fromUTM.reduce(
        (UTMParams, queryParam) => ({
            ...UTMParams,
            [queryParam]: from.query[queryParam],
        }),
        {}
    );
}

function getCasinoQueryObject(route) {
    const allowableCasinoQuery = ['gameId', 'filter', 'favouriteCandidateGameId'];
    const fromCasino = Object.keys(route.from.query).filter((query) => allowableCasinoQuery.includes(query));
    const toCasino = Object.keys(route.to.query).filter((query) => allowableCasinoQuery.includes(query));
    if (toCasino.length || !fromCasino.length) {
        return {};
    }
    return fromCasino.reduce(
        (CasinoParams, queryParam) => ({
            ...CasinoParams,
            [queryParam]: route.from.query[queryParam],
        }),
        {}
    );
}

function applyQueryAndRedirect({ to, from, next }, UTMQuery, CasinoQuery, redirectRequired = false, redirectRoute = {}) {
    if (redirectRequired && redirectRoute.path) {
        redirectRoute.query = {
            ...redirectRoute.query,
            ...UTMQuery,
            ...to.query,
        };
        next(redirectRoute);
        return;
    }

    const isCasinoQuery = Object.keys(CasinoQuery).length && from.path === '/login' && to.path === `/${JURISDICTION.casinoType.path}`;

    to.meta.selfRedirect = true;
    if (to.meta.isClickedBack) {
        next();
        return;
    }
    if (isCasinoQuery) {
        next({
            ...to,
            query: { ...to.query, ...CasinoQuery },
        });
    } else {
        delete to.meta.selfRedirect;
        next();
        if (from.meta?.skipDepositRedirect) {
            delete from.meta.skipDepositRedirect;
        }
    }
}

function handleScrollBehaviour(to, from, savedPosition) {
    const EXTRA_OFFSET = 100;
    this.app.$nextTick().then((app) => {
        if (to.hash || savedPosition || to.meta?.savedPosition) {
            app.$scroll.scrollTo(to.hash || savedPosition || to.meta?.savedPosition, { extraOffset: EXTRA_OFFSET });
        }
    });
}

export default router;
