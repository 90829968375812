<template>
    <div id="modals-container">
        <Modal
            v-for="modal in modals"
            :key="`${modal.modalAttrs.name}-${modal.id}`"
            :ref="modal.modalRef"
            :fixed="true"
            :isDynamic="true"
            v-bind="modal.modalAttrs"
            v-on="modal.modalListeners"
            @close="$modal.hide(modal.modalAttrs.name)"
            @closed="remove(modal.id)"
        >
            <component
                v-if="modal.isComponent"
                :is="modal.component"
                v-bind="modal.componentAttrs"
                v-on="$listeners"
                :modalRefKey="modal.modalRef"
                :modalRefs="$refs"
                @close="closeModal({ modalRef: $refs[modal.modalRef], modalName: modal.modalAttrs.name, avoidListeners: $event })"
            />
        </Modal>
    </div>
</template>
<script>
const PREFIX = '_dynamic_modal_';
const generateId = (
    (index = 0) =>
    () =>
        (index++).toString()
)();

export default {
    data() {
        return {
            modals: [],
        };
    },
    created() {
        this.$root._dynamicContainer = this;
    },
    methods: {
        add(component, componentAttrs = {}, modalAttrs = {}, modalListeners) {
            const id = generateId();
            const name = modalAttrs.name || PREFIX + id;
            const isComponent = !!component.template || !!component.render;
            this.modals.push({
                id,
                modalAttrs: { ...modalAttrs, name },
                modalListeners,
                component,
                isComponent,
                componentAttrs,
                modalRef: `modal-${modalAttrs.name}-${id}`,
            });
            this.$nextTick(() => {
                this.$modal.show(name);
            });
        },
        closeModal({ modalRef, modalName, avoidListeners }) {
            if (avoidListeners) {
                this.$modal.hide(modalName);
            } else if (modalRef[0]) {
                modalRef[0].$emit('close', avoidListeners);
            }
        },
        remove(id) {
            for (let i in this.modals) {
                if (this.modals[i].id === id) {
                    this.modals.splice(i, 1);
                    return;
                }
            }
        },
        list() {
            return this.modals;
        },
    },
};
</script>

<style scoped>
#modals-container {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9999;
}
</style>
