<template>
    <BaseCashOut />
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { sport } from '@agi.packages/sport';

import BaseCashOut from '@/modules/sport/components/Fragments/Cashout/BaseCashOut.vue';

export default {
    name: 'CashOut',
    components: { BaseCashOut },
    computed: {
        ...mapState({
            betslip: (state) => state.sport.myBets.betslip,
            myBetsCashoutClicked: (state) => state.sport.ui.myBetsCashoutClicked,
        }),
    },
    methods: {
        ...mapMutations({
            setCashoutOfferError: sport.mutation.SET_CASHOUT_OFFER_ERROR,
            setIsConfirmCashOutReady: sport.mutation.SET_IS_CONFIRM_CASHOUT_READY,
        }),
    },
    created() {
        this.setIsConfirmCashOutReady(this.myBetsCashoutClicked || this.betslip.cashoutInfo?.displayingEnabled);
        this.setCashoutOfferError();
    },
};
</script>

<style scoped lang="scss">
::v-deep .request-offer {
    padding: 12px;
    background-color: white;

    &.error {
        border: 1px solid $error-red;
    }

    &.warn {
        border: 1px solid $golden-brown;
    }

    .offer-message {
        .cashout-icon {
            margin: -3px 8px 0 0;
        }
    }

    @include only_mini {
        ::v-deep .button-media {
            display: block;
            width: 14px;
            height: 14px;
        }
    }

    &-update {
        border: 1px solid $golden-brown;
    }
}

::v-deep .button-refresh {
    height: 36px;
}

::v-deep .button-media {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
