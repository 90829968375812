<template>
    <ExpansionPanel
        v-if="!!tournaments && tournaments.length"
        data-test-id="menu-tournaments"
        :title="title"
        :control="!isPresto"
        :expanded="true"
    >
        <slot :tournaments="tournaments" :currentRoute="currentRoute" :trackMainNav="trackMainNav">
            <MenuItem
                class="sub-menu"
                v-for="(tournament, index) in tournaments"
                :key="`tournament-${tournament.id}-${index}`"
                :selected="currentRoute.id === tournament.id"
                :link="{ name: 'Group', query: { id: tournament.id } }"
                :item="{
                    text: tournament.name,
                    control: {
                        text: tournament.eventCount,
                        iconDisabled: true,
                    },
                }"
                :data-test-id="`tournament-${tournament.id}`"
                @click.native="trackMainNav(tournament.name, 'tournaments_nav')"
            >
                <template slot="icon">
                    <ImageIcon
                        v-if="tournament.region"
                        class="icon-status"
                        :src="tournament.region && `img/flags/${tournament.regionSlug.toLowerCase()}.png`"
                        :altSrc="tournament.regionSlug && `img/flags/${tournament.parentRegionSlug}.png`"
                    />
                </template>
            </MenuItem>
        </slot>
    </ExpansionPanel>
</template>

<script>
import { mapState } from 'vuex';
import { deviceType } from '@agi.packages/core';
import { MenuItem, ExpansionPanel } from '@agi.packages/core/components';

export default {
    name: 'TournamentsList',
    components: { MenuItem, ExpansionPanel },
    data: function () {
        return {
            currentRoute: {
                name: '',
                id: null,
                category: null,
            },
            isPresto: deviceType.isPresto(),
            title: this.$t('ui.eventFilters.popularTournaments'),
        };
    },
    computed: {
        ...mapState({
            tournaments: 'currentTournaments',
        }),
    },
    methods: {
        trackMainNav(element, event = 'main_nav') {
            this.$emit('onMenuItemClick', { element, event });
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.$data.currentRoute = { name: to.name, id: to.query.id, category: to.query.category };
            },
        },
    },
};
</script>

<style></style>
