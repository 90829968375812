import { getObjectField, isNil } from '../helper';
import { flatDataAttributes } from './flatDataAttributes';

export function getStrapiData(relationName, defVal) {
    return (entity) => {
        let data = getObjectField(entity, relationName + '.data');

        if (isNil(data)) {
            data = getObjectField(entity, relationName);
        }

        if (isNil(data)) {
            return defVal;
        }

        return formatStrapiStructure(data);
    };
}

function formatStrapiStructure(obj) {
    return Array.isArray(obj) ? obj.map(flatDataAttributes) : flatDataAttributes(obj);
}
