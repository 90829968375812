<template>
    <div class="event" data-test-id="stakeInfo" :class="{ 'event-live': isInPlay }">
        <div class="event-line event-header">
            <Modal
                v-if="isCancelled && cancellationType"
                class="cancellation-info"
                :name="cancellationInfoModalName"
                width="80%"
                :avoidCollisionWithFooter="true"
                @close="$modal.hide(cancellationInfoModalName)"
            >
                <renderer :input="cancellationInfoText" />
            </Modal>
            <div class="event-label">
                <div class="label" :class="{ strikethrough: isCancelled }">
                    <div v-if="isInPlay">
                        <Countdown v-if="canDisplayGameMinute" class="inplay-countdown">
                            <span class="inplay-countdown-text">
                                {{ $t('ui.singleBetslip.inPlay.countDown', { minute: inPlayDetails.display.minute }) }}
                            </span>
                        </Countdown>
                        <SvgIcon v-else iconId="icon-live" class="icon icon-size-medium icon-live" />
                    </div>
                    <div v-else>
                        {{ time }} <span class="date">{{ date }}</span>
                    </div>
                </div>
                <Badge v-if="!isPresto && isLocked" type="locked" mode="locked" icon-name="icon-lock" :text="$t('ui.cashout.locked')" />
                <div v-if="isCancelled && cancellationType" class="cancellation-badge">
                    <div class="cancellation-badge-label bold text">{{ cancellationReasonText }}</div>
                    <div class="cancellation-badge-info">
                        <SvgIcon
                            @click.native="$modal.show(cancellationInfoModalName)"
                            class="icon-info icon-size-medium"
                            iconId="icon-info"
                            verticalAlign="text-top"
                        />
                    </div>
                </div>
            </div>
            <div :class="`value odd ${odd.status.toLowerCase()}`">
                <span :class="{ strikethrough: isCancelled || isStakeBack }">{{ $numberFormat(odd.price) }}</span>
                <badge class="badge-result" :type="badgeType" mode="circle" :iconName="`${isCancelled ? 'icon-close' : ''}`" />
            </div>
        </div>
        <div class="event-line">
            <div class="label">
                <div class="match" data-test-id="matchName">
                    <EventNameWithLink
                        :title="odd.selection.event.name"
                        :eventId="odd.selection.event.id"
                        :isLink="
                            $isEventLive({
                                startDate: odd.selection.starts,
                                isVirtual: isVirtual,
                            })
                        "
                    />
                </div>
                <div class="league">{{ odd.selection.competition.name }}</div>
                <div class="bold">
                    <span>{{ formattedMarketName }}</span>
                </div>
            </div>
            <div class="value">
                <span v-if="isMatchScore" class="result inplay">
                    {{ formattedMatchScore }}
                </span>
                <span v-else-if="odd.resultText" class="result">
                    {{ odd.resultText }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter as platformGetter } from '@agi.packages/platform';
import {
    singleBetslipOddStatus,
    cancellationReasonType,
    cancellationReasonTranslationKey,
    cancellationReasonLinkId,
} from '@agi.packages/sport';
import { isEventLive } from '@agi.packages/sport/utils/event/is-event-live';
import { deviceType, helper } from '@agi.packages/core';
import Badge from '@/components/Badge.vue';
import { EventNameWithLink } from '@agi.packages/sport/components';
import Countdown from '@/components/Countdown';

export default {
    name: 'SingleBetslipOdd',
    components: { Badge, Countdown, EventNameWithLink },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    props: {
        odd: {
            type: Object,
            required: true,
        },
        isVirtual: {
            type: Boolean,
            default: false,
        },
        isLocked: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850,
        }),
        inPlayDetails() {
            return this.odd.inPlayDetails || {};
        },
        canDisplayGameMinute() {
            return !this.isPresto && this.$getObjectField(this.inPlayDetails, 'display.minute', false);
        },
        isCancelled() {
            return this.odd.status === singleBetslipOddStatus.CANCELLED;
        },
        isStakeBack() {
            return this.odd.status === singleBetslipOddStatus.STAKE_BACK;
        },
        cancellationType() {
            return Object.keys(cancellationReasonType).find((key) => cancellationReasonType[key] === this.odd?.cancellationReason);
        },
        cancellationReasonText() {
            return this.$t(`ui.singleBetslip.cancellationReason.${cancellationReasonTranslationKey[this.cancellationType] || ''}`);
        },
        cancellationInfoText() {
            if (this.cancellationType === cancellationReasonType.ODDS_ERROR) {
                return this.$t(`ui.singleBetslip.cancellationInfo.oddsText`);
            }
            const reason = this.$t(
                `ui.singleBetslip.cancellationInfo.reasons.${cancellationReasonTranslationKey[this.cancellationType] || ''}`
            );
            const linkId = cancellationReasonLinkId[this.cancellationType] || '';
            return this.$t(`ui.singleBetslip.cancellationInfo.text`, { reason, linkId });
        },
        cancellationInfoModalName() {
            return `cancellation-info-${this.odd.selection.id}`;
        },
        time() {
            return this.$formatDateTime(this.odd.selection.starts, { needDate: false, isUtc: true, ...this.dateOptions });
        },
        date() {
            return this.$formatDateTime(this.odd.selection.starts, { needTime: false, isUtc: true, ...this.dateOptions });
        },
        badgeType() {
            if (this.odd.status === singleBetslipOddStatus.WIN || this.odd.status === singleBetslipOddStatus.LOSE) {
                return singleBetslipOddStatus[this.odd.status].toLowerCase();
            }
            if (this.odd.status === singleBetslipOddStatus.CANCELLED) {
                return singleBetslipOddStatus.CANCELLED.toLowerCase();
            }
            return singleBetslipOddStatus.PENDING.toLowerCase();
        },
        formattedMarketName() {
            const { selection } = this.odd;
            const { text: hcp, type: handicapType } = selection.market.handicap;
            const { name: priceName } = selection;
            const { name: marketName, groupName, displayName, groupedName } = selection.market;
            const { name: eventName } = selection.event;
            return this.$formulateMarketName({
                hcp,
                priceName,
                marketName,
                displayName,
                groupName,
                handicapType,
                eventName,
                groupedName,
            });
        },
        isInPlay() {
            return this.inPlayDetails.startCounter;
        },
        isMatchScore() {
            return this.isInPlay && this.inPlayDetails.matchScore;
        },
        formattedMatchScore() {
            const { matchScore } = this.inPlayDetails;
            return `${matchScore.scoreHome} - ${matchScore.scoreAway}`;
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
        $formatDateTime: helper.formatDateTime,
        $formulateMarketName: helper.formulateMarketName,
        $getObjectField: helper.getObjectField,
        $isEventLive: isEventLive,
    },
};
</script>

<style lang="scss" scoped>
.inplay-countdown {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-text {
        color: #ff7a00;
        font-size: 14px;
        font-weight: bold;
        border-radius: 0;
    }
}

.event {
    @extend %small-details-font-400;
    border-bottom: $single-betslip-border;
    color: $main-text;
    padding: 8px;
    position: relative;

    &-live::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: $event-live-status-color;
    }

    &-label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }

    &-badge {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;

        .badge-result {
            margin: 0;
        }

        .icon {
            fill: #aaaeb0;
            position: absolute;
        }
    }

    .event-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &.event-header {
            @extend %body-normal-font-400;
            align-items: center;

            .date {
                font-weight: bold;
            }

            .odd {
                align-items: center;
                display: flex;
                font-weight: bold;

                &.win {
                    color: $green-success;
                }

                &.lose {
                    color: $light-red;
                }
            }

            .badge {
                margin-right: 0;
            }

            .badge.mode-locked {
                margin-right: 10px;
            }
        }

        .label {
            margin-right: 14px;
            .league {
                color: $disabled-text;
            }
        }

        .icon-live {
            fill: $event-live-status-color;
            margin-right: 2px;
            transform: translateY(-6%);
        }

        .result {
            @extend %body-normal-font-700;
            white-space: nowrap;

            &.inplay {
                color: $orange;
            }
        }

        .icon-link {
            height: 12px;
            width: 12px;
            margin-left: 6px;
        }
    }
}

.cancellation-badge {
    display: flex;
    align-items: center;

    &-label {
        background-color: $medium-grey;
        color: $main-text;
        padding: 2px 4px;
        max-width: 110px;
    }

    .icon-info {
        cursor: pointer;
        margin-left: 10px;
    }
}

.match-link {
    &:hover {
        color: inherit;
    }
}

.cancellation-info {
    top: 16px;
}
</style>
