<template>
    <div class="campaign-message" v-if="isVisible">
        <div class="campaign-message-icon">
            <SvgIcon class="icon-size-large" icon-id="icon-gift" verticalAlign="text-top" />
        </div>
        <div class="campaign-message-content">
            <p class="text bold">
                {{
                    $t('ui.campaignMessage.refundStakeBack', {
                        amount: $options.filters.currency(Number(campaign.campaignAmount), currency.format, 0),
                    })
                }}
            </p>
            <p class="text-small">
                {{ $t('ui.campaignMessage.validUntil', { date: expiredDate }) }}
                <router-link class="underline" :to="{ path: '/rfb-rules' }" @click.native="clickReadMore">
                    {{ $t('ui.betslip.readMore') }}.
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getter as platformGetter } from '@agi.packages/platform';
import helper from '@/modules/core/utils/helper';

export default {
    name: 'CampaignMessage',
    props: {
        campaign: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS,
        }),
        expiredDate() {
            return (
                helper.formatDateTime(new Date(this.campaign.eligibleUntil), {
                    ...this.dateOptions,
                    needTime: false,
                    isUtc: true,
                }) + `/${new Date(this.campaign.eligibleUntil).getFullYear()}`
            );
        },
        isVisible() {
            return this.campaign && this.campaign?.campaignAmount && this.campaign?.eligibleUntil;
        },
    },
    methods: {
        clickReadMore() {
            this.$gtm.query({ event: 'risk_free_bet_read_more_click' });
        },
    },
};
</script>

<style scoped lang="scss">
.campaign-message {
    padding: 12px;
    display: flex;
    align-items: flex-start;
    background-color: $message-success;

    &-content {
        color: $main-text;

        .amount {
            color: $main-text;
            display: inline;
        }
    }

    &-icon {
        margin-right: 8px;
    }

    .text-small {
        a:hover {
            color: inherit;
            opacity: 0.7;
        }
    }
}
</style>
