<template>
    <div>
        <!-- CashOut Message-->
        <!-- For cashOut v1 Compatibility `requestOfferError.message` is needed inside `if` -->
        <div class="request-offer" :class="getRequestOfferWrapperClass">
            <div class="offer-message" :class="{ 'row-cell align-middle': isPresto }">
                <p v-if="isNetworkError">
                    {{ $t('ui.common.error.networkError') }}
                </p>
                <Spinner
                    v-else-if="showCashoutInProgress && !isCashOutRefreshing && isOldCashOut"
                    visible
                    class="justify-center"
                    type="bp-spinner"
                >
                    {{ offerInProgressMessage }}
                </Spinner>
                <div v-else-if="showReloadCashoutStatus">
                    {{ offerInProgressMessage }}
                    <Currency
                        :contrast="false"
                        class="offer-amount"
                        :amount="cashOutOffer"
                        :format="settings.currency"
                        data-test-id="cashoutOffer"
                    />
                </div>
                <p v-else-if="isCashOutLocked">
                    <renderer :input="$t('ui.cashout.cashoutLocked')" />
                </p>
                <p v-else-if="requestOfferError.message">
                    <template v-if="isGenericError">
                        {{ $t('ui.common.error.genericError') }}
                    </template>
                    <template v-else>
                        <renderer :input="requestOfferError.message" />
                    </template>
                </p>
                <div v-else-if="!isNetworkError && isCashoutOfferValid && isConfirmCashOutReady" class="cashout-offer">
                    <p>{{ $t('ui.cashout.cashoutOffer') }}</p>
                    <Currency
                        :contrast="false"
                        class="offer-amount"
                        :amount="cashOutOffer"
                        :format="settings.currency"
                        data-test-id="cashoutOffer"
                        v-bind="{ ...isCashOutOfferCurrencyLoading }"
                    />
                </div>
                <p v-else>
                    <SvgIcon class="cashout-icon icon-size-medium" iconId="icon-cashoutable"></SvgIcon>
                    {{ $t('ui.cashout.cashout') }}
                </p>
            </div>

            <!-- CashOut Actions-->
            <div :class="{ 'row-cell align-middle right': isPresto, 'offer-action-full': shouldGetOfferUpdate }">
                <button v-if="showReloadCashoutStatus" @click="reloadCashoutStatus()" class="button button-primary offer-button">
                    {{ $t('ui.cashout.confirmationReload') }}
                </button>
                <button v-else-if="shouldGetOfferUpdate" disabled class="button button-primary offer-button button-full countdown-button">
                    <Countdown
                        v-if="!isPresto"
                        class="request-offer-countdown"
                        theme="number"
                        :start="startCounter"
                        :refreshRate="countdownRate"
                    />
                    <span>{{ $t('ui.cashout.retrievingUpdate') }}</span>
                </button>
                <PromptAction
                    v-else-if="showConfirmCashout"
                    :disabled="confirmOfferIsLoading || !!pollingCounter"
                    @leftResolvePrompt="promptLeftBtnAction"
                    @rightResolvePrompt="promptRightBtnAction"
                    :class="{ 'offer-button offer-button-prompt-action': $mq.isXSmall }"
                    :left="{
                        text: $t('ui.cashout.acceptOffer'),
                        color: 'primary',
                        buttonCssClass: isPresto ? 'button-very-small button-presto row-cell' : '',
                        ...promptActionDisabledState.leftAction,
                    }"
                    :right="{
                        isLoading: isCashOutRefreshing,
                        icon: isOldCashOut ? 'icon-close' : 'icon-reload',
                        iconCssClass: isOldCashOut ? 'icon-size-small icon-white-color' : 'icon-size-small',
                        buttonCssClass: promptRightBtnClasses,
                        ...promptActionDisabledState.rightAction,
                    }"
                ></PromptAction>
                <Button v-else :disabled="disableRequestCashout" class="button button-accent offer-button" @click="requestOffer()">
                    {{ requestCashoutButtonText }}
                </Button>
            </div>
        </div>

        <!-- CashOut Accept Modal -->
        <Modal name="cashout-modal" class="cashout-modal" :options="{ overlay: true }" width="312px" :fixed="true" @close="closeModal">
            <div class="title">{{ $t('ui.cashout.cashOutNowModalTitle') }}</div>
            <p class="description">
                <renderer
                    :input="
                        $t('ui.cashout.cashOutNowModalDescription', {
                            newOffer: numberFormat(cashOutOffer),
                            oldOffer: acceptableOffer,
                            currency: currencySymbol,
                        })
                    "
                />
            </p>
            <Button class="button button-primary button-full button-accept-cashout" @click="confirmOffer">
                {{ $t('ui.cashout.acceptOffer') }}
            </Button>
            <button class="button button-accent button-full" @click="closeModal">
                {{ $t('ui.cashout.cancelOffer') }}
            </button>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { sport } from '@agi.packages/sport';

import { deviceType, getter as coreGetter } from '@/modules/core';
import { getter as platformGetter } from '@/modules/platform';
import Countdown from '@/components/Countdown.vue';
import { Currency, PromptAction } from '@/modules/core/components';
import Button from '@/components/Button.vue';
import { getObjectField, numberFormat } from '@/modules/core/utils/helper';

const CASHOUT_MODAL_DISPLAY_DURATION = 60000;

export default {
    name: 'BaseCashOut',
    components: { PromptAction, Button, Currency, Countdown },
    props: {
        isOldCashOut: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            startCounter: new Date(),
            cashoutTimeout: null,
            cashOutModalTimeOutId: null,
        };
    },
    mounted() {
        if (!this.isOldCashOut) {
            this.requestOffer();
        }
    },
    computed: {
        ...mapState({
            betslip: (state) => state.sport.myBets.betslip,
            offerPollingIsEnabled: (state) => state.sport.myBets.offerPolling.pollingEnabled,
            isNetworkError: (state) => state.sport.myBets.offerError.isNetworkError,
            pollingCounter: (state) => state.sport.myBets.offerStatus.pollingCounter,
            initialRequestLimit: (state) => state.sport.myBets.offerPolling.initialRequestLimit,
            pollingSeconds: (state) => state.sport.myBets.offerPolling.pollingSeconds,
            offerStatus: (state) => state.sport.myBets.offerStatus,
            cashoutStatusId: (state) => state.sport.myBets.offerStatus.statusId,
            currencySymbol: (state) => state.platform.settings.currency.symbol,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            settings: platformGetter.GET_SETTINGS,
            autoCashOut: sport.getter.GET_AUTO_CASHOUT_OFFER,
            requestOfferError: sport.getter.GET_CASHOUT_OFFER_ERRORS,
            isConfirmCashOutReady: sport.getter.GET_IS_CONFIRM_CASHOUT_READY,
            isCashOutRefreshing: sport.getter.GET_IS_CASHOUT_REFRESHING,
            cashOutAvailabilityTime: platformGetter.GET_CASHOUT_AVAILABILITY_TIME,
        }),
        isCashOutOfferCurrencyLoading() {
            return (!this.isOldCashOut && { isLoading: this.showCashoutInProgress || this.requestOfferIsLoading }) || {};
        },
        acceptableOffer() {
            return numberFormat(getObjectField(this.autoCashOut, 'acceptableOffer', 0));
        },
        promptRightBtnClasses() {
            return this.isPresto ? 'button-very-small row-cell' : this.isOldCashOut ? 'button-small' : 'button-small button-refresh';
        },
        cashOutOffer() {
            return getObjectField(this.betslip, 'cashout.payout.net');
        },
        countdownRate() {
            return this.pollingSeconds * this.initialRequestLimit;
        },
        isCashoutOfferValid() {
            return !!(this.betslip?.cashout?.offerId && this.cashOutOffer);
        },
        showConfirmCashout() {
            return this.isCashoutOfferValid && !this.requestOfferError.message && this.isConfirmCashOutReady;
        },
        disableRequestCashout() {
            return this.requestOfferError.dead || this.requestOfferIsLoading;
        },
        isGenericError() {
            return this.requestOfferError.message && this.requestOfferError.isGenericError;
        },
        showReloadCashoutStatus() {
            return this.isPresto && this.pollingCounter;
        },
        requestOfferIsLoading() {
            return this.isLoading(sport.action.REQUEST_CASHOUT_OFFER);
        },
        confirmOfferIsLoading() {
            return this.isLoading(sport.action.CONFIRM_CASHOUT_OFFER);
        },
        isCashOutLocked() {
            return this.offerPollingIsEnabled && !this.requestOfferError.dead;
        },
        shouldGetOfferUpdate() {
            return this.offerPollingIsEnabled && !this.requestOfferError.dead && !this.requestOfferError.isNetworkError;
        },
        promptActionDisabledState() {
            return {
                leftAction: (!this.isOldCashOut && { disabled: this.isCashOutRefreshing || this.showCashoutInProgress }) || {},
                rightAction: (!this.isOldCashOut && { disabled: !this.isCashOutRefreshing && this.showCashoutInProgress }) || {},
            };
        },
        showCashoutInProgress() {
            return (
                (this.requestOfferIsLoading || this.confirmOfferIsLoading || this.pollingCounter) &&
                !this.showReloadCashoutStatus &&
                !this.isNetworkError
            );
        },
        offerInProgressMessage() {
            if (this.pollingCounter && !this.isPresto) {
                return this.$t('ui.cashout.confirmationStatusProgress', { seconds: this.pollingCounter });
            }
            if (this.showReloadCashoutStatus) {
                return this.$t('ui.cashout.confirmationStatusCheckReload', { seconds: this.pollingCounter });
            }
            return this.$t('ui.cashout.processingOffer');
        },
        requestCashoutButtonText() {
            if (!this.requestOfferError.dead) {
                return this.requestOfferError.isLockedError ? this.$t(`ui.common.tryAgain`) : this.$t(`ui.cashout.requestOffer`);
            }
            return this.$t('ui.cashout.buttonCashoutUnavailable');
        },
        getRequestOfferWrapperClass() {
            const isError = this.requestOfferError.message || this.requestOfferError.isNetworkError;
            return [
                isError &&
                    !this.requestOfferError.isLockedError &&
                    (!this.requestOfferError.dead || this.requestOfferError.isNetworkError ? 'warn' : 'error'),
                this.isPresto && 'table',
                this.shouldGetOfferUpdate ? 'request-offer-update' : '',
            ];
        },
    },
    watch: {
        'betslip.cashout'(cashout) {
            const timerNotStarted = !this.cashoutTimeout && this.cashOutAvailabilityTime;
            const timerCanStart = this.isConfirmCashOutReady || !cashout;
            if (timerNotStarted && timerCanStart) {
                const secondsLeft = this.cashOutAvailabilityTime * 1000;
                clearTimeout(this.cashoutTimeout);
                this.cashoutTimeout = setTimeout(() => {
                    this.cashoutTimeout = null;
                    this.getSingleBetslip(this.betslip.id).then(() => {
                        this.setIsConfirmCashOutReady(false);
                    });
                }, secondsLeft);
            }
        },
        offerStatus({ confirmed }) {
            if (confirmed) {
                this.refreshBetslip();
            }
        },
        requestOfferError(requestOfferError) {
            if (this.isOldCashOut && requestOfferError.refresh) {
                this.refreshBetslip();
            }
        },
    },
    methods: {
        numberFormat,
        ...mapActions({
            requestCashoutOffer: sport.action.REQUEST_CASHOUT_OFFER,
            getSingleBetslip: sport.action.GET_SINGLE_BETSLIP,
            checkCashoutOfferStatus: sport.action.CHECK_CASHOUT_OFFER_STATUS,
            confirmCashoutOffer: sport.action.CONFIRM_CASHOUT_OFFER,
        }),
        ...mapMutations({
            setIsConfirmCashOutReady: sport.mutation.SET_IS_CONFIRM_CASHOUT_READY,
            setIsCashOutRefreshing: sport.mutation.SET_IS_CASHOUT_REFRESHING,
        }),
        cancelOffer() {
            this.trackCashOutOfferRequest('cancel');
            this.refreshBetslip();
        },
        promptLeftBtnAction() {
            return this.isOldCashOut ? this.confirmOffer() : this.acceptOffer();
        },
        promptRightBtnAction() {
            return this.isOldCashOut ? this.cancelOffer() : this.refreshOfferRequest();
        },
        closeModal() {
            clearTimeout(this.cashOutModalTimeOutId);
            this.$modal.hide('cashout-modal');
        },
        refreshBetslip() {
            clearTimeout(this.cashoutTimeout);
            this.cashoutTimeout = null;
            this.getSingleBetslip(this.betslip.id);
        },
        reloadCashoutStatus() {
            this.checkCashoutOfferStatus({
                statusId: this.cashoutStatusId,
                betslipId: this.betslip.id,
                amount: this.cashOutOffer,
            });
        },
        refreshOfferRequest() {
            if (this.isCashOutRefreshing) return;
            this.requestOffer(true);
            this.trackCashOutOfferRequest('refresh');
        },
        confirmOffer() {
            this.trackCashOutOfferRequest('confirm');
            clearTimeout(this.cashoutTimeout);
            this.confirmCashoutOffer({
                betslipId: this.betslip.id,
                offerId: this.betslip.cashout.offerId,
                amountNet: this.cashOutOffer,
            });
            if (!this.isOldCashOut) {
                this.trackCashOutOfferRequest('accept_offer_when_auto_cashout_already_set');
            }
        },
        acceptOffer() {
            if (this.autoCashOut.enabled) {
                this.$modal.show('cashout-modal');
                this.cashOutModalTimeOutId = setTimeout(() => {
                    this.$modal.hide('cashout-modal');
                }, CASHOUT_MODAL_DISPLAY_DURATION);
            } else {
                this.confirmOffer();
            }
        },
        requestOffer(isRefresh = false) {
            this.setIsConfirmCashOutReady(true);

            if (this.isOldCashOut) {
                this.requestCashoutOffer({
                    betslipId: this.betslip.id,
                    amount: this.betslip.stake,
                });
            } else {
                this.setIsCashOutRefreshing(isRefresh);
                this.requestCashoutOffer({
                    betslipId: this.betslip.id,
                    amount: this.betslip.stake,
                }).then(() => {
                    this.setIsCashOutRefreshing(false);
                });
            }
            this.trackCashOutOfferRequest();
        },
        trackCashOutOfferRequest(type = '') {
            this.$gtm.query({
                event: 'cashout_offer',
                betslipId: this.betslip.id,
                userId: getObjectField(this.user, 'userUuid'),
                click: type || (this.requestOfferError.isLockedError ? 'request-again' : 'request'),
            });
        },
    },
    beforeDestroy() {
        clearTimeout(this.cashOutModalTimeOutId);
        clearTimeout(this.cashoutTimeout);
        this.$store.commit(sport.mutation.UPDATE_CASHOUT_POLLING, {
            pollingEnabled: false,
            requestLimit: 0,
        });
    },
};
</script>

<style scoped lang="scss">
.offer-action-full {
    width: 100%;
}
.request-offer {
    @extend %body-normal-font-400;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .offer-amount {
        height: 17px;
    }

    .offer-message {
        flex: 1;
        font-weight: normal;
        margin-right: 10px;

        p {
            @extend %body-normal-font-500;
        }

        .spinner {
            line-height: initial;
        }

        .cashout-icon {
            fill: $cashout-offer-icon-color;
            margin: -3px 8px 0;
        }
    }
    .offer-amount {
        color: $cashout-offer-amount-color;
        font-weight: bold;
    }
    .offer-button {
        @extend %body-normal-font-700;
        word-break: break-word;
        flex-basis: content;
    }

    .offer-button-prompt-action {
        flex: none;
    }
    @include only_mini {
        align-items: flex-start;
        flex-direction: column;

        .offer-button {
            width: 100%;
        }

        ::v-deep .button-media {
            display: block;
            width: 14px;
            height: 14px;
        }
        ::v-deep .button-media svg {
            position: relative;
            top: -5px;
        }
    }
    ::v-deep .button-presto {
        width: 77%;

        @include oldschool {
            width: 100%;
        }
    }
    &-update {
        flex-direction: column;
        align-items: flex-start;
        border-color: $golden-brown;

        .offer-message {
            font-weight: 400;
            margin-bottom: 8px;
        }
    }

    .countdown-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 20px;
    }

    &-countdown {
        display: block;
        margin-right: 8px;
        white-space: nowrap;
    }
}

// modal
.cashout-modal {
    .title {
        @extend %h4-font-700;
        text-align: center;
        color: $grey-text;
        margin-bottom: 8px;
    }

    .description {
        @extend %body-normal-font-400;
        text-align: center;
        color: $grey-text;
        margin-bottom: 24px;
    }

    .button-accept-cashout {
        margin-bottom: 8px;
    }
}

.table {
    display: table;
}
</style>
