<template>
    <div class="pj-winners">
        <Spinner v-if="isFetching" :visible="true" class="align-center inset" />
        <ProgressiveJpResultsTable v-else :winners="winners" :title="data.content.title" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { action as platformAction, getter as platformGetter } from '@agi.packages/platform';
import { getter as coreGetter, helper } from '@agi.packages/core';
import ProgressiveJpResultsTable from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpResultsTable.vue';

const defaultPayloadTake = 3;

export default {
    components: { ProgressiveJpResultsTable },
    props: ['data'],
    computed: {
        ...mapGetters({
            progressiveJackpots: platformGetter.GET_PROGRESSIVE_JP,
            isLoading: coreGetter.IS_LOADING,
        }),
        winners() {
            return helper.getObjectField(this.progressiveJackpots[this.data.content.type], 'winners', []);
        },
        isFetching() {
            return this.isLoading(platformAction.GET_PROGRESSIVE_JACKPOT_WINNERS);
        },
    },
    methods: {
        ...mapActions({
            getProgressiveJackpotWinners: platformAction.GET_PROGRESSIVE_JACKPOT_WINNERS,
        }),
    },
    mounted() {
        this.getProgressiveJackpotWinners({ take: this.data.content.take || defaultPayloadTake });
    },
};
</script>

<style lang="scss" scoped>
.pj-winners {
    .spinner {
        padding: 0;
    }
}
</style>
