/**
 * @deprecated use MARKET_TYPE_GROUPING from market-const
 */
export const MARKET_TYPE_GROUPING = {
    2: [
        { K: '_1X2', T: '_1X2' },
        { K: 'OU_FT', T: 'OU' },
        { K: 'BTTS_FT', T: 'BTTS' },
        { K: 'DOUBLE_CHANCE_FT', T: 'DC' },
        { K: 'HT_FT', T: 'HT_FT' },
    ],
    3: [
        { K: 'MONEYLINE_FT_OT', T: 'ML' },
        { K: 'OU_FT_OT', T: 'OU' },
        { K: 'ODD_EVEN_FT_OT', T: 'OE' },
        { K: 'TWO_WAY_HCAP_OT', T: 'HCAP' },
    ],
    452: [
        { K: 'MONEYLINE_FT', T: 'ML' },
        { K: 'GAMES_OU', T: 'OU' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    453: [
        { K: '_1X2', T: '_1X2' },
        { K: 'OU_FT', T: 'OU' },
        { K: 'ODD_EVEN', T: 'OE' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    454: [{ K: 'MONEYLINE_FT_OT', T: 'ML' }],
    458: [
        { K: '_1X2', T: '_1X2' },
        { K: 'OU_FT', T: 'OU' },
        { K: 'ODD_EVEN', T: 'OE' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    459: [
        { K: 'MONEYLINE_FT_OT', T: 'ML' },
        { K: 'OU_FT_OT', T: 'OU' },
        { K: 'TWO_WAY_HCAP_OT', T: 'HCAP' },
    ],
    460: [{ K: 'OU_FT', T: 'OU' }],
    461: [
        { K: 'MONEYLINE_FT', T: 'ML' },
        { K: 'POINTS_OU', T: 'OU' },
        { K: 'ODD_EVEN_POINTS', T: 'OE' },
        { K: 'TWO_WAY_HCAP', T: 'HCAP' },
    ],
    462: [{ K: '_1X2', T: '_1X2' }],
    463: [{ K: 'MONEYLINE_FT', T: 'ML' }],
    464: [
        { K: 'MONEYLINE_FT_OT', T: 'ML' },
        { K: 'OU_FT_OT', T: 'OU' },
        { K: 'ODD_EVEN_FT_OT', T: 'OE' },
        { K: 'TWO_WAY_HCAP_OT', T: 'HCAP' },
    ],
};

export const CATEGORY_MAP = [
    { name: 'football', key: 2, markets: ['1', 'X', '2'] },
    { name: 'basketball', key: 3, markets: ['1', '2'] },
    { name: 'tennis', key: 452, markets: ['1', '2'] },
    { name: 'rugby', key: 453, markets: ['1', 'X', '2'] },
    { name: 'boxing', key: 454, markets: ['1', 'X', '2'] },
    { name: 'motorsport', key: 455, markets: ['1'] },
    { name: 'chess', key: 456, markets: ['1', '2'] },
    { name: 'rugby-league', key: 458, markets: ['1', 'X', '2'] },
    { name: 'american-football', key: 459, markets: ['1', '2'] },
    { name: 'volleyball', key: 461, markets: ['1', '2'] },
    { name: 'cricket', key: 462, markets: ['1', '2'] },
    { name: 'table-tennis', key: 463, markets: ['1', '2'] },
    { name: 'ice-hockey', key: 464, markets: ['1', 'X', '2'] },
];
