const MESSAGES_MENU_ITEM = 'messages';

const processMenu = {
    processDefaultItemBadge: ({ item, newMessagesNumber }) => {
        if (item.name?.toLowerCase() === MESSAGES_MENU_ITEM && newMessagesNumber) {
            item.badge = newMessagesNumber.toString();
            item.badgeType = 'new-messages';
        }

        return item;
    },
};

export default processMenu;
