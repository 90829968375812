export const casinoType = {
    CASINO: {
        path: 'casino',
        seoTemplate: 'casino',
    },
    GAMES: {
        path: 'games',
        seoTemplate: 'games',
    },
    CASINOGAMES: {
        path: 'casino',
        seoTemplate: 'casinoGames',
    },
};

export const countryName = {
    KE: 'Kenya',
    ZM: 'Zambia',
    UG: 'Uganda',
    TZ: 'Tanzania',
    GH: 'Ghana',
    NG: 'Nigeria',
    ZW: 'Zimbabwe',
    CD: 'DR Congo',
    CM: 'Cameroon',
    RW: 'Rwanda',
    SN: 'Senegal',
    MWI: 'Malawi',
    BJ: 'Benin',
    SL: 'Sierra Leone',
    AO: 'Angola',
    MZ: 'Mozambique',
    CI: 'Ivory Coast',
    CG: 'Congo Brazzaville',
    LS: 'Lesotho',
    GA: 'Gabon',
};

/**
 * @deprecated in the future, this object will need to be completely abandoned,
 * but in the near future, after switching to brandPreference, it will be possible
 * to leave only some of the fields that are needed even before the components-data endpoint request
 * (for example, in the router or in the http plugin constructor)
 */
export const BETPAWA = {
    ke: {
        brandIdentifier: 'betpawa-kenya',
        brand: 'betPawa',
        iso: 'KE',
        brandName: 'betPawa.co.ke',
        countryName: countryName.KE,
        country: 'KE',
        countryCode: 'ke',
        capital: 'Nairobi County',
        id: 5,
        hreflang: 'en-KE',
        phonePrefix: 254,
        regionId: 37,
        pin: true,
        currency: {
            code: 'KES',
        },
        casinoType: casinoType.CASINO,
    },
    zm: {
        brandIdentifier: 'betpawa-zambia',
        brand: 'betPawa',
        iso: 'ZM',
        brandName: 'betPawa.co.zm',
        countryName: countryName.ZM,
        country: 'ZM',
        countryCode: 'zm',
        capital: 'Lusaka',
        id: 7,
        hreflang: 'en-ZM',
        phonePrefix: 260,
        regionId: 50,
        currency: {
            code: 'ZMW',
        },
        casinoType: casinoType.CASINO,
    },
    ug: {
        brandIdentifier: 'betpawa-uganda',
        brand: 'betPawa',
        iso: 'UG',
        brandName: 'betPawa.ug',
        countryName: countryName.UG,
        country: 'UG',
        countryCode: 'ug',
        capital: 'Kampala',
        id: 3,
        nationality: 'Ugandan',
        hreflang: 'en-UG',
        phonePrefix: 256,
        regionId: 48,
        currency: {
            code: 'UGX',
        },
        casinoType: casinoType.CASINO,
    },
    tz: {
        brandIdentifier: 'betpawa-tanzania',
        brand: 'betPawa',
        iso: 'TZ',
        brandName: 'betPawa.co.tz',
        countryName: countryName.TZ,
        country: 'TZ',
        countryCode: 'tz',
        capital: 'Dar es Salaam',
        id: 6,
        hreflang: 'en-TZ',
        phonePrefix: 255,
        regionId: 49,
        currency: {
            code: 'TZS',
        },
        casinoType: casinoType.CASINO,
    },
    gh: {
        brandIdentifier: 'betpawa-ghana',
        brand: 'betPawa',
        iso: 'GH',
        brandName: 'betPawa.com.gh',
        countryName: countryName.GH,
        country: 'GH',
        countryCode: 'gh',
        capital: 'Accra',
        id: 8,
        nationality: 'Ghanaian',
        hreflang: 'en-GH',
        phonePrefix: 233,
        regionId: 531,
        currency: {
            code: 'GHS',
            symbolFallback: 'GHc',
        },
        casinoType: casinoType.CASINO,
    },
    ng: {
        brandIdentifier: 'betpawa-nigeria',
        brand: 'betPawa',
        iso: 'NG',
        brandName: 'betPawa.ng',
        countryName: countryName.NG,
        country: 'NG',
        countryCode: 'ng',
        capital: 'Lagos',
        id: 4,
        hreflang: 'en-NG',
        phonePrefix: 234,
        regionId: 85,
        currency: {
            code: 'NGN',
            symbolFallback: 'N',
        },
        casinoType: casinoType.CASINO,
    },
    zw: {
        brandIdentifier: 'betpawa-zimbabwe',
        brand: 'betPawa',
        iso: 'ZW',
        brandName: 'betPawa.co.zw',
        countryName: countryName.ZW,
        country: 'ZW',
        countryCode: 'zw',
        capital: 'Harare',
        id: 10,
        hreflang: 'en-ZW',
        phonePrefix: 263,
        regionId: 51,
        currency: {
            code: 'ZWL',
            symbolFallback: 'RTGS',
        },
        casinoType: casinoType.CASINO,
    },
    cd: {
        brandIdentifier: 'betpawa-drc',
        brand: 'betPawa',
        iso: 'CD',
        brandName: 'betPawa.cd',
        countryName: countryName.CD,
        countryAbbreviation: 'DRC',
        country: 'CD',
        countryCode: 'cd',
        capital: 'Kinshasa',
        id: 12,
        hreflang: 'en-CD',
        phonePrefix: 243,
        regionId: 57,
        pin: true,
        currency: {
            code: 'CDF',
        },
        casinoType: casinoType.CASINO,
    },
    cm: {
        brandIdentifier: 'betpawa-cameroon',
        brand: 'betPawa',
        iso: 'CM',
        brandName: 'betPawa.cm',
        countryName: countryName.CM,
        country: 'CM',
        countryCode: 'cm',
        capital: 'Yaoundé',
        id: 9,
        hreflang: 'en-CM',
        phonePrefix: 237,
        regionId: 53,
        pin: true,
        currency: {
            code: 'XAF',
        },
        casinoType: casinoType.CASINO,
        age: 21,
    },
    rw: {
        brandIdentifier: 'betpawa-rwanda',
        brand: 'betPawa',
        iso: 'RW',
        brandName: 'betpawa.rw',
        countryName: countryName.RW,
        country: 'RW',
        countryCode: 'rw',
        capital: 'Kigali',
        id: 14,
        hreflang: 'en-RW',
        phonePrefix: 250,
        regionId: 44,
        pin: true,
        currency: {
            code: 'RWF',
        },
        casinoType: casinoType.CASINO,
    },
    sn: {
        brandIdentifier: 'betpawa-senegal',
        brand: 'betPawa',
        iso: 'SN',
        brandName: 'betpawa.sn',
        countryName: countryName.SN,
        country: 'SN',
        countryCode: 'sn',
        capital: 'Dakar',
        id: 15,
        hreflang: 'en-SN',
        pin: true,
        phonePrefix: 221,
        regionId: 87,
        currency: {
            code: 'XOF',
        },
        casinoType: casinoType.CASINO,
    },
    mw: {
        brandIdentifier: 'betpawa-malawi',
        brand: 'betPawa',
        iso: 'MWI',
        brandName: 'betpawa.mw',
        countryName: countryName.MWI,
        country: 'MWI',
        countryCode: 'mw',
        capital: 'Lilongwe',
        id: 17,
        hreflang: 'en-MW',
        phonePrefix: 265,
        regionId: 531,
        pin: true,
        currency: {
            code: 'MWK',
        },
        casinoType: casinoType.CASINOGAMES,
        age: 18,
    },
    bj: {
        brandIdentifier: 'betpawa-benin',
        brand: 'betPawa',
        iso: 'BJ',
        brandName: 'betpawa.bj',
        countryName: countryName.BJ,
        country: 'BJ',
        countryCode: 'bj',
        capital: 'Porto-Novo',
        id: 19,
        pin: true,
        nationality: 'Beninese',
        hreflang: 'en-BJ',
        phonePrefix: 229,
        regionId: 73,
        currency: {
            code: 'XOF',
        },
        casinoType: casinoType.CASINO,
    },
    sl: {
        brandIdentifier: 'betpawa-sierraleone',
        brand: 'betPawa',
        iso: 'SL',
        brandName: 'betpawa.sl',
        countryName: countryName.SL,
        country: 'SL',
        countryCode: 'sl',
        capital: 'Freetown',
        id: 20,
        pin: true,
        nationality: 'Sierra Leonean',
        hreflang: 'en-SL',
        phonePrefix: 232,
        regionId: 88,
        currency: {
            code: 'SLL',
        },
        casinoType: casinoType.CASINO,
    },
    ao: {
        brandIdentifier: 'betpawa-angola',
        brand: 'betPawa',
        iso: 'AO',
        brandName: 'betpawa.co.ao',
        countryName: countryName.AO,
        country: 'AO',
        countryCode: 'ao',
        capital: 'Luanda',
        id: 21,
        pin: true,
        nationality: 'Angolan',
        hreflang: 'en-AO',
        phonePrefix: 244,
        regionId: 52,
        currency: {
            code: 'AOA',
        },
        casinoType: casinoType.CASINO,
    },
    mz: {
        brandIdentifier: 'betpawa-mozambique',
        brand: 'betPawa',
        iso: 'MZ',
        brandName: 'betpawa.co.mz',
        countryName: countryName.MZ,
        country: 'MZ',
        countryCode: 'mz',
        capital: 'Maputo',
        id: 22,
        pin: true,
        nationality: 'Mozambican',
        hreflang: 'en-MZ',
        phonePrefix: 258,
        regionId: 42,
        currency: {
            code: 'MZN',
        },
        casinoType: casinoType.CASINO,
    },
    ci: {
        brandIdentifier: 'betpawa-ivorycoast',
        brand: 'betPawa',
        iso: 'CI',
        brandName: 'betpawa.ci',
        countryName: countryName.CI,
        country: 'CI',
        countryCode: 'ci',
        capital: 'Yamoussoukro',
        id: 23,
        pin: true,
        nationality: 'Ivorian',
        hreflang: 'en-CI',
        phonePrefix: 255,
        regionId: 76,
        currency: {
            code: 'XOF',
        },
        casinoType: casinoType.CASINO,
    },
    cg: {
        brandIdentifier: 'betpawa-congobrazzaville',
        brand: 'betPawa',
        iso: 'CG',
        brandName: 'betpawa.cg',
        countryName: countryName.CG,
        country: 'CG',
        countryCode: 'cg',
        capital: 'Brazzaville',
        id: 24,
        pin: true,
        nationality: 'Congolese',
        hreflang: 'en-CG',
        phonePrefix: 242,
        regionId: 56,
        currency: {
            code: 'XAF',
        },
        casinoType: casinoType.CASINO,
    },
    ls: {
        brandIdentifier: 'betpawa-lesotho',
        brand: 'betPawa',
        iso: 'LS',
        brandName: 'betpawa.ls',
        countryName: countryName.LS,
        country: 'LS',
        countryCode: 'ls',
        capital: 'Maseru',
        id: 25,
        pin: true,
        nationality: 'Mosotho',
        hreflang: 'en-LS',
        phonePrefix: 266,
        regionId: 69,
        currency: {
            code: 'LSL',
        },
        casinoType: casinoType.CASINO,
    },
    ga: {
        brandIdentifier: 'betpawa-gabon',
        brand: 'betPawa',
        iso: 'GA',
        brandName: 'betpawa.ga',
        countryName: countryName.GA,
        country: 'GA',
        countryCode: 'ga',
        capital: 'Libreville',
        id: 26,
        pin: true,
        nationality: 'Gabonese',
        hreflang: 'en-GA',
        phonePrefix: 241,
        regionId: 59,
        currency: {
            code: 'XAF',
        },
        casinoType: casinoType.CASINO,
    },
};

export const JURISDICTION_MAP = [].concat(Object.values(BETPAWA));

export const JUR_LIST = (function () {
    switch (process.env.VUE_APP_PROJECT_ID) {
        case 'betpawa':
            return BETPAWA;
        default:
            return BETPAWA;
    }
})();

export function findJurisdiction(hostname = location.hostname) {
    const splitHost = hostname.split('.');
    const jurList = Object.values(JUR_LIST);
    return jurList.find((jur) => splitHost.includes(jur.countryCode));
}
