var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deposit-form"},[_c('Spinner',{staticClass:"inset",attrs:{"visible":_vm.fetchingDetails}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelect),expression:"showSelect"}],staticClass:"deposit-header"},[_c('SvgIcon',{staticClass:"icon-deposit",attrs:{"iconId":"icon-deposit-menu","verticalAlign":"top"}}),_c('span',{staticClass:"deposit-header-title"},[_vm._v(_vm._s(_vm.$t('ui.payment.deposit.title')))]),_c('span',{staticClass:"deposit-close",on:{"click":_vm.onCloseClick}},[_c('SvgIcon',{staticClass:"icon-deposit-close icon-size-very-small",attrs:{"iconId":"icon-close"}})],1)],1),(!_vm.fetchingDetails)?_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.deposit.apply(null, arguments)}}},[_c('SelectField',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelect),expression:"showSelect"}],staticClass:"select-method",attrs:{"options":_vm.selectOptions,"selectedValue":_vm.initialOption,"v":_vm.$v.selectActiveOption,"errorMessages":_vm.selectErrorMessages,"attrs":{ disabled: _vm.isInitialized || _vm.inProgress }},on:{"input":_vm.onSelectChange}}),(!_vm.errorText)?[_vm._t("amount",function(){return [(_vm.errorMessage)?_c('div',{staticClass:"notify error"},[_c('renderer',{attrs:{"input":_vm.errorMessage}})],1):_vm._e(),(!_vm.showLink)?_c('InputField',{staticClass:"deposit-amount",attrs:{"type":_vm.amountInputAttr.type,"name":"amount-input","formName":"deposit-form","value":_vm.depositAmount,"v":_vm.$v.depositAmount,"errorMessages":_vm.errorMessages,"currencySymbol":_vm.currencySymbol,"attrs":{
                        pattern: _vm.amountInputAttr.pattern,
                        inputmode: _vm.amountInputAttr.inputmode,
                        'data-test-id': 'depositAmountInput',
                    },"disabled":_vm.isInitialized || _vm.inProgress,"helpText":_vm.helpText,"step":_vm.amountInputAttr.step},on:{"value":_vm.onValueChange}},[_c('template',{slot:"label"},[_c('span',{staticClass:"label-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.inputLabel || _vm.$t('ui.payment.deposit.enterAmount')))]),(_vm.isTelcoFeeEnabled)?_c('DepositTelcoFeeLabel',{attrs:{"compensationAmount":_vm.selectedProvider.minDepositAmountForFeeCompensation,"providerName":_vm.selectedProvider.text}}):(_vm.selectedProviderFee)?_c('span',[_vm._v(_vm._s(_vm.selectedProviderFee))]):_vm._e()],1)])],2):_vm._e()]}),_vm._t("fee",null,{"provider":_vm.selectedProvider,"fee":_vm.fee}),(!_vm.fee && _vm.isTelcoFeeEnabled && _vm.depositAmount)?_c('DepositTelcoFee',{attrs:{"amount":_vm.depositAmount,"isLoading":_vm.isTelcoFeeLoading,"providerName":_vm.selectedProvider.text,"telcoFee":_vm.telcoFee}}):_vm._e(),_c('div',[(_vm.showLink)?[(_vm.selectedProvider && _vm.selectedProvider.pageUrl)?_c('router-link',{staticClass:"button button-primary button-full",attrs:{"to":{ path: _vm.selectedProvider.pageUrl }}},[_vm._v(" "+_vm._s(_vm.$t('ui.payment.deposit.linkTitle'))+" ")]):_c('div',{staticClass:"button button-primary button-full disabled"},[_vm._v(" "+_vm._s(_vm.$t('ui.payment.deposit.linkTitle'))+" ")])]:_c('button',{staticClass:"button button-primary button-full",class:{ processing: _vm.isInitialized },attrs:{"disabled":_vm.isSubmitButtonDisabled,"data-test-id":"depositButton","type":"submit"}},[_c('span',[_vm._v(_vm._s(_vm.depositButtonText))])])],2)]:_vm._e()],2):_vm._e(),(_vm.boxMessage)?_c('div',{staticClass:"notify",class:{
            error: _vm.errorText,
            warning: _vm.boxWarningMessage,
        },attrs:{"data-test-id":"boxMessage"}},[_c('renderer',{attrs:{"input":_vm.boxMessage}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }