<template>
    <div class="table-slip">
        <Jackpot :jackpot="pool">
            <template slot="cta">
                <a class="button-accent button button-full" @click.stop.prevent="showResults">{{ $t('ui.jackpot.seeLess') }}</a>
            </template>
        </Jackpot>
        <div class="events">
            <div class="events-header">
                <div class="title">{{ $t('ui.jackpot.match') }}</div>
                <div>{{ $t('ui.jackpot.result') }}</div>
            </div>
            <div class="events-item" v-for="(row, index) of pool.events" v-bind:key="index">
                <div class="events-item-match">
                    <h3>{{ row.name }}</h3>
                    <h4 v-if="!isSingleGameJackpot && row.event">
                        {{ formatDateTime(row.event.startDate, { ...dateOptions, isUtc: true }) }} - {{ row.event.competition }}
                    </h4>
                </div>
                <div class="events-item-status">
                    <SvgIcon v-if="row.event && row.event.live && !isPresto" iconId="icon-live" class="icon-status" />
                    <span class="result">{{ getEventResult(row) }}</span>
                </div>
            </div>
        </div>
        <div class="events tiebreakers" v-if="poolTiebreakers.length">
            <div class="events-header">
                <div class="title">{{ $t('ui.jackpot.tiebreakers.title') }}</div>
            </div>
            <div class="events-item" v-for="(tie, index) in poolTiebreakers" :key="`${index}-tiebreaker-${tie.id}`">
                <div class="events-item-match">
                    <h3>{{ tie.question }}</h3>
                </div>
                <div class="events-item-status">
                    <span class="result">{{ tie.correctAnswer }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { helper, deviceType } from '@agi.packages/core';
import { getter as platformGetter } from '@agi.packages/platform';

import { jackpotType } from '@agi.packages/sport';
import Jackpot from './Jackpot';
import { routeName } from '@/router/const';

export default {
    name: 'TableSlip',
    components: { Jackpot },
    props: {
        pool: {
            required: true,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
        }),
        ...mapState({
            settings: (state) => state.platform.settings,
            jackpotResults: (state) => state.sport.jackpot.resulted,
        }),
        isSingleGameJackpot() {
            return [jackpotType.SINGLE, jackpotType.MULTIJURISDICTION].includes(this.pool.type);
        },
        poolTiebreakers() {
            return this.pool.tiebreakers?.tiebreakers || [];
        },
    },
    methods: {
        formatDateTime: helper.formatDateTime,
        getEventResult(row) {
            const { cancelled, scores, text } = row?.result || {};
            const resulted = Number.isInteger(scores?.scoreHome) && parseInt(scores?.scoreHome) > -1;
            if (cancelled) {
                return this.$t('ui.jackpot.cancelled');
            } else if (row?.event?.live) {
                return this.$t('ui.jackpot.live');
            } else if (this.pool.isPaid || resulted) {
                if (this.isSingleGameJackpot && text) return text;
                if (Number.isInteger(scores?.scoreHome) && Number.isInteger(scores?.scoreAway)) {
                    return scores.scoreHome + ' - ' + scores.scoreAway;
                }
            }
            return text || '';
        },
        showResults() {
            this.$router.push({ name: routeName.JACKPOT_PAGE, query: { tab: 'results' } });
        },
    },
};
</script>

<style scoped lang="scss">
.events {
    &-header {
        @extend %small-details-font-400;
        color: $grey-text;
        padding: 5px 12px;
        display: flex;
        text-align: left;

        .tiebreakers & {
            border-top: 1px solid black;
        }

        .title {
            // TODO: remove in favor of global flex
            flex: 1;
        }
    }

    &-item {
        background: $light-grey-bg;
        border-top: 1px solid $light-grey;
        display: flex;
        padding: 5px 12px;

        .tiebreakers & {
            border-top: 2px solid white;
        }

        &-match {
            text-align: left;
            flex: 1;

            h3,
            h4 {
                margin: 0;
            }

            h3 {
                @extend %body-small-font-700;
                color: $betpawa-black;
                text-transform: none;
            }

            h4 {
                @extend %caption-font-400;
                color: $grey-text;
            }
        }

        &-status {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .result {
                color: $main-text;
                @extend %body-small-font-700;
                vertical-align: initial;
                margin-left: 10px;
            }
        }
    }
}
</style>
