<template>
    <div class="boosted-events">
        <Spinner v-if="isEventsLoading" visible class="inset" />
        <div v-else-if="events.length" class="boosted-events-container">
            <Game :key="event.id" v-for="event in events" :event="event" :showMarketCount="true" />
        </div>
        <div v-else class="boosted-events-empty">{{ $t('ui.popular.noActiveEvents') }}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sport } from '@agi.packages/sport';
import { getter as generalGetter } from '@/store/store';
import { getter as coreGetter } from '@/modules/core';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Game from '../Game.vue';

export default {
    name: 'BoostedEventsView',
    mixins: [PageMixin, SEOMixin],
    components: { Game },
    computed: {
        ...mapGetters({
            events: sport.getter.GET_BOOSTED_EVENTS,
            sortedSportCategories: generalGetter.GET_SORTED_CATEGORIES,
            isLoading: coreGetter.IS_LOADING,
        }),
        isEventsLoading() {
            return this.isLoading(sport.action.LOAD_UPCOMING_EVENTS);
        },
    },
    created() {
        const categoryIds = this.sortedSportCategories.map((c) => c.id);
        this.$store.dispatch(sport.action.LOAD_UPCOMING_EVENTS, {
            sportParams: {
                eventParams: [
                    {
                        categoryIds,
                        eventType: 'UPCOMING',
                        take: 100, // temporary query until this page will be enhanced with infinite loading and load more button
                        filters: {
                            boosted: {
                                value: true,
                            },
                        },
                    },
                ],
            },
        });
    },
};
</script>

<style scoped lang="scss">
.boosted-events {
    position: relative;

    &-empty {
        padding-top: 24px;
        text-align: center;
    }
}
</style>
