<template>
    <div v-if="isTabsEnabled && tabs" class="tabs-wrapper" :class="{ 'on-top': isTabsOnTop }">
        <ATabs :tabs="tabs" :active="currentTab" :align="align" tabType="text" bottomBorder @select="onTabChange" />
    </div>
</template>
<script>
import { Tabs as ATabs } from '@agi.packages/core/components';
import { getter as platformGetter } from '@agi.packages/platform';
import { mapGetters } from 'vuex';

const TABS_TYPE = 'tabs';

export default {
    name: 'Tabs',
    components: { ATabs },
    props: ['data'],
    computed: {
        ...mapGetters({
            pages: platformGetter.GET_PAGES,
        }),
        align() {
            const { align } = this.data?.content || {};
            if (align) {
                return align[this.$mq.size] || 'center';
            }
            return this.$mq.isSmall ? 'center' : 'left';
        },
        tabs() {
            return this.data.content.tab.map((tab) => ({
                text: tab.name,
                key: 'tab-' + tab.id,
                link: tab.page.data?.attributes.links.find((link) => link.isActual)?.link,
                data: tab,
            }));
        },
        currentTab() {
            return this.tabs.find((tab) => tab.link === this.routePath);
        },
        isTabsEnabled() {
            return !!this.data.content.isTabsEnabled;
        },
        routePath() {
            return this.$route.path.split('/').pop();
        },
        isTabsOnTop() {
            return this.pages[this.routePath]?.content[0]?.type.toLowerCase() === TABS_TYPE;
        },
    },
    methods: {
        onTabChange(selectedTab) {
            if (this.routePath !== selectedTab.link) {
                this.$router.push(selectedTab.link);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.tabs-wrapper {
    margin: 0 -20px;
}

.on-top {
    margin-top: -20px;
}
</style>
