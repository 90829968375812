import { helper, NETWORK_ERROR } from '@agi.packages/core';
import { GLOBAL_ERRORS_REQUIRING_TIME_FORMATTING } from '@agi.packages/platform';

const MULTI_EXCEPTION = 'MULTIPLE_EXCEPTION_WRAPPER';
const UNKNOWN_ERROR = 'UNKNOWN';

/**
 * Creates an error handler for the rejected state of the axios interceptor
 * @param {Object} Vue vue instance
 * @param {Object} store vuex store
 * @param {String} langGetter get language getter
 */
export const createErrorHandler = (Vue, store, langGetter) => {
    return (error) => {
        const processErrorMessage = Vue.$t || ((it) => it);
        const { code, response, config } = error;
        // reject "network" errors
        if (!response && !code && code !== 'ECONNABORTED') {
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
            Vue.$gtm.query({
                event: 'network_error',
                url: config.url,
                downlink: connection.downlink,
            });
            error.errorCode = NETWORK_ERROR;
            return Promise.reject(error);
        }
        const { locales } = store.getters[langGetter];
        const errors = locales[Object.keys(locales)[0]].errors.global;
        const api = response?.config?.url.split('/').join('_').toUpperCase() || UNKNOWN_ERROR;
        const hasDataObject = response.data && typeof response.data !== 'string';
        const { data, status } = hasDataObject ? response : { ...response, data: { error: `ERROR_${response.status}_${api}` } };
        const { translationParams } = config || {};
        error.message = processErrorMessage('errors.UNKNOWN', { indefinite: true });
        error.params = data.params || [];

        if (data.error in errors) {
            error.errorCode = data.error;
            if (GLOBAL_ERRORS_REQUIRING_TIME_FORMATTING.includes(data.error)) {
                data.params[1] = helper.formatMinutesToDHM(data.params[1]);
            }
            error.message = processErrorMessage(`errors.${data.error}`, {
                ...(data?.params?.length ? data.params : []),
                ...(translationParams || {}),
                indefinite: true,
            });
        }

        if (data.error === MULTI_EXCEPTION) {
            error.errorCode = response.data.error;
            error.message = Object.entries(data.payload).reduce((acc, [key, val], index, entries) => {
                const { error, params } = val;
                const prefix = entries.length > 1 ? `${acc}${index ? '<br><br>' : ''}${index + 1}. ` : '';
                return `${prefix}${processErrorMessage(`errors.${error}`, {
                    ...params,
                    ...(translationParams || {}),
                    indefinite: true,
                })}`;
            }, '');
        }

        if (status) {
            error.statusCode = status;
        }
        if (data.payload) {
            error.payload = data.payload;
        }
        if (data.uuid) {
            error.uuid = data.uuid;
        }
        return Promise.reject(error);
    };
};
