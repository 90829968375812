import { render, staticRenderFns } from "./AuthWrapper.vue?vue&type=template&id=32b31d43&scoped=true"
import script from "./AuthWrapper.vue?vue&type=script&lang=js"
export * from "./AuthWrapper.vue?vue&type=script&lang=js"
import style0 from "./AuthWrapper.vue?vue&type=style&index=0&id=32b31d43&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b31d43",
  null
  
)

export default component.exports