<template>
    <router-link
        :to="{ path: progressiveJackpot.link }"
        :class="['highlight-hover-disabled', 'progressive-jp-presto', `progressive-jp-presto-${progressiveJackpot.type}`]"
    >
        <div class="progressive-jp-presto-content">
            <div class="progressive-jp-presto-name">{{ progressiveJackpot.name }}</div>
            <div class="progressive-jp-presto-prize">
                <Currency :amount="progressiveJackpot.prize" :format="currencyFormat" :decimal="0" />
            </div>
        </div>
        <div class="progressive-jp-presto-button">
            <SvgIcon iconId="arrow_right" class="icon-size-small arrow-icon" />
        </div>
    </router-link>
</template>

<script>
import { mapState } from 'vuex';
import { Currency } from '@/modules/core/components';

export default {
    name: 'ProgressiveJpPreviewBarPresto',
    components: { Currency },
    props: {
        progressiveJackpot: {
            type: Object,
        },
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
    },
};
</script>

<style lang="scss" scoped>
.progressive-jp-presto {
    @extend %small-details-font-400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;

    &-sport {
        background: map-get($pjp-color-schema, 'sport', 'background');
    }

    &-casino {
        background: map-get($pjp-color-schema, 'casino', 'background');
        color: map-get($pjp-color-schema, 'casino', 'text');

        .arrow-icon {
            fill: $white-text;
        }
    }

    &-prize {
        @extend %small-details-font-700;
        text-transform: uppercase;
    }

    &-button {
        width: 16px;
    }
}
</style>
