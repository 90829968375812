<template>
    <div class="bet-bonus">
        <template v-if="isMultiplierSchema">
            <div v-show="legBonusInfo" class="betslip-odds-multiplier-banner">
                <div class="multiplier-icon">
                    <SvgIcon iconId="icon-odds-multiplier" class="icon-odds" />
                </div>
                <div class="multiplier-content">
                    {{ $t('ui.betslip.bannerGetUpTo') }}
                    <span class="highlight">{{ maxBonus }}x</span>
                    {{ $t('ui.betslip.bannerWithOur') }}
                    <span class="highlight">{{ $t('ui.betslip.bannerOddsMultiplier') }}</span>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="betslip-legbonus-info notify" v-show="legBonusInfo">
                {{ legBonusInfo }}
                <router-link
                    class="underline"
                    :to="{
                        path: $t(`project.ui.onBoarding.${country.KE ? 'boostLink' : 'bonusLink'}`),
                    }"
                >
                    {{ $t('ui.betslip.readMore') }}
                </router-link>
            </div>
        </template>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import { country } from '@agi.packages/core';
import { getter as generalGetter } from '@agi.packages/platform';

export default {
    name: 'BetBonus',
    props: {
        legBonusInfo: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        country,
    }),
    computed: {
        ...mapState({
            maxBonus: (state) => state.platform.settings.bonus.perLeg[state.platform.settings.bonus.perLeg.length - 1] + 1,
        }),
        ...mapGetters({
            isMultiplierSchema: generalGetter.IS_MULTIPLIER_SCHEMA,
            settings: generalGetter.GET_SETTINGS,
        }),
    },
};
</script>

<style lang="scss" scoped>
.bet-bonus {
    display: flex;
    border-bottom: 1px solid $betslip-bottom-border-color;
}
.betslip-legbonus-info {
    width: 100%;
    margin: 10px;
    background-color: $betslip-legbonus-info-background;
    @include oldschool {
        margin: 8px;
    }
}

.betslip-odds-multiplier-banner {
    padding: 10px;
    padding-right: 30px;
    margin: 3%;
    border: 1px solid $bet-bonus-betslip-odds-multiplier-banner-border-color;
    background: $white-bg;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    display: flex;
    font-style: italic;
    font-weight: bold;

    .multiplier-icon {
        display: flex;
        align-items: center;
        background: $bet-bonus-multiplier-icon-background;
        margin-right: 10px;
        padding: 15px 8px;
        svg {
            width: 42px;
        }
        @include only_mini {
            display: block;
        }

        .icon-odds {
            fill: $bet-bonus-betslip-odds-multiplier-banner-color;
        }
    }
    .multiplier-content {
        font-size: 20px;
        color: $betpawa-black;
        .highlight {
            color: $betpawa-green;
        }
    }
}
</style>
